import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';
import Select from 'react-select'

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDateTime} from '../../inc/helper';
import clientData from '../../inc/clientData';
class MyAppointmentModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      crmMemberAppointments: [],
      crmMemberTypes: [],
      cities: [],
      towns: [],

      filterActive: '',
      filterAppointmentStartDate: '',
      filterAppointmentEndDate: '',
      filterCompanyName: '',
      filterCompanyType: '',
      filterCrmMemberTypeId: '',
      filterEmail: '',
      filterFullName: '',
      filterPhone: '',

      filterCityId: '',
      filterTownId: ''
    };
  }

  componentDidMount() {
    this.getMyAppointments();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  filteredMyAppointments(query) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'crmMemberAppointments/tr?filterMarketerId=' + clientData.id + '&' + query, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, crmMemberAppointments: responseData.crmMemberAppointments});
        break;
        default:
          this.setState({isLoading: false});
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getMyAppointments() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 4;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'crmMemberAppointments/tr?filterMarketerId=' + clientData.id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({crmMemberAppointments: responseData.crmMemberAppointments});
        break;
        default:
          errorMessages.push(responseData.message);
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'crmMemberTypes?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({crmMemberTypes: responseData.crmMemberTypes});
        break;
        default:
          errorMessages.push(responseData.message);
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'cities/tr/1?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const sortedCities = responseData.cities.sort((first, last) => first.name.localeCompare(last.name));
          this.setState({cities: sortedCities, towns: []});
        break;
        default:
          errorMessages.push(responseData.message);
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'company/tr/marketers?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({companyMarketers: responseData.companyMarketers});
        break;
        default:
          errorMessages.push(responseData.message);
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getTowns(cityId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'towns/tr/' + cityId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({filterCityId: cityId, filterTownId: '', towns: responseData.towns});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getPhoneNumber(crmMemberAppointmentInfo) {
    let activeCrmMemberPhones = crmMemberAppointmentInfo.crmMemberPhones.filter(item => item.active === 1);

    if(activeCrmMemberPhones.length > 0) {
      let activeCrmMemberPhoneNumber = activeCrmMemberPhones[0].phoneNumber.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
      let phoneMasking = `(${activeCrmMemberPhoneNumber[1]}) ${activeCrmMemberPhoneNumber[2]} ${activeCrmMemberPhoneNumber[3]} ${activeCrmMemberPhoneNumber[4]}` 
      return phoneMasking;
    }
    
    return '-'
  }

  getRegion(crmMemberAppointmentInfo) {
    let activeCrmMemberAddresses = crmMemberAppointmentInfo.crmMemberAddresses.filter(item => item.active === 1);
    return activeCrmMemberAddresses.length > 0 ? activeCrmMemberAddresses[0].cityName + '/' + activeCrmMemberAddresses[0].townName : '-';
  }

  selectedCityOnChange = (selectedCity) => {
    let filterCityId = selectedCity ? selectedCity.value : '';
    if (filterCityId) {
      this.getTowns(filterCityId);
    } else {
      this.setState({filterCityId: '', filterTownId: '', towns: []});
    }
  }

  selectedTownOnChange = (selectedTown) => {
    this.setState({filterTownId: selectedTown ? selectedTown.value : ''});
  }

  filterOnClick = (event) => {
    this.filteredMyAppointments('filterCrmMemberTypeId=' + this.state.filterCrmMemberTypeId + '&filterCompanyName=' + this.state.filterCompanyName + '&filterCompanyType=' + this.state.filterCompanyType + '&filterFullName=' + this.state.filterFullName + '&filterEmail=' + this.state.filterEmail + '&filterPhone=' + this.state.filterPhone + '&filterCityId=' + this.state.filterCityId + '&filterTownId=' + this.state.filterTownId + '&filterAppointmentStartDate=' + this.state.filterAppointmentStartDate + '&filterAppointmentEndDate=' + this.state.filterAppointmentEndDate + '&filterActive=' + this.state.filterActive);
  }

  resetOnClick = (event) => {
    this.setState({
      filterActive: '',
      filterAppointmentStartDate: '',
      filterAppointmentEndDate: '',
      filterCompanyName: '',
      filterCompanyType: '',
      filterCrmMemberTypeId: '',
      filterEmail: '',
      filterFullName: '',
      filterPhone: '',

      filterCityId: '',
      filterTownId: ''
    });
    this.getMyAppointments();
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.filterOnClick();
    }
  }

  render() {
    const selectedCitiesOptions = this.state.cities.map((cityInfo) => ({
      value: cityInfo.id,
      label: `${cityInfo.name}`,
      search: cityInfo.name
    }))
    
    const selectedTownsOptions = this.state.towns.map((townInfo) => ({
      value: townInfo.id,
      label: `${townInfo.name}`,
      search: townInfo.name
    }))

    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-xl-9">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between">
                <h4>Randevularım</h4>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered ">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Üye Adı Soyadı</th>
                      <th>Firma Adı</th>
                      <th>Email</th>
                      <th>Telefon</th>
                      <th>Bölge</th>
                      <th>Randevu Durumu</th>
                      <th>Randevu Zamanı</th>
                      <th>Satış Sorumlusu</th>
                      <th>Durum</th>
                      <th>Düzenle</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.crmMemberAppointments.map((crmMemberAppointmentInfo) =>
                      <tr key={crmMemberAppointmentInfo.id}>
                        <td className="align-middle font-bold">{<Link title="Potansiyel Üye Profiline Git" to={'/crmMemberEdit?id=' + crmMemberAppointmentInfo.crmMemberId}>{crmMemberAppointmentInfo.crmMemberFullName}</Link>}<br /><span className="text-darkblue">{crmMemberAppointmentInfo.crmMemberTypeName || '-'}</span></td>
                        <td className="align-middle">{crmMemberAppointmentInfo.companyName || '-'}<br /><strong className="font-bold text-darkblue">{crmMemberAppointmentInfo.companyType || '-'}</strong></td>
                        <td className="align-middle">{crmMemberAppointmentInfo.email || '-'}</td>
                        <td className="align-middle">{this.getPhoneNumber(crmMemberAppointmentInfo)}</td>
                        <td className="align-middle">{this.getRegion(crmMemberAppointmentInfo)}</td>
                        <td className="align-middle">{crmMemberAppointmentInfo.crmMemberAppointmentStatusName}</td>
                        <td className="align-middle">{isoToDateTime(crmMemberAppointmentInfo.appointmentDate)}</td>
                        <td className="align-middle">{crmMemberAppointmentInfo.crmMemberMarketers.map((crmMemberMarketerInfo) => <h5 className="font-normal" key={crmMemberMarketerInfo.id}>{crmMemberMarketerInfo.fullName}</h5>)}</td>
                        <td className="align-middle">{crmMemberAppointmentInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle">
                          <Link className="btn btn-orange text-center" to={"/crmMemberAppointmentEdit?id=" + crmMemberAppointmentInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                        </td>
                      </tr>
                    )}
                    {this.state.crmMemberAppointments.length === 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="11">Randevu Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
            <div className="ibox">
              <div className="ibox-content text-darkblue p-0">
                <h4 className="collapse-title">
                  <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
                </h4>
              </div>
              <div className="collapse" data-parent="#accordion" id="filterCollapse">
                <div className="ibox-content text-darkblue">
                  <strong>Üyelik Tipi</strong>
                  <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterCrmMemberTypeId: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterCrmMemberTypeId}>
                    <option value="">Seçiniz</option>
                    {this.state.crmMemberTypes.map((crmMemberTypeInfo) => <option key={crmMemberTypeInfo.id} value={crmMemberTypeInfo.id}>{crmMemberTypeInfo.name}</option>)}
                  </select>
                  <strong>Üye Adı</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.filterFullName} />
                  <strong>Firma Adı</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Firma Adı" type="text" value={this.state.filterCompanyName} />
                  <strong>E-posta</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterEmail: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.filterEmail} />
                  <strong>Telefon</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPhone: event.target.value.replace(/^0|[^0-9]+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Telefon" type="tel" value={this.state.filterPhone} />
                  <strong>Şehir</strong>
                  <Select
                  className=" form-input mb-2 mt-1 text-dark"
                  placeholder="Seçiniz"
                  onChange={this.selectedCityOnChange}
                  options={selectedCitiesOptions}
                  isClearable={true}
                  defaultValue={selectedCitiesOptions[0]}
                  value={selectedCitiesOptions.find((cityInfo) => cityInfo.value === this.state.filterCityId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  />
                  <strong>İlçe</strong>
                  <Select
                  className="form-input mb-2 mt-1 text-dark"
                  isDisabled={this.state.towns.length === 0}
                  onChange={this.selectedTownOnChange}
                  options={selectedTownsOptions}
                  isClearable={true}
                  defaultValue={selectedTownsOptions[0]}
                  placeholder="Seçiniz"
                  value={selectedTownsOptions.find((townInfo) => townInfo.value === this.state.filterTownId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  />
                  <strong>Faaliyet Alanı</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyType: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Faliyet Alanı" type="text" value={this.state.filterCompanyType} />
                  <strong>Durum</strong>
                  <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterActive: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                    <option value="">Seçiniz</option>
                    <option value="1">Açık</option>
                    <option value="0">Kapalı</option>
                  </select>
                  <strong>Randevu Başlangıç Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterAppointmentStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentStartDate} />
                  <strong>Randevu Bitiş Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterAppointmentEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentEndDate} />
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                    <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
            <div className="ibox">
              <div className="ibox-title text-darkblue">
                <h5><i className="fa fa-filter"></i> Filtrele</h5>
              </div>
              <div className="ibox-content text-darkblue">
                <strong>Üyelik Tipi</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterCrmMemberTypeId: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterCrmMemberTypeId}>
                  <option value="">Seçiniz</option>
                  {this.state.crmMemberTypes.map((crmMemberTypeInfo) => <option key={crmMemberTypeInfo.id} onKeyDown={this.handleKeyPress} value={crmMemberTypeInfo.id}>{crmMemberTypeInfo.name}</option>)}
                </select>
                <strong>Üye Adı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.filterFullName} />
                <strong>Firma Adı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Firma Adı" type="text" value={this.state.filterCompanyName} />
                <strong>E-posta</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterEmail: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.filterEmail} />
                <strong>Telefon</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPhone: event.target.value.replace(/^0|[^0-9]+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Telefon" type="tel" value={this.state.filterPhone} />
                <strong>Şehir</strong>
                <Select
                  className=" form-input mb-2 mt-1 text-dark"
                  options={selectedCitiesOptions}
                  isClearable={true}
                  defaultValue={selectedCitiesOptions[0]}
                  onChange={this.selectedCityOnChange}
                  value={selectedCitiesOptions.find((cityInfo) => cityInfo.value === this.state.filterCityId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  placeholder="Seçiniz"
                  />
                <strong>İlçe</strong>
                <Select
                className=" form-input mb-2 mt-1 text-dark"
                options={selectedTownsOptions}
                onChange={this.selectedTownOnChange}
                isDisabled={this.state.towns.length === 0}
                value={selectedTownsOptions.find((townInfo) => townInfo.value === this.state.filterTownId)}
                filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                placeholder="Seçiniz"
                />
                <strong>Faaliyet Alanı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyType: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Faliyet Alanı" type="text" value={this.state.filterCompanyType} />
                <strong>Durum</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterActive: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                  <option value="">Seçiniz</option>
                  <option value="1">Açık</option>
                  <option value="0">Kapalı</option>
                </select>
                <strong>Randevu Başlangıç Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterAppointmentStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentStartDate} />
                <strong>Randevu Bitiş Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterAppointmentEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentEndDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function MyAppointments() {
  return (
    <MyAppointmentModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}