import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';

import {BrowserRouter, Routes, Route,Navigate } from 'react-router-dom';

import Adverts from './pages/advert/Adverts';
import AdvertEdit from './pages/advert/AdvertEdit';
import ActiveMembers from './pages/reports/ActiveMembers';
import Agreements from './pages/siteManagement/agreement/Agreements';
import AgreementAdd from './pages/siteManagement/agreement/AgreementAdd';
import AgreementEdit from './pages/siteManagement/agreement/AgreementEdit';
import AgreementLanguageEdit from './pages/siteManagement/agreement/AgreementLanguageEdit';
import AttributeGroups from './pages/category/attributeGroups/AttributeGroups';
import AttributeGroupEdit from './pages/category/attributes/AttributeGroupEdit';
import BannerEdit from './pages/banner/BannerEdit';
import Banners from './pages/banner/Banners';
import BannerLocationEdit from './pages/siteManagement/banner/BannerLocationEdit';
import BannerLocations from './pages/siteManagement/banner/BannerLocations';
import BlogAdd from './pages/informationPage/blog/BlogAdd';
import BlogEdit from './pages/informationPage/blog/BlogEdit';
import Blogs from './pages/informationPage/blog/Blogs';
import Categories from './pages/category/Categories';
import CategoryAttributes from './pages/category/attributes/CategoryAttributes';
import CategoryFields from './pages/category/fields/CategoryFields';
import CategoryFieldItemList from './pages/category/fields/CategoryFieldItemList';
import CategoryFieldItemListEdit from './pages/category/fields/CategoryFieldItemListEdit';
import CategoryAttributeGroupEdit from './pages/category/attributes/CategoryAttributeGroupEdit';
import CategoryAttributeEdit from './pages/category/attributes/CategoryAttributeEdit';
import CategoryFieldEdit from './pages/category/fields/CategoryFieldEdit';
import CreditRequests from './pages/services/CreditRequests';
import CreditRequestDetail from './pages/services/CreditRequestDetail';
import CreditRequestEdit from './pages/services/CreditRequestEdit';
import CreditRequestHistories from './pages/services/CreditRequestHistories';
import CrmMemberAdd from './pages/crm/crmMember/CrmMemberAdd';
import CrmMemberAppointments from './pages/crm/crmMemberAppointment/CrmMemberAppointments';
// import CrmMemberAppointmentAdd from './pages/crm/crmMemberAppointment/CrmMemberAppointmentAdd';
import CrmAppointmentAdd from './pages/crm/crmMemberAppointment/CrmAppointmentAdd';
import CrmMemberAppointmentEdit from './pages/crm/crmMemberAppointment/CrmMemberAppointmentEdit';
import CrmMemberAppointmentsReport from './pages/reports/CrmMemberAppointmentsReport';
import PersonalEvents from './pages/reports/PersonalEvents';
import CrmMemberEdit from './pages/crm/crmMember/CrmMemberEdit';
import CrmMembers from './pages/crm/crmMember/CrmMembers';
import CrmMemberAppointmentStatusEdit from './pages/siteManagement/crmMemberAppointmentStatus/CrmMemberAppointmentStatusEdit';
import CrmMemberAppointmentStatuses from './pages/siteManagement/crmMemberAppointmentStatus/CrmMemberAppointmentStatuses';
import CrmMemberTypeEdit from './pages/siteManagement/crmMemberType/CrmMemberTypeEdit';
import CrmMemberTypes from './pages/siteManagement/crmMemberType/CrmMemberTypes';
import ExpiredMembersReport from './pages/reports/ExpiredMembersReport';
import MemberHistory from './pages/reports/MemberHistory';
import Home from './pages/Home';
import Login from './pages/Login';
import MemberAdd from './pages/member/MemberAdd';
import MemberWorkExpenses from './pages/accounting/memberWorkExpense/MemberWorkExpenses';
import MemberWorkExpenseDetail from './pages/accounting/memberWorkExpense/MemberWorkExpenseDetail';
import MemberWorkExpenseEdit from './pages/accounting/memberWorkExpense/MemberWorkExpenseEdit';
import MemberWorkItemDetail from './pages/workItem/memberWorkItem/MemberWorkItemDetail';
import MemberWorkItemEdit from './pages/workItem/memberWorkItem/MemberWorkItemEdit';
import MemberWorkItems from './pages/workItem/memberWorkItem/MemberWorkItems';
import MemberEdit from './pages/member/MemberEdit';
import Members from './pages/member/Members';
import MyAppointments from './pages/specialForMe/MyAppointments';
import MySales from './pages/specialForMe/MySales';
import MyWorkExpenses from './pages/specialForMe/myWorkExpenses/MyWorkExpenses';
import MyWorkExpenseEdit from './pages/specialForMe/myWorkExpenses/MyWorkExpenseEdit';
import MyWorkItems from './pages/specialForMe/MyWorkItems';
import OtherPortalEdit from './pages/siteManagement/otherPortal/OtherPortalEdit';
import OtherPortals from './pages/siteManagement/otherPortal/OtherPortals';
import PanelBase from './component/PanelBase';
import PaymentStatusEdit from './pages/siteManagement/paymentStatus/PaymentStatusEdit';
import PaymentStatuses from './pages/siteManagement/paymentStatus/PaymentStatuses';
import PaymentTransactionByBankTransfer from './pages/member/payment/PaymentTransactionByBankTransfer';
import PaymentTransactionByCreditCard from './pages/member/payment/PaymentTransactionByCreditCard';
import PaymentTransactionByFree from './pages/member/payment/PaymentTransactionByFree';
import PaymentTypeEdit from './pages/siteManagement/paymentType/PaymentTypeEdit';
import PaymentTypes from './pages/siteManagement/paymentType/PaymentTypes';
import PendingAdverts from './pages/advert/PendingAdverts';
import PendingAdvertDetail from './pages/advert/PendingAdvertDetail';
import PhoneTypeEdit from './pages/siteManagement/phoneType/PhoneTypeEdit';
import PhoneTypes from './pages/siteManagement/phoneType/PhoneTypes';
import Products from './pages/siteManagement/product/Products';
import ProductAdd from './pages/siteManagement/product/ProductAdd';
import ProductEdit from './pages/siteManagement/product/ProductEdit';
import ProductTypeEdit from './pages/siteManagement/productType/ProductTypeEdit';
import ProductTypes from './pages/siteManagement/productType/ProductTypes';
import TransactionInvoiceEdit from './pages/member/payment/TransactionInvoiceEdit';
import WorkExpenses from './pages/accounting/WorkExpenses';
import WorkItemEdit from './pages/workItem/workItem/WorkItemEdit';
import WorkItemMembers from './pages/workItem/workItem/WorkItemMembers';
import WorkItems from './pages/workItem/workItem/WorkItems';
import Helps from './pages/informationPage/help/Helps';
import HelpEdit from './pages/informationPage/help/HelpEdit';
import HelpAdd from './pages/informationPage/help/HelpAdd';
import Expertises from './pages/services/Expertises';
import ExpertiseDetail from './pages/services/ExpertiseDetail';
import CrmMembersRegistered from './pages/crm/crmMember/CrmMembersRegistered';
import TowingRequests from './pages/services/TowingRequests';
import SalesHistory from './pages/member/SalesHistory';

const appElement = document.getElementById('root');
const root = ReactDOM.createRoot(appElement);

Modal.setAppElement(appElement);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/adverts" element={<PanelBase><Adverts /></PanelBase>} />
      <Route path="/advertEdit" element={<PanelBase><AdvertEdit /></PanelBase>} />
      <Route path="/agreements" element={<PanelBase><Agreements /></PanelBase>} />
      <Route path="/agreementAdd" element={<PanelBase><AgreementAdd /></PanelBase>} />
      <Route path="/agreementEdit" element={<PanelBase><AgreementEdit /></PanelBase>} />
      <Route path="/agreementLanguageEdit" element={<PanelBase><AgreementLanguageEdit /></PanelBase>} />
      <Route path="/attributeGroups" element={<PanelBase><AttributeGroups /></PanelBase>} />
      <Route path="/attributeGroupEdit" element={<PanelBase><AttributeGroupEdit /></PanelBase>} />
      <Route path="/reports/activeMembers" element={<PanelBase><ActiveMembers /></PanelBase>} />
      <Route path="/bannerEdit" element={<PanelBase><BannerEdit /></PanelBase>} />
      <Route path="/banners" element={<PanelBase><Banners /></PanelBase>} />
      <Route path="/bannerLocationEdit" element={<PanelBase><BannerLocationEdit /></PanelBase>} />
      <Route path="/bannerLocations" element={<PanelBase><BannerLocations /></PanelBase>} />
      <Route path="/blogAdd" element={<PanelBase><BlogAdd /></PanelBase>} />
      <Route path="/blogEdit" element={<PanelBase><BlogEdit /></PanelBase>} />
      <Route path="/blogs" element={<PanelBase><Blogs /></PanelBase>} />
      <Route path="/helps" element={<PanelBase><Helps /></PanelBase>} />
      <Route path="/helpAdd" element={<PanelBase><HelpAdd /></PanelBase>} />
      <Route path="/helpEdit" element={<PanelBase><HelpEdit /></PanelBase>} />
      <Route path="/categories" element={<PanelBase><Categories /></PanelBase>} />
      <Route path="/categoryAttributes" element={<PanelBase><CategoryAttributes /></PanelBase>} />
      <Route path="/categoryAttributeGroupEdit" element={<PanelBase><CategoryAttributeGroupEdit /></PanelBase>} />
      <Route path="/categoryFields" element={<PanelBase><CategoryFields /></PanelBase>} />
      <Route path="/categoryFieldItemList" element={<PanelBase><CategoryFieldItemList /></PanelBase>} />
      <Route path="/categoryFieldItemListEdit" element={<PanelBase><CategoryFieldItemListEdit /></PanelBase>} />
      <Route path="/categoryAttributeEdit" element={<PanelBase><CategoryAttributeEdit /></PanelBase>} />
      <Route path="/categoryFieldEdit" element={<PanelBase><CategoryFieldEdit /></PanelBase>} />
      <Route path="/creditRequests" element={<PanelBase><CreditRequests /></PanelBase>} />
      <Route path="/creditRequestDetail" element={<PanelBase><CreditRequestDetail /></PanelBase>} />
      <Route path="/creditRequestEdit" element={<PanelBase><CreditRequestEdit /></PanelBase>} />
      <Route path="/creditRequestHistories" element={<PanelBase><CreditRequestHistories /></PanelBase>} />
      <Route path="/crmMemberAdd" element={<PanelBase><CrmMemberAdd /></PanelBase>} />
      <Route path="/crmMemberAppointments" element={<PanelBase><CrmMemberAppointments /></PanelBase>} />
      {/* <Route path="/crmMemberAppointmentAdd" element={<PanelBase><CrmMemberAppointmentAdd/></PanelBase>} /> */}
      <Route path="/crmAppointmentAdd" element={<PanelBase><CrmAppointmentAdd/></PanelBase>} />
      <Route path="/crmMemberAppointmentEdit" element={<PanelBase><CrmMemberAppointmentEdit /></PanelBase>} />
      <Route path="/crmMemberEdit" element={<PanelBase><CrmMemberEdit /></PanelBase>} />
      <Route path="/crmMembers" element={<PanelBase><CrmMembers /></PanelBase>} />
      <Route path="/crmMembersRegistered" element={<PanelBase><CrmMembersRegistered /></PanelBase>} />
      <Route path="/reports/crmMemberAppointments"  element={<PanelBase><CrmMemberAppointmentsReport /></PanelBase>}/>
      <Route path="/crmMemberAppointmentStatusEdit" element={<PanelBase><CrmMemberAppointmentStatusEdit /></PanelBase>} />
      <Route path="/crmMemberAppointmentStatuses" element={<PanelBase><CrmMemberAppointmentStatuses /></PanelBase>} />
      <Route path="/crmMemberTypeEdit" element={<PanelBase><CrmMemberTypeEdit /></PanelBase>} />
      <Route path="/crmMemberTypes" element={<PanelBase><CrmMemberTypes /></PanelBase>} />
      <Route path="/expertises" element={<PanelBase><Expertises /></PanelBase>} />
      <Route path="/expertiseDetail" element={<PanelBase><ExpertiseDetail /></PanelBase>} />
      <Route path="/reports/expiredMembers"  element={<PanelBase><ExpiredMembersReport /></PanelBase>}/>
      <Route path="/reports/personalEvents"  element={<PanelBase><PersonalEvents /></PanelBase>}/>
      <Route path="/reports/memberHistory"  element={<PanelBase><MemberHistory /></PanelBase>}/>
      <Route path="/home" element={<PanelBase><Home /></PanelBase>} />
      <Route path="/" element={<Login />} />
      <Route path="/memberAdd" element={<PanelBase><MemberAdd /></PanelBase>} />
      <Route path="/memberWorkExpenses" element={<PanelBase><MemberWorkExpenses /></PanelBase>} />
      <Route path="/memberWorkExpenseDetail" element={<PanelBase><MemberWorkExpenseDetail /></PanelBase>} />
      <Route path="/memberWorkExpenseEdit" element={<PanelBase><MemberWorkExpenseEdit /></PanelBase>} />
      <Route path="/memberWorkItemDetail" element={<PanelBase><MemberWorkItemDetail /></PanelBase>} />
      <Route path="/memberWorkItemEdit" element={<PanelBase><MemberWorkItemEdit /></PanelBase>} />
      <Route path="/memberWorkItems" element={<PanelBase><MemberWorkItems /></PanelBase>} />
      <Route path="/memberEdit" element={<PanelBase><MemberEdit /></PanelBase>} />
      <Route path="/members" element={<PanelBase><Members /></PanelBase>} />
      <Route path="/salesHistory" element={<PanelBase><SalesHistory /></PanelBase>} />
      <Route path="/myAppointments" element={<PanelBase><MyAppointments /></PanelBase>} />
      <Route path="/mySales" element={<PanelBase><MySales /></PanelBase>} />
      <Route path="/myWorkExpenses" element={<PanelBase><MyWorkExpenses /></PanelBase>} />
      <Route path="/myWorkExpenseEdit" element={<PanelBase><MyWorkExpenseEdit /></PanelBase>} />
      <Route path="/myWorkItems" element={<PanelBase><MyWorkItems /></PanelBase>} />
      <Route path="/otherPortalEdit" element={<PanelBase><OtherPortalEdit /></PanelBase>} />
      <Route path="/otherPortals" element={<PanelBase><OtherPortals /></PanelBase>} />
      <Route path="/paymentStatusEdit" element={<PanelBase><PaymentStatusEdit /></PanelBase>} />
      <Route path="/paymentStatuses" element={<PanelBase><PaymentStatuses /></PanelBase>} />
      <Route path="/paymentTransactionByBankTransfer" element={<PanelBase><PaymentTransactionByBankTransfer /></PanelBase>} />
      <Route path="/paymentTransactionByCreditCard" element={<PanelBase><PaymentTransactionByCreditCard /></PanelBase>} />
      <Route path="/paymentTransactionByFree" element={<PanelBase><PaymentTransactionByFree /></PanelBase>} />
      <Route path="/paymentTypeEdit" element={<PanelBase><PaymentTypeEdit /></PanelBase>} />
      <Route path="/paymentTypes" element={<PanelBase><PaymentTypes /></PanelBase>} />
      <Route path="/pendingAdverts" element={<PanelBase><PendingAdverts /></PanelBase>} />
      <Route path="/pendingAdvertDetail" element={<PanelBase><PendingAdvertDetail /></PanelBase>} />
      <Route path="/phoneTypeEdit" element={<PanelBase><PhoneTypeEdit /></PanelBase>} />
      <Route path="/phoneTypes" element={<PanelBase><PhoneTypes /></PanelBase>} />
      <Route path="/products" element={<PanelBase><Products /></PanelBase>} />
      <Route path="/productAdd" element={<PanelBase><ProductAdd /></PanelBase>} />
      <Route path="/productEdit" element={<PanelBase><ProductEdit /></PanelBase>} />
      <Route path="/productTypeEdit" element={<PanelBase><ProductTypeEdit /></PanelBase>} />
      <Route path="/productTypes" element={<PanelBase><ProductTypes /></PanelBase>} />
      <Route path="/transactionInvoiceEdit" element={<PanelBase><TransactionInvoiceEdit /></PanelBase>} />
      <Route path="/workExpenses" element={<PanelBase><WorkExpenses /></PanelBase>} />
      <Route path="/towingRequests" element={<PanelBase><TowingRequests /></PanelBase>} />
      <Route path="/workItemEdit" element={<PanelBase><WorkItemEdit /></PanelBase>} />
      <Route path="/workItemMembers" element={<PanelBase><WorkItemMembers/></PanelBase>} />
      <Route path="/workItems" element={<PanelBase><WorkItems /></PanelBase>} />
    </Routes>
  </BrowserRouter>
);