import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import AddedCategoryFieldItemModal from '../../../component/category/categoryField/AddedCategoryFieldItemModal';

import config from '../../../inc/config';
import {getCookie} from '../../../inc/cookies';

class CategoryFieldItemListModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddedCategoryFieldItemModal: false,

      fieldItems: [],
      fieldInfo: {}
    };
  }

  componentDidMount() {
    this.getCategoryFieldItems();
    window.scrollTo(0, 0);
  }

  getCategoryFieldItems() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/categoryFields'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'fieldItems/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({fieldItems: responseData.fieldItems});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'field/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({fieldInfo: responseData.fieldInfo});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

  }

  saveCategoryFieldItemOnClick(fieldItemInfo) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({name: fieldItemInfo.name, description: fieldItemInfo.description, sort: parseInt(fieldItemInfo.sort)})
    };

    fetch(config.restApi + 'fieldItem/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Özel alana ait item başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getCategoryFieldItems(), this.setState({isOpenAddedCategoryFieldItemModal: false}), fieldItemInfo.name = '', fieldItemInfo.description = '', fieldItemInfo.sort = 0);
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCategoryFieldItemOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Itemı silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'fieldItem/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Item başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCategoryFieldItems());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return this.state.isLoading ?
    <div className="ibox-content sk-loading position-static">
    <div className="sk-spinner sk-spinner-fading-circle">
      <div className="sk-circle1 sk-circle"></div>
      <div className="sk-circle2 sk-circle"></div>
      <div className="sk-circle3 sk-circle"></div>
      <div className="sk-circle4 sk-circle"></div>
      <div className="sk-circle5 sk-circle"></div>
      <div className="sk-circle6 sk-circle"></div>
      <div className="sk-circle7 sk-circle"></div>
      <div className="sk-circle8 sk-circle"></div>
      <div className="sk-circle9 sk-circle"></div>
      <div className="sk-circle10 sk-circle"></div>
      <div className="sk-circle11 sk-circle"></div>
      <div className="sk-circle12 sk-circle"></div>
    </div>
  </div>
  :
  <>
  <div className="row">
    <div className="col">
      <div className="ibox">
        <div className="ibox-title d-flex justify-content-between text-darkblue pr-3">
          <h5><strong className="text-orange">{this.state.fieldInfo.name}</strong> Özel Alanına Ait Item Listesi</h5>
          <div>
            <button className="btn btn-orange mr-2" onClick={() => this.setState({isOpenAddedCategoryFieldItemModal: true})} title="Ekle"><i className="fa fa-plus"></i></button>
            <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
          </div>
        </div>
        <div className="ibox-content">
          <div className="row mb-3">
            <div className="col">
              <div className="overflow-auto">
                <table className="table table-striped table-bordered">
                <thead>
                  <tr className="text-center text-darkblue">
                    <th>Alan Adı</th>
                    <th>Açıklama</th>
                    <th>Sıralama</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr> 
                </thead>
                <tbody className="text-center">
                  {
                    this.state.fieldItems.map((fieldItemInfo) => 
                      <tr key={fieldItemInfo.id}>
                        <td className="align-middle">{fieldItemInfo.name}</td>
                        <td className="align-middle">{fieldItemInfo.description}</td>
                        <td className="align-middle">{fieldItemInfo.sort}</td>
                        <td className="align-middle">{fieldItemInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle">
                          <Link className="btn btn-outline btn-orange text-center mr-lg-2" title="Düzenle" to={'/categoryFieldItemListEdit?id=' + fieldItemInfo.id }><i className="fa fa-pencil text-white action-icon"></i></Link>
                          <button className="btn btn-outline btn-darkblue mr-sm-2 mb-1 mb-xl-0" onClick={() => this.deleteCategoryFieldItemOnClick(fieldItemInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                        </td>
                      </tr>
                    )
                  }
                  {this.state.fieldItems.length === 0 ?
                    <tr>
                      <td className="align-middle p-3" colSpan="5">Item Bulunamadı !</td>
                    </tr>
                  : null}
                </tbody>
                </table>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange" onClick={() => this.setState({isOpenAddedCategoryFieldItemModal: true})} title="Ekle"><i className="fa fa-plus"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddedCategoryFieldItemModal isOpen={this.state.isOpenAddedCategoryFieldItemModal} modalTitle="Item Ekle" modalDescription="Özel alana ait item eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({isOpenAddedCategoryFieldItemModal: false})} saveOnClick={fieldItemInfo => this.saveCategoryFieldItemOnClick(fieldItemInfo)} />
      </div>
    </div>
  </div>
  </>
  }
}

export default function CategoryFieldItemList() {
  return (
    <CategoryFieldItemListModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}
