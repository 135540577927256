import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import swal from 'sweetalert';

import WorkItemAddModal from '../../../component/workItemModals/WorkItemAddModal'

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDateTime} from '../../../inc/helper';
import ReactPaginate from 'react-paginate';

class WorkItemModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenWorkItemAddModal: false,

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 50,

      workItems: []
    };

    this.onCompleted = this.onCompleted.bind(this);
  }

  componentDidMount() {
    this.getWorkItems(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }
  
  getWorkItems(currentPage) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `workItems/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentWorkItems = responseData.workItems.slice(startIndex, endIndex);
          this.setState({isLoading: false, workItems: currentWorkItems, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handlePageClick = (page) => {
    const updatedPage = page.selected + 1;
    this.setState({ currentPage: updatedPage }, () => {this.getWorkItems(updatedPage);});
  }


  onCompleted() {
    this.getWorkItems(this.state.currentPage);
    this.setState({isOpenWorkItemAddModal: false});
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-1 pl-0">
                <div className="col-7 col-xl-8"><h5>İş Gereçleri Listesi</h5></div>
                <button className="btn btn-orange mr-2" onClick={() => this.setState({isOpenWorkItemAddModal: true})} title="Ekle"><i className="fa fa-plus"></i> Ürün Ekle</button>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Ürün Adı</th>
                      <th>Ürün Açıklaması</th>
                      <th>Düzenlenme Tarihi</th>
                      <th>Durum</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.workItems.map(workItemInfo =>
                      <tr key={workItemInfo.id}>
                        <td className="align-middle"><Link to={"/workItemMembers?id=" + workItemInfo.id}><strong>{workItemInfo.name}</strong></Link></td>
                        <td className="align-middle">{workItemInfo.description}</td>
                        <td className="align-middle">{isoToDateTime(workItemInfo.modifiedDate)}</td>
                        <td className="align-middle">{workItemInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle">
                          <Link className="btn btn-orange text-center mb-3 mr-0 mr-lg-1 mb-lg-0" title="Düzenle" to={"/workItemEdit?id=" + workItemInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                        </td>
                      </tr>
                    )}
                    {this.state.workItems.length === 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="5">Ürün Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
                {
                this.state.workItems.length !== 0 &&
                <div className="d-flex justify-content-sm-end justify-content-start pl-1 pl-sm-0">
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={10}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  forcePage={this.state.currentPage - 1}
                  />
                </div>
                }
              </div>
            </div>
          </div>
        </div>
        <WorkItemAddModal isOpen={this.state.isOpenWorkItemAddModal} modalTitle="Ürün Ekle" modalDescription="Demirbaş listesine ürün eklemek için aşağıdaki alanları doldurunuz !" onCompleted={this.onCompleted} onRequestClose={() => this.setState({isOpenWorkItemAddModal: false})} />
      </>
  }
}

export default function WorkItems() {
  return (
    <WorkItemModule navigate={useNavigate()} />
  )
}