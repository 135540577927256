let clientData = {
    id: '',
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    emailVerified: '',
    sessionHash: ''
};

export default clientData;