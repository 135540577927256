import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

//EditorState anlık görüntüyü alır
// createEmpty(), : state'i boş durumla başlat
//getCurrentContent  geçerli durumu alır
class BlogAddModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      blogInfo: {
        title: '',
        description: '',
        content: '',
        slug: '',
        keywords: '',
        author: '',
        footerTitle: '',
        footerDescription: ''
      },
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnChange(editorState) {
    this.setState({editorState: editorState})
  }
  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.saveBlogAddOnClick();
    }
  }

  saveBlogAddOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    
    if (!this.state.blogInfo.title) {
      infoMessage = 'Başlık boş geçilemez !';
    } else if (!this.state.blogInfo.description) {
      infoMessage = 'Açıklama boş geçilemez !';
    } else if (!this.state.blogInfo.content) {
      infoMessage = 'İçerik boş geçilemez !';
    } else if (!this.state.blogInfo.slug) {
      infoMessage = 'Slug boş geçilemez !';
    } else if (!this.state.blogInfo.keywords) {
      infoMessage = 'Anahtar kelime alanı boş geçilemez !';
    } else if (!this.state.blogInfo.author) {
      infoMessage = 'Yazar alanı boş geçilemez !';
    } else if (!this.state.blogInfo.footerTitle) {
      infoMessage = 'Footer başlığı boş geçilemez !';
    } else if (!this.state.blogInfo.footerDescription) {
      infoMessage = 'Footer açıklaması boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify(this.state.blogInfo)
      };

      fetch(config.restApi + 'blog/tr', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isCompleted: true, blogInfo: {title: '', description: '', content: '', slug: '', keywords: '', author: '', footerTitle: '', footerDescription: ''}});

            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Blog başarıyla eklendi.',
              buttons: 'Tamam'
            }).then(() => this.props.navigate('/blogEdit?id=' + responseData.blogId));
          }
          break;
          default: {
            this.setState({isCompleted: true});

            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  onEditorStateChange(editor) {
    let convertContent = draftToHtml(convertToRaw(editor.getCurrentContent()))
    this.setState({blogInfo: {...this.state.blogInfo, content: convertContent}})
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
           <div className="d-flex ibox justify-content-end">
            <button className="btn btn-orange text-white mr-2" onClick={() => this.saveBlogAddOnClick()} title="Kaydet"><i className="fa fa-save"></i> Kaydet</button>
            <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i> Geri</button>
            {/* <Link className="btn btn-darkblue text-white" title="Geri" to={"/blogs"}><i className="fa fa-reply"></i> Geri</Link> */}
           </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content text-darkblue">
                <h4>Yeni Blog Ekle</h4>
                <div className="hr-line-dashed"></div>
                <div className="row">
                  <div className="col">
                    <strong>Blog Başlığı<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({blogInfo: {...this.state.blogInfo, title: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Blog Başlığı" type="text" value={this.state.blogInfo.title} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Açıklama<strong className="text-danger ml-1">*</strong></strong>
                    <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({blogInfo: {...this.state.blogInfo, description: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Blog tanımı giriniz.." type="text" value={this.state.blogInfo.description} />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col">
                    <strong>İçerik<strong className="text-danger ml-1">*</strong></strong>
                    <Editor
                      editorClassName= "editor-class"
                      onEditorStateChange={this.onEditorStateChange}
                      editorStyle={{border: '1px solid #F1F1F1', height: '25vh'}}
                    /> 
                    {/* <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({blogInfo: {...this.state.blogInfo, content: event.target.value.trim()}})} placeholder="İçerik giriniz.." type="text" value={this.state.blogInfo.content}  /> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Slug<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({blogInfo: {...this.state.blogInfo, slug: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Slug" type="text" value={this.state.blogInfo.slug}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Anahtar Kelimeler<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({blogInfo: {...this.state.blogInfo, keywords: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Anahtar Kelimeler" type="text" value={this.state.blogInfo.keywords}/>
                  </div>
                  <div className="col-md-6">
                    <strong>Yazar<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({blogInfo: {...this.state.blogInfo, author: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Yazar" type="text" value={this.state.blogInfo.author}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Alt Başlık<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({blogInfo: {...this.state.blogInfo, footerTitle: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Alt Başlık" type="text" value={this.state.blogInfo.footerTitle}/>
                  </div>
                  <div className="col-md-6">
                    <strong>Alt Açıklama<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({blogInfo: {...this.state.blogInfo, footerDescription: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Alt Açıklama" type="text" value={this.state.blogInfo.footerDescription}/>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange" onClick={() => this.saveBlogAddOnClick()}>Kaydet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function BlogAdd() {
  return (
    <BlogAddModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}