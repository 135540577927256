import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDateTime} from '../../../inc/helper';

class WorkItemMemberModule extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isLoading: true,
  
      workItem : {},
      workItemMembers: []
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getWorkItemDetail();
  }

  componentWillUnmount() {
  }

  getWorkItemDetail() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/home'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'workItem/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, workItem: responseData.workItem});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'workItemMembers/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, workItemMembers: responseData.workItemMembers});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col overflow-auto">
            <div className="ibox table-width">
              <div className="ibox-title d-flex justify-content-between text-darkblue d-flex pr-4 pl-4">
                <h5>{this.state.workItem.name} Ürünü'nü Zimmet Edinen Üye Listesi</h5>
                <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
              </div>
              <div className="ibox-content">
                <table className="table table-stripped table-bordered ">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Adı Soyadı</th>
                      <th>Teslim Edilme Tarihi</th>
                      <th>Teslim Edilme Notu</th>
                      <th>Teslim Alma Tarihi</th>
                      <th>Teslim Alma Notu</th>
                      <th>Durum</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.workItemMembers.map(workItemMemberInfo =>
                      <tr key={workItemMemberInfo.id}>
                        <td className="align-middle"><Link to={"/memberWorkItemDetail?id=" + workItemMemberInfo.memberId}>{workItemMemberInfo.memberFullName}</Link></td>
                        <td className="align-middle">{isoToDateTime(workItemMemberInfo.deliveryDate)}</td>
                        <td className="align-middle">{workItemMemberInfo.deliveryNote ? workItemMemberInfo.deliveryNote : '-'}</td>
                        <td className="align-middle">{workItemMemberInfo.returnDate ? isoToDateTime(workItemMemberInfo.returnDate) : '-'}</td>
                        <td className="align-middle">{workItemMemberInfo.returnNote ? workItemMemberInfo.returnNote : '-'}</td>
                        <td className="align-middle">{workItemMemberInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                      </tr>
                    )}
                    {this.state.workItemMembers.length === 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="6">Bu Ürünü Daha Önce Zimmetleyen Üye Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function WorkItemMembers() {
  return (
    <WorkItemMemberModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}