import React from 'react';
import {Link,useNavigate, useSearchParams} from 'react-router-dom';

import swal from 'sweetalert';
import {getCookie} from '../../inc/cookies';

import config from '../../inc/config';
import { priceFormat } from '../../inc/helper';
import Images from '../../utilies/Images';

class PendingAdvertDetailModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      advertInfo: {
        languages: [],
        fields: [],
        attributes: [],
        contactInfo: [],
        categoryPath: [],
        photos: [],
      }
    };
  }

  componentDidMount() {
    this.getAdvertDetail();
    window.scrollTo(0, 0);
  }

  getAdvertDetail() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'advert/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, advertInfo: responseData.advertInfo});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  confirmAdvertOnClick = () => {
    let sessionHash = getCookie('sessionHash');

     const requestOptions = {
       method: 'PUT',
       headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
       body: JSON.stringify({advertStatus: 2})
     };

     fetch(config.restApi + 'advertStatus/tr/' + this.props.searchParams.get('id'), requestOptions)
     .then(response => response.json())
     .then(responseData => {
       this.setState({isLoading: false});
       switch (responseData.status) {
         case 200: {
           swal({
             dangerMode: true,
             icon: 'success',
             title: 'Tamamlandı',
             text: 'İlan başarıyla onaylandı.',
             buttons: 'Tamam'
           }).then(() => this.getAdvertDetail());
         }
         break;
         default: {
           swal({
             dangerMode: true,
             icon: 'warning',
             title: 'Hay Aksi',
             text: responseData.message,
             buttons: 'Tamam'
           });
         }
         break;
       }
     }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  rejectAdvertOnClick = () => {
    let sessionHash = getCookie('sessionHash');

     const requestOptions = {
       method: 'PUT',
       headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
       body: JSON.stringify({advertStatus: 3})
     };

     fetch(config.restApi + 'advertStatus/tr/' + this.props.searchParams.get('id'), requestOptions)
     .then(response => response.json())
     .then(responseData => {
       this.setState({isLoading: false});
       switch (responseData.status) {
         case 200: {
           swal({
             dangerMode: true,
             icon: 'success',
             title: 'Tamamlandı',
             text: 'İlan reddedildi.',
             buttons: 'Tamam'
           }).then(() => this.getAdvertDetail());
         }
         break;
         default: {
           swal({
             dangerMode: true,
             icon: 'warning',
             title: 'Hay Aksi',
             text: responseData.message,
             buttons: 'Tamam'
           });
         }
         break;
       }
     }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
 }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }  

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="col-lg-12">
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-2">
                <h4>İlan Detayı</h4>
                <div className="d-flex">
                  <Link className="btn border btn-height bg-orange text-white mr-2" title='Düzenle' to={'/advertEdit?id=' + this.props.searchParams.get('id')}><i className="fa fa-edit"></i></Link>
                  <Link className="btn border btn-height bg-darkblue text-white" title='Geri' to={'/pendingAdverts'}><i className="fa fa-reply"></i></Link>
                </div>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col">
                    <ul className="d-flex list-unstyled" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                      {this.state.advertInfo.categoryPath.map((category, index) => (
                        category.id > 0 || index > 0 ? 
                        <li key={index} className="breadcrumb-item">
                          <a className="text-orange" href="#" >
                            {category.name}
                          </a>
                        </li>
                        : null
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="d-flex justify-content-between mb-2">
                      <h4 className="text-orange">İlan Bilgileri</h4>
                      {this.state.advertInfo.advertStatus === 1 ? (
                        <div className="pending-grid">
                          <button className="btn btn-green mr-2" onClick={this.confirmAdvertOnClick}>İlanı Onayla</button>
                          <button className="btn btn-orange mr-2" onClick={this.rejectAdvertOnClick}>İlanı Reddet</button>
                        </div>
                      ) : this.state.advertInfo.advertStatus === 2 ? (
                        <div><h4 className="text-green"><strong>İlan onaylandı.</strong></h4></div>
                      ) : this.state.advertInfo.advertStatus === 3 ? (
                        <div><h4 className="text-orange"><strong>İlan reddedildi !</strong></h4></div>
                      ) : null}
                    </div>
                    <div className="hr-line-dashed mt-0"></div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-2">İlan Başlığı</h4>
                          <span>
                            {this.state.advertInfo.languages.find(languageInfo => languageInfo.language === "tr")?.title || '-'}
                            </span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-2">İlan Sahibi</h4>
                          <span>{this.state.advertInfo.memberFullName}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-2">Fiyat</h4>
                          <span>{priceFormat(this.state.advertInfo.price)} {this.state.advertInfo.currencySymbol}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-2">Bölge</h4>
                          <span>{this.state.advertInfo.townName + '/' + this.state.advertInfo.cityName}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-2">İletişim Bilgileri</h4>
                          <div className="d-block">
                            {this.state.advertInfo.contactInfo.map(contact => (
                              <div key={contact.id}>
                                <h4 className="font-normal">{contact.fullname}</h4>
                                {contact.phones.map((phone, index) => (
                                  <h4 className="font-normal" key={index}>{this.phoneFormat(phone.phoneNumber)}</h4>
                                ))}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">İlana Ait Özel Alanlar</h4>
                    <div className="hr-line-dashed mt-0"></div>
                    <div className="row">
                      {this.state.advertInfo.fields.map((fieldInfo) => 
                      <div className="d-flex justify-content-betwen align-items-center col-md-6">
                      <div className="m-2" key={fieldInfo.id}>
                        <h4 className="font-bold text-black mr-1 my-0">{fieldInfo.name}:</h4>
                      </div>
                      <div>{fieldInfo.data}</div>
                      </div>
                      )}
                    </div>
                    {this.state.advertInfo.fields.length === 0 && <div className="row"><div className="col-12 d-flex justify-content-center"><h4 className="font-normal">İlana Ait Özel Alan Bulunamadı !</h4></div></div>}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                  <h4 className="text-orange">İlana Ait Özellikler</h4>
                  <div className="hr-line-dashed mt-0"></div>
                    {this.state.advertInfo.attributes.map((attributeInfo) => (
                      <div key={attributeInfo.id}>
                        <h4 className="d-flex text-darkblue">{attributeInfo.name}</h4>
                        <div className="row">
                          {attributeInfo.items.map((itemInfo) => (
                            <div key={itemInfo.id} className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3">
                              {itemInfo.selected === 1 ? (
                                <h4 className="font-bold">
                                  <i className="fa fa-check text-green mr-1"></i>
                                  {itemInfo.name}
                                </h4>
                              ) : (
                                <h4 className="font-bold">
                                  <i className="fa fa-close text-orange mr-1"></i>
                                  {itemInfo.name}
                                </h4>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                    {this.state.advertInfo.attributes.length === 0 && <div className="row"><div className="col-12 d-flex justify-content-center"><h4 className="font-normal">İlana Ait Özellik Bulunamadı !</h4></div></div>}
                    <div>
                    <h4 className="text-orange">Fotoğraflar ({this.state.advertInfo.photos.length})</h4>
                    <div className="hr-line-dashed mt-0"></div>
                    <div className="row">
                      {this.state.advertInfo.photos.length !== 0 ?
                        this.state.advertInfo.photos.map(photo =>
                        <div className="col-xl-6">
                          <div style={{ position: "relative", display: "inline-block", border:"1px solid lightgray", backgroundColor: "#F5F5F5"}}>
                            <img className='col-12 p-0' style={{height:250, width:389, objectFit:"contain", overflow: "visible", display: "block"}} src={`https://file.satisgaranti.com/${photo.path}`}/>
                            <img style={{position: "absolute", top: 0, left: 0}} src={this.state.advertInfo.photos[0].path === photo.path  ? Images.showCase : ''}/>
                          </div>
                        </div>
                      ): <div className="col-12 d-flex justify-content-center">İlana Ait Fotoğraf Bulunamadı !</div>}
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function PendingAdvertDetail() {
  return (
    <PendingAdvertDetailModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}