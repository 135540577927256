import React from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import { getCookie } from "../../inc/cookies";
import config from "../../inc/config";
import { isoToDateTime, priceFormat } from "../../inc/helper";

class ExpertisesModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      appraisalAppointments: [],
      filterId: '',
      filterPlate: '',
      filterFullName: '',
      filterAppointmentStartDate: '',
      filterAppointmentEndDate: '',
      filterStatus: '',

      appointmentStatus: [
        {id: 0, status: "Randevu için sıraya alındı"},
        {id: 1, status: "Randevu yapılıyor"},
        {id: 2, status: "Randevu başarılı"},
        {id: 3, status: "Randevu başarısız"}
      ],

      paymentStatus: [
        {id: 1, status: "Ödeme Bekleniyor"},
        {id: 2, status: "Ödeme Başarılı"},
        {id: 3, status: "Ödeme Başarısız"},
        {id: 4, status: "Ücretsiz"}
      ],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10,
    };
  }

  componentDidMount() {
    this.getAppointments(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  filteredAppointments(currentPage) {
    let sessionHash = getCookie("sessionHash");

    const query = `limit=${this.state.limit}&page=${currentPage}&filterId=${this.state.filterId}&filterPlate=${this.state.filterPlate}&filterFullName=${this.state.filterFullName}&filterAppointmentStartDate=${this.state.filterAppointmentStartDate}&filterAppointmentEndDate=${this.state.filterAppointmentEndDate}&filterStatus=${this.state.filterStatus}`;

    const requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + sessionHash },
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `appraisalAppointments/tr?${query}`, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              const currentappraisalAppointments = responseData.appraisalAppointments.slice(startIndex, endIndex);
              this.setState({appraisalAppointments: currentappraisalAppointments, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
            break;
            case 401:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              }).then(this.props.navigate('/home'))
            break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
        },
        () =>
          swal({
            dangerMode: true,
            icon: "error",
            title: "Bağlantı Hatası",
            text: "Lütfen internet bağlantınızı kontrol ediniz.",
            buttons: "Tamam",
          })
      );
  }

  getAppointments(currentPage) {
    let sessionHash = getCookie("sessionHash");

    const requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + sessionHash },
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `appraisalAppointments/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              const currentappraisalAppointments = responseData.appraisalAppointments.slice(startIndex, endIndex);
              this.setState({isLoading: false, appraisalAppointments: currentappraisalAppointments, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
            break;
            case 401:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              }).then(this.props.navigate('/home'))
            break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
        },
        () =>
          swal({
            dangerMode: true,
            icon: "error",
            title: "Bağlantı Hatası",
            text: "Lütfen internet bağlantınızı kontrol ediniz.",
            buttons: "Tamam",
          })
      );
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.filteredAppointments(this.state.currentPage))
  }

  filterActiveOnChange = (event) => {
    this.setState({filterStatus: event.target.value});
  }

  filterOnClick = () => {
    const query = `filterId=${this.state.filterId}&filterPlate=${this.state.filterPlate}&filterFullName=${this.state.filterFullName}&filterAppointmentStartDate=${this.state.filterAppointmentStartDate}&filterAppointmentEndDate=${this.state.filterAppointmentEndDate}&filterStatus=${this.state.filterStatus}`;
    this.filteredAppointments(1, query)
  }

  resetOnClick = (event) => {
    this.setState({
      filterId: '',
      filterPlate: '',
      filterFullName: '',
      filterAppointmentStartDate: '',
      filterAppointmentEndDate: '',
      filterStatus: '',
      currentPage: 1
    }, () => {this.setState({isLoading: true}, () => {this.filteredAppointments(this.state.currentPage);this.setState({isLoading: false});});});
  }

  handleKeyPress = (event, page) => {
    if (event.key === 'Enter') {
      const query = `filterId=${this.state.filterId}&filterPlate=${this.state.filterPlate}&filterFullName=${this.state.filterFullName}&filterAppointmentStartDate=${this.state.filterAppointmentStartDate}&filterAppointmentEndDate=${this.state.filterAppointmentEndDate}&filterStatus=${this.state.filterStatus}`;
      this.setState({isLoading: true, currentPage: 1}, () => {this.filteredAppointments(this.state.currentPage, query);this.setState({isLoading: false});});
    }
  }

  render() {
    return this.state.isLoading ? (
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    ) : (
      <div className="row custom-container">
        <div className="col-xl-9">
          <div className="ibox">
            {/* <div className="ibox">
              <div className="alert alert-info d-flex justify-content-center">
                1 adet ücretsiz ekspertiz kullanım hakkınız var. Son kullanma tarihi 24/08/2023.
              </div>
            </div> */}
            <div className="ibox-title">
              <strong className="text-black">Ekspertiz İşlemleri</strong>
            </div>
            <div className="table-responsive-lg mb-5">
              <table className="table table-bordered text-center">
                <thead>
                  <tr className="text-black">
                    <th>Randevu No</th>
                    <th>Bayi Adı</th>
                    <th>Randevu Tarihi</th>
                    <th>Firma Adı - Sahibi</th>
                    <th>Adı Soyadı</th>
                    <th>Plaka</th>
                    <th>Toplam</th>
                    <th>Durum</th>
                    <th>Detay</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.appraisalAppointments.map((appraisalAppointment, index) =>
                    <tr key={index}>
                      <td className="align-middle">{appraisalAppointment.id}</td>
                      <td className="align-middle">{appraisalAppointment.pointName}<br/>{appraisalAppointment.pointCityName} / {appraisalAppointment.pointTownName}</td>
                      <td className="align-middle">{isoToDateTime(appraisalAppointment.appointmentDate)}</td>
                      <td className="align-middle">{appraisalAppointment.storeName ? appraisalAppointment.storeName : "-"}<br/><b>{appraisalAppointment.storeOwner ? appraisalAppointment.storeOwner : "-"}</b></td>
                      <td className="align-middle">{appraisalAppointment.fullname}</td>
                      <td className="align-middle">{appraisalAppointment.plate}</td>
                      <td className="align-middle">{priceFormat(appraisalAppointment.price)} ₺</td>
                      <td className="align-middle">
                        {appraisalAppointment.active === 1 ? (appraisalAppointment.paymentStatus === 2 || appraisalAppointment.paymentStatus === 4) ?
                        this.state.appointmentStatus.find(status => status.id === appraisalAppointment.appoinmentStatus)?.status
                        : <span>Ödeme Bekleniyor</span> : <span>İptal Edildi</span>
                        }
                      </td>
                      <td className="align-middle">
                        <Link className="btn btn-outline btn-orange text-white text-center mb-1" to={"/expertiseDetail?id=" + appraisalAppointment.id} title="Randevu Detay"><i className="fa fa-eye"></i></Link>
                      </td>
                    </tr>
                  )}
                  {this.state.appraisalAppointments.length === 0 && <tr><td className="align-middle text-darkblue p-3" colSpan="7">Ekspertiz Başvurusu Bulunamadı !</td></tr>}
                </tbody>
              </table>
            </div>
            {this.state.appraisalAppointments.length !== 0 &&
            <ReactPaginate
              previousLabel={"Geri"}
              nextLabel={"İleri"}
              breakLabel={"..."}  
              pageCount={this.state.pageCount}
              marginPagesDisplayed={1}
              onPageChange={page => this.handlePageClick(page)}
              pageRangeDisplayed={2}
              containerClassName={'pagination'}
              activeClassName={'activePage'}
            />
            }
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
          <div className="ibox">
            <div className="ibox-content text-darkblue p-0">
              <h4 className="collapse-title">
                <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
              </h4>
            </div>
            <div className="collapse" data-parent="#accordion" id="filterCollapse">
              <div className="ibox-content text-darkblue">  
                <strong>Randevu No</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterId: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Randevu No" type="text" value={this.state.filterId}/>
                <strong>Üye Adı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.filterFullName}/>
                <strong>Plaka</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPlate: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Plaka" type="text" value={this.state.filterPlate}/>
                <strong>Durum</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={this.filterActiveOnChange} onKeyDown={this.handleKeyPress} value={this.state.filterStatus}>
                  <option value="">Seçiniz</option>
                  <option value="0">İşlem Bekleniyor</option>
                  <option value="1">İşlem Alındı</option>
                  <option value="2">İşlem Tamamlandı</option>
                  <option value="3">İşlem Başarısız</option>
                </select>
                <strong>Başlangıç Tarihi</strong>
                <input className="form-control form-inputt mb-2 mt-1" onChange={event => this.setState({filterAppointmentStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentStartDate}/>
                <strong>Bitiş Tarihi</strong>
                <input className="form-control form-input" onChange={event => this.setState({filterAppointmentEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentEndDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
          <div className="ibox">
            <div className="ibox-title text-darkblue">
              <h5><i className="fa fa-filter"></i> Filtrele</h5>
            </div>
            <div className="ibox-content text-darkblue">
              <strong>Randevu No</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterId: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Randevu No" type="text" value={this.state.filterId}/>
              <strong>Üye Adı</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.filterFullName}/>
              <strong>Plaka</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPlate: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Plaka" type="text" value={this.state.filterPlate}/>
              <strong>Durum</strong>
              <select className="form-control form-input mb-2 mt-1" onChange={this.filterActiveOnChange} onKeyDown={this.handleKeyPress} value={this.state.filterStatus}>
                <option value="">Seçiniz</option>
                <option value="0">İşlem Bekleniyor</option>
                <option value="1">İşlem Alındı</option>
                <option value="2">İşlem Tamamlandı</option>
                <option value="3">İşlem Başarısız</option>
              </select>
              <strong>Başlangıç Tarihi</strong>
              <input className="form-control form-inputt mb-2 mt-1" onChange={event => this.setState({filterAppointmentStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentStartDate}/>
              <strong>Bitiş Tarihi</strong>
              <input className="form-control form-input" onChange={event => this.setState({filterAppointmentEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentEndDate} />
              <div className="d-flex justify-content-end">
                <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function Expertises(props) {
  return (
    <ExpertisesModule navigate={useNavigate()} searchParams={useSearchParams()[0]}></ExpertisesModule>
  );
}
