import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import swal from 'sweetalert';
import Select from 'react-select'

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDate, isoToDateTime, isoToTime} from '../../inc/helper';

class PersonalEventsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      memberEvents: []
    }
  }

  componentDidMount() {
    console.log('crmMemberAppointments: componentDidMount');
    this.getPersonalEvents()
    window.scrollTo(0, 0);
  }
  
  componentDidUpdate() {
    console.log('crmMemberAppointments: componentDidUpdate');
  }
  
  componentWillUnmount() {
    console.log('crmMemberAppointments: componentWillUnmount');
  }

  getPersonalEvents() {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'memberEvents/tr/', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, memberEvents: responseData.memberEvents})
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).this.props.navigate('/home');
        break;
        default:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getAttributeGroups()));
  }

  eventType = (eventType) => {
    switch (eventType) {
      case "fingerprint":
        
        return "Parmak İzi"
    
      default:
        return "s"
    }
  }
  

  render() {

    const groupByMemberEventDate = (memberEvents) => {
      return memberEvents.reduce((groups, event) => {
        const date = event.eventDate;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(event);
        return groups;
      }, {});
    };
    
    const groupedEvents = groupByMemberEventDate(this.state.memberEvents);

    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="row custom-container">
        <div className="col-xl-12">
          <div className="ibox">
            <div className="ibox-content text-darkblue pr-3">
              <div className="d-flex justify-content-between">
                <h4>Personel Hareketleri</h4>
              </div>
            </div>
            <div className="table-responsive overflow-x-auto">
              {Object.keys(groupedEvents).map(eventDate => (
                <table key={eventDate} className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-left text-darkblue">
                      <th>{isoToDate(eventDate)}</th>
                      <th>Başlangıç Saati</th>
                      <th>Bitiş Saati</th>
                      <th>Etkinlik</th>
                    </tr>
                  </thead>
                  <tbody className="text-left">
                    {groupedEvents[eventDate].map(memberEventInfo => (
                      <tr key={memberEventInfo.memberId}>
                        <td className="">{memberEventInfo.memberFullName || '-'} ({memberEventInfo.memberId})</td>
                        <td className="">{isoToTime(memberEventInfo.startDate)}</td>
                        <td className="">{isoToTime(memberEventInfo.endDate)}</td>
                        <td className="">{this.eventType(memberEventInfo.eventType)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}
              {this.state.memberEvents.length === 0 ?
                <tr>
                  <td className="align-middle p-3" colSpan="4">Personel Hareketi Bulunamadı !</td>
                </tr>
              : null}
            </div>
          </div>
        </div>
      </div>
  }
}

export default function CrmMemberAppointmentsReport() {
  return (
    <PersonalEventsModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}