import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import { getCookie } from '../../../inc/cookies';
import config from '../../../inc/config';

import AddedCategoryFieldItemLanguageModal from '../../../component/category/categoryField/AddedCategoryFieldItemLanguageModal';

class CategoryFieldItemListEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddedCategoryFieldItemLanguageModal: false,

      sort: 0,

      fieldItemLanguageId: 0,
      fieldItemLanguages: [],
      fieldItemInfo: {},

      editCategoryFieldItemLanguages: null
    };
  }

  componentDidMount() {
    this.getCategoryFieldItemDetail();
    window.scrollTo(0, 0);
  }

  getCategoryFieldItemDetail() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 3;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'fieldItem/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({sort: responseData.fieldItemInfo.sort});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'fieldItemLanguages/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({fieldItemLanguages: responseData.fieldItemLanguages});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'fieldItem/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({fieldItemInfo: responseData.fieldItemInfo});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

  }

  saveCategoryFieldItemOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.sort && this.state.sort !== 0) {
      infoMessage = 'Sıralama boş geçilemez !';
    } 

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({sort: parseInt(this.state.sort)})
      };

      fetch(config.restApi + 'fieldItem/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});

        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Item başarıyla güncellendi.',
              buttons: 'Tamam'
            });
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  saveCategoryFieldItemLanguageOnClick(categoryFieldItemLanguageInfo, id) {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({name: categoryFieldItemLanguageInfo.name, language: categoryFieldItemLanguageInfo.language, description: categoryFieldItemLanguageInfo.description})
    };

    fetch(config.restApi + 'fieldItemLanguage/tr/' + id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Özel alana ait çeviri başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getCategoryFieldItemDetail(), this.setState({isOpenAddedCategoryFieldItemLanguageModal: false}), categoryFieldItemLanguageInfo.name = '', categoryFieldItemLanguageInfo.language = '', categoryFieldItemLanguageInfo.description = '');
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCategoryFieldItemLanguageOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Çeviriyi silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'fieldItemLanguage/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Çeviri başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCategoryFieldItemDetail());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  updateCategoryFieldItemLanguageOnClick(categoryFieldItemLanguageInfo, id) {
    
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(categoryFieldItemLanguageInfo)
    };

    fetch(config.restApi + 'fieldItemLanguage/tr/' + id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Çeviri başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.getCategoryFieldItemDetail(), this.setState({isOpenAddedCategoryFieldItemLanguageModal: false, editCategoryFieldItemLanguages: categoryFieldItemLanguageInfo}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.saveCategoryFieldItemOnClick();
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
      <div className="row">
        <div className="col">
          <div className="ibox">
            <div className="ibox-title text-darkblue d-flex justify-content-between pr-2">
              <h5><strong className="text-orange">{this.state.fieldItemInfo.name}</strong> Item'ını Düzenle</h5>
              <div>
                <button className="btn btn-orange mr-2" title="Kaydet" onClick={this.saveCategoryFieldItemOnClick}><i className="fa fa-save"></i></button>
                <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
              </div>
            </div>
            <div className="ibox-content">  
              <div className="row mb-5">
                <div className="col-md-6">
                  <h5 className="text-darkblue"><strong>Sıralama<strong className="text-danger ml-1">*</strong></strong></h5>
                  <input className="form-control form-input mb-2 mt-1" min="0" onChange={(event) => this.setState({sort: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Sıralama" type="number" value={this.state.sort} />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darkblue"><strong className="text-orange">{this.state.fieldItemInfo.name}</strong>  Item'ına Ait Çeviri Listesi</h4>
                    <button className="btn btn-orange" onClick={() => this.setState({editCategoryFieldItemLanguages: null, isOpenAddedCategoryFieldItemLanguageModal: true})} title="Dil Ekle"><i className="fa fa-plus"></i></button>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="row">
                    <div className="col">
                    <div className="overflow-auto">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="text-center text-darkblue">
                          <th>Çeviri</th>
                          <th>Dil</th>
                          <th>Açıklama</th>
                          <th>Durum</th>
                          <th>İşlem</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {this.state.fieldItemLanguages.map((fieldItemLanguageInfo) =>
                          <tr key={fieldItemLanguageInfo.id}>
                            <td className="align-middle text-orange">{fieldItemLanguageInfo.name}</td>
                            <td className="align-middle">{fieldItemLanguageInfo.language}</td>
                            <td className="align-middle">{fieldItemLanguageInfo.description}</td>
                            <td className="align-middle">{fieldItemLanguageInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                            <td className="align-middle">
                              <button onClick={() => this.setState({isOpenAddedCategoryFieldItemLanguageModal: true, fieldItemLanguageId: fieldItemLanguageInfo.id, editCategoryFieldItemLanguages: fieldItemLanguageInfo})} className="btn btn-outline btn-orange text-center mr-2" title="Düzenle"><i className="fa fa-pencil text-white action-icon"></i></button>
                              <button className="btn btn-outline btn-darkblue" onClick={() => this.deleteCategoryFieldItemLanguageOnClick(fieldItemLanguageInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                            </td>
                          </tr>
                        )}
                        {this.state.fieldItemLanguages.length === 0 ?
                          <tr>
                            <td className="align-middle p-3" colSpan="5">Çeviri Listesi Bulunamadı !</td>
                          </tr>
                        : null}
                      </tbody> 
                      </table>
                    </div>
                    </div>
                  </div>
                  <AddedCategoryFieldItemLanguageModal isOpen={this.state.isOpenAddedCategoryFieldItemLanguageModal} modalTitle="Çeviri Ekle" modalDescription="Item'a ait çeviri eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editCategoryFieldItemLanguages: null, isOpenAddedCategoryFieldItemLanguageModal: false})} saveOnClick={categoryFieldItemLanguageInfo => this.saveCategoryFieldItemLanguageOnClick(categoryFieldItemLanguageInfo, this.props.searchParams.get('id'))} />
                  {this.state.editCategoryFieldItemLanguages ?
                  <AddedCategoryFieldItemLanguageModal defaultValue={this.state.editCategoryFieldItemLanguages} isOpen={this.state.isOpenAddedCategoryFieldItemLanguageModal} modalTitle="Çeviri Düzenle" modalDescription="Çeviri bilgisini düzenlemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editCategoryFieldItemLanguages: null, isOpenAddedCategoryFieldItemLanguageModal: false})} saveOnClick={categoryFieldItemLanguageInfo => this.updateCategoryFieldItemLanguageOnClick(categoryFieldItemLanguageInfo, this.state.fieldItemLanguageId)} />
                  : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  }
}

export default function CategoryFieldItemListEdit() {
  return (
    <CategoryFieldItemListEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}