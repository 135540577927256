import React from 'react';
import Modal from 'react-modal';
import { getCookie } from '../../inc/cookies';
import swal from 'sweetalert';
import config from '../../inc/config';

export default class AdvertRotationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,

      degree: 0,
    };
    this.rotate = this.rotate.bind(this);
    this.rotateleft = this.rotateleft.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  updatePhotoRotation() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({degree: this.state.degree})
    };

    fetch(config.restApi + 'advertPhotoRotate/tr/' + this.props.photoId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Fotoğraf başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.setState({isLoading: true}, () => {this.props.onRotationCompleted();this.setState({isLoading: false});}));
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  rotate() {
    const currentRotation = this.state.degree || 0;
    const newRotation = (currentRotation + 90) % 360;
  
    this.setState(({degree: newRotation }));
  }
  
  rotateleft() {
    const currentRotation = this.state.degree || 0;
    const newRotation = (currentRotation - 90) % 360;
  
    this.setState(({degree: newRotation }));
  }
 
  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex:2500
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={() => { this.props.onRequestClose(); this.setState({ degree: 0 }); }}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue d-flex justify-content-center p-1">
                <img style={{width:345, height: 345, objectFit:"contain", overflow: "visible", transform: `rotate(${this.state.degree || 0}deg)`}} src={`https://file.satisgaranti.com/${this.props.photoPath}`}/>
              </div>
              <div className="d-flex justify-content-center mb-2">
                <button className="btn btn-default btn-circle" onClick={() => this.rotateleft(this.props.photoId)} ><i className="fa fa-undo" style={{pointerEvents: 'none', color:"black"}}></i></button>
                <button className="btn btn-default btn-circle ml-4" onClick={() => this.rotate(this.props.photoId)} ><i className="fa fa-rotate-right" style={{pointerEvents: 'none', color:"black"}}></i></button>
              </div>
              <div className="modal-footer"> 
                <button className="btn btn-orange" onClick={() => this.updatePhotoRotation()}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}
