import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import swal from 'sweetalert';

import PaymentTypeAddModal from '../../../component/siteManagementModals/paymentTypes/PaymentTypeAddModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDateTime} from '../../../inc/helper';

class PaymentTypeModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenPaymentTypeAddModal: false,

      paymentTypes: []
    };

    this.onCompleted = this.onCompleted.bind(this);
  }

  componentDidMount() {
    this.getPaymentTypes();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  getPaymentTypes() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'paymentTypes', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, paymentTypes: responseData.paymentTypes});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  onCompleted() {
    this.getPaymentTypes();
    this.setState({isOpenPaymentTypeAddModal: false});
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-2 pl-0">
                <div className="col-7 col-xl-8"><h5>Ödeme Tipi Listesi</h5></div>
                <button className="btn btn-orange mb-2" onClick={() => this.setState({isOpenPaymentTypeAddModal: true})} title="Ekle"><i className="fa fa-plus"></i> Ödeme Tipi Ekle</button>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Ödeme Tipi</th>
                      <th>Düzenlenme Tarihi</th>
                      <th>Sıralama</th>
                      <th>Durum</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.paymentTypes.map((paymentTypeInfo) =>
                      <tr key={paymentTypeInfo.id}>
                        <td className="align-middle">{paymentTypeInfo.name}</td>
                        <td className="align-middle">{isoToDateTime(paymentTypeInfo.modifiedDate)}</td>
                        <td className="align-middle">{paymentTypeInfo.sort}</td>
                        <td className="align-middle">{paymentTypeInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle">
                          <Link className="btn btn-orange text-center" to={"/paymentTypeEdit?id=" + paymentTypeInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                        </td>
                      </tr>
                    )}
                    {this.state.paymentTypes.length == 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="5">Ödeme Tipi Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PaymentTypeAddModal isOpen={this.state.isOpenPaymentTypeAddModal} modalTitle="Ödeme Tipi Ekle" modalDescription="Ödeme tipi eklemek için aşağıdaki alanları doldurunuz !" onCompleted={this.onCompleted} onRequestClose={() => this.setState({isOpenPaymentTypeAddModal: false})} />
      </>
  }
}

export default function PaymentTypes() {
  return (
    <PaymentTypeModule navigate={useNavigate()} />
  )
}