import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import AddressModal from '../../component/AddressModal';
import PhoneModal from '../../component/PhoneModal';

import config from '../../inc/config';
import {getCookie} from '../../inc/cookies';

class MemberAddModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isOpenAddressModal: false,
      isOpenPhoneModal: false,

      member: {
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        memberAddresses: [],
        memberPhones: []
      }
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillMount() {
  }

  saveMemberAddressOnClick(addressInfo) {
    let memberAddresses = this.state.member.memberAddresses;
    memberAddresses.push(addressInfo);

    this.setState({member: {...this.state.member, memberAddresses: memberAddresses}, editAddress: null, isOpenAddressModal: false});
  }

  updateMemberAddressOnClick(addressInfo) {
    let memberAddresses = this.state.member.memberAddresses;

    let index = memberAddresses.findIndex(item => item.id == addressInfo.id);

    memberAddresses[index] = addressInfo;

    this.setState({member: {...this.state.member, memberAddresses: memberAddresses}, editAddress: null, isOpenAddressModal: false});
  }

  deleteMemberAddressOnClick(index) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Adresi silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let memberAddresses = this.state.member.memberAddresses;
        memberAddresses.splice(index, 1);

        this.setState({member: {...this.state.member, memberAddresses: memberAddresses}, editAddress: null});

        swal({
          dangerMode: true,
          icon: 'success',
          title: 'Tamamlandı',
          text: 'Adres başarıyla silindi.',
          buttons: 'Tamam'
        });
      }
    });
  }

  saveMemberPhoneOnClick(phoneInfo) {
    let memberPhones = this.state.member.memberPhones;
    memberPhones.push(phoneInfo);

    this.setState({member: {...this.state.member, memberPhones: memberPhones}, editPhone: null, isOpenPhoneModal: false});
  }

  updateMemberPhoneOnClick(phoneInfo) {
    let memberPhones = this.state.member.memberPhones;

    let index = memberPhones.findIndex(item => item.id == phoneInfo.id);

    memberPhones[index] = phoneInfo;

    this.setState({member: {...this.state.member, memberPhones: memberPhones}, editPhone: null, isOpenPhoneModal: false});
  }

  deleteMemberPhoneOnClick(index) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Telefonu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let memberPhones = this.state.member.memberPhones;
        memberPhones.splice(index, 1);

        this.setState({member: {...this.state.member, memberPhones: memberPhones}, editPhone: null});

        swal({
          dangerMode: true,
          icon: 'success',
          title: 'Tamamlandı',
          text: 'Telefon başarıyla silindi.',
          buttons: 'Tamam'
        });
      }
    });
  }

  handleKeyPress(event) {
    if(event.key === 'Enter') {
      this.saveMemberOnClick();
    }
  }

  saveMemberOnClick = (event) => {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(this.state.member)
    };

    fetch(config.restApi + 'member/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false});
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Üye başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/memberEdit?id=' + responseData.memberId));
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="row custom-container">
        <div className="col">
          <div className="ibox">
            <div className="ibox-title d-flex justify-content-between text-darkblue pr-2">
              <h4 className="text-darkblue">Üye Ekle</h4>
              <button className="btn bg-orange text-white" onClick={this.saveMemberOnClick} title="Kaydet"><i className="fa fa-save"></i></button>
            </div>
            <div className="row">
              <div className="col">
                <div className="ibox">
                  <div className="ibox-content text-darkblue">
                    <div className="row">
                      <div className="col">
                      <div className="row">
                        <div className="col-md-6">
                          <h5><strong>Üye Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                          <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({member: {...this.state.member, firstname: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')}})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.member.firstname} />
                        </div>
                        <div className="col-md-6">
                          <h5><strong>Üye Soyadı<strong className="text-danger ml-1">*</strong></strong></h5>
                          <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({member: {...this.state.member, lastname: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')}})} onKeyDown={this.handleKeyPress} placeholder="Üye Soyadı" type="text" value={this.state.member.lastname} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h5><strong>Kullanıcı Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                          <input className="form-control form-input mb-1" onChange={event => this.setState({member: {...this.state.member, username: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Kullanıcı Adı" type="text" value={this.state.member.username} />
                        </div>
                        <div className="col-md-6">
                          <h5><strong>E-posta<strong className="text-danger ml-1">*</strong></strong></h5>
                          <input className="form-control form-input mb-1" onChange={event => this.setState({member: {...this.state.member, email: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.member.email} />
                        </div>
                      </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col">
                        <div className="d-flex justify-content-between">
                          <h4>Adres Bilgileri</h4>
                          <button className="btn btn-orange" onClick={() => this.setState({editAddress: null, isOpenAddressModal: true})} title="Adres Ekle"><i className="fa fa-plus"></i></button>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="overflow-auto">
                          <table className="table table-striped table-bordered">
                          <thead>
                            <tr className="text-center text-darkblue">
                              <th>İl</th>
                              <th>İlçe</th>
                              <th>Semt</th>
                              <th>Mahalle</th>
                              <th>Açık Adres</th>
                              <th>Açıklama</th>
                              <th>Durum</th>
                              <th>İşlem</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {this.state.member.memberAddresses.map((memberAddressInfo, index) =>
                              <tr key={index}>
                                <td className="align-middle">{memberAddressInfo.cityName}</td>
                                <td className="align-middle">{memberAddressInfo.townName}</td>
                                <td className="align-middle">{memberAddressInfo.districtName}</td>
                                <td className="align-middle">{memberAddressInfo.quarterName}</td>
                                <td className="align-middle">{memberAddressInfo.address}</td>
                                <td className="align-middle">{memberAddressInfo.description}</td>
                                <td className="align-middle">{memberAddressInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                                <td className="align-middle" style={{minWidth:98.5}}>
                                  <button className="btn btn-orange m-1" onClick={() => this.setState({editAddress: memberAddressInfo, isOpenAddressModal: true})} title="Düzenle"><i className="fa fa-pencil"></i></button>
                                  <button className="btn btn-darkblue" onClick={() => this.deleteMemberAddressOnClick(index)} title="Sil"><i className="fa fa-trash"></i></button>
                                </td>
                              </tr>
                            )}
                            {this.state.member.memberAddresses.length == 0 ?
                              <tr>
                                <td className="align-middle p-3" colSpan="8">Adres Bulunamadı !</td>
                              </tr>
                            : null}
                          </tbody>
                          </table>
                          <AddressModal isOpen={this.state.isOpenAddressModal} modalTitle="Adres Ekle" modalDescription="Adres bilgisi eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editAddress: null, isOpenAddressModal: false})} saveOnClick={addressInfo => this.saveMemberAddressOnClick(addressInfo)} />
                          {this.state.editAddress ?
                            <AddressModal defaultValue={this.state.editAddress} isOpen={this.state.isOpenAddressModal} modalTitle="Adres Düzenle" modalDescription="Adres bilgisini düzenlemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editAddress: null, isOpenAddressModal: false})} saveOnClick={addressInfo => this.updateMemberAddressOnClick(addressInfo)} />
                          : null}
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col">
                        <div className="d-flex justify-content-between">
                          <h4>Telefon Bilgileri<strong className="text-danger ml-1">*</strong></h4>
                          <button className="btn btn-orange" onClick={() => this.setState({editPhone: null, isOpenPhoneModal: true})} title="Telefon Ekle"><i className="fa fa-plus"></i></button>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="overflow-auto">
                          <table className="table table-striped table-bordered">
                          <thead>
                            <tr className="text-center text-darkblue">
                              <th>Telefon Tipi</th>
                              <th>Telefon Numarası</th>
                              <th>Ülke</th>
                              <th>Varsayılan</th>
                              <th>İletişim Numarası</th>
                              <th>Durum</th>
                              <th>İşlem</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {this.state.member.memberPhones.map((memberPhoneInfo, index) =>
                              <tr key={index}>
                                <td className="align-middle">{memberPhoneInfo.phoneTypeName}</td>
                                <td className="align-middle">{this.phoneFormat(memberPhoneInfo.phoneNumber)}</td>
                                <td className="align-middle">{memberPhoneInfo.countryName}</td>
                                <td className="align-middle">-</td>
                                <td className="align-middle">-</td>
                                <td className="align-middle">{memberPhoneInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                                <td className="align-middle" style={{minWidth:98.5}}>
                                  <button className="btn btn-orange m-1" onClick={() => this.setState({editPhone: memberPhoneInfo, isOpenPhoneModal: true})} title="Düzenle"><i className="fa fa-pencil"></i></button>
                                  <button className="btn btn-darkblue" onClick={() => this.deleteMemberPhoneOnClick(index)} title="Sil"><i className="fa fa-trash"></i></button>
                                </td>
                              </tr>
                            )}
                            {this.state.member.memberPhones.length == 0 ?
                              <tr>
                                <td className="align-middle p-3" colSpan="7">Telefon Bulunamadı !</td>
                              </tr>
                            : null}
                          </tbody>
                          </table>
                          <PhoneModal isOpen={this.state.isOpenPhoneModal} modalTitle="Telefon Ekle" modalDescription="Telefon bilgisi eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editPhone: null, isOpenPhoneModal: false})} saveOnClick={phoneInfo => this.saveMemberPhoneOnClick(phoneInfo)} />
                          {this.state.editPhone ?
                            <PhoneModal defaultValue={this.state.editPhone} isOpen={this.state.isOpenPhoneModal} modalTitle="Telefon Düzenle" modalDescription="Telefon bilgisini düzenlemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editPhone: null, isOpenPhoneModal: false})} saveOnClick={phoneInfo => this.updateMemberPhoneOnClick(phoneInfo)} />
                          : null}
                        </div>
                      </div>
                    </div>
                    <div className="text-row text-right mt-2">
                      <button className="btn bg-orange text-white" onClick={this.saveMemberOnClick}><i className="fa fa-save"></i> Kaydet</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function MemberAdd() {
  return (
    <MemberAddModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}