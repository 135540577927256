import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import swal from 'sweetalert';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDateTime} from '../../inc/helper';
import Select from 'react-select';

class MemberHistoryModule extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date().toISOString().split('T')[0];
    this.state = {
      isLoading: true,

      memberHistory: [],
      cities: [],
      towns: [],
      companyEmployees: [],
      quantity: "1000",

      filterAddedStartDate: today,
      filterAddedByIds: '',

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    }
  }

  componentDidMount() {
    console.log('memberHistory: componentDidMount');
    this.getMemberHistory(this.state.currentPage);
    window.scrollTo(0, 0);
  }
  
  componentDidUpdate(prevProps, prevState) {
    console.log('memberHistory: componentDidUpdate');
    
    if (this.props.searchParams !== prevProps.searchParams) {
    }

    let newTabIndex = parseInt(this.props.searchParams.get('tab')) || 0;
    if (prevState.selectedTabIndex !== newTabIndex) {
      this.setState({selectedTabIndex: newTabIndex});
    }
  }
  
  componentWillUnmount() {
    console.log('memberHistory: componentWillUnmount');
  }

  filteredMemberHistory(currentPage) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const query = `limit=${this.state.limit}&page=${currentPage}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterAddedByIds=${this.state.filterAddedByIds}`;

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `report/memberHistory/tr?${query}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentMemberHistorys = responseData.memberHistories.slice(startIndex, endIndex);
          this.setState({isLoading: false, memberHistory: currentMemberHistorys, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        case 401:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getMemberHistory(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    this.setState({isLoading: true});

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(() => {});
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `report/memberHistory/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentMemberHistorys = responseData.memberHistories.slice(startIndex, endIndex);
          this.setState({memberHistory: currentMemberHistorys, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'company/tr/employees?limit=1000&filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, companyEmployees: responseData.companyEmployees});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  handlePageClick = (page) => {
    const updatedPage = page.selected + 1;
    this.setState({ currentPage: updatedPage }, () => {this.filteredMemberHistory(updatedPage);});
  }

  filterOnClick = (event) => {
    const query = `&filterAddedStartDate=${this.state.filterAddedStartDate}&filterAddedByIds=${this.state.filterAddedByIds}`;
    this.filteredMemberHistory(1, query)
  }

  resetOnClick = (event) => {
    this.setState({
      filterAppointmentDate: '',
      filterAddedStartDate: '',
      filterAddedByIds: '',
      currentPage: 1
    }, () => {this.setState({isLoading: true}, () => {this.filteredMemberHistory(this.state.currentPage);this.setState({isLoading: false});});});
  }

  handleKeyPress = (event, page) => {
    if (event.key === 'Enter') {
      const query = `&filterAddedStartDate=${this.state.filterAddedStartDate}&filterAddedByIds=${this.state.filterAddedByIds}`;
      
      this.setState({isLoading: true, currentPage: 1}, () => {
        this.filteredMemberHistory(this.state.currentPage, query);
        this.setState({isLoading: false});
      });
    }
  }

  selectedEmployeeOnChange = (selectedEmployees) => {
    this.setState({filterAddedByIds: selectedEmployees ? selectedEmployees.map(employee => employee.value) : []});
  };

  render() {
    let sessionHash = getCookie("sessionHash");

    const selectedEmployeesOptions = this.state.companyEmployees.map((companyEmployeeInfo) => ({
      value: companyEmployeeInfo.id,
      label: `${companyEmployeeInfo.firstname} ${companyEmployeeInfo.lastname}`,
      search: companyEmployeeInfo.firstname + ' ' + companyEmployeeInfo.lastname
    }));

    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="row custom-container">
        <div className="col-xl-9">
          <div className="ibox">
            <div className="ibox-content text-darkblue pr-3">
              <div className="d-flex justify-content-between">
                <h4>Üye Geçmişi</h4>
                <div className="d-flex justify-content-end">
                  <input className="col-md-4 form-control form-input mx-2" onChange={event => this.setState({quantity: event.target.value})} placeholder="Miktar" type="number" value={this.state.quantity} />
                  <a className="btn btn-orange" href={`${config.restApi}report/memberHistory/tr?download=1&limit=${this.state.quantity}` + `&sessionHash=${sessionHash}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterAddedByIds=${this.state.filterAddedByIds}`} target="_blank" title="İndir"><i className="fa fa-save"></i></a>
                </div>
              </div>
            </div>
            <div className="table-responsive overflow-x-auto">
              <table className="table table-striped table-bordered">
              <thead>
                <tr className="text-center text-darkblue">
                  <th>Üye Adı Soyadı</th>
                  <th>Firma Adı</th>
                  <th>Paket Adı</th>
                  <th>Mesajı Yazan</th>
                  <th>Mesaj</th>
                  <th>Mesaj Tarihi ve Saati</th>
                  <th>İşlem</th>
                </tr>
              </thead>
              <tbody className="text-center">
              {this.state.memberHistory.map(cmemberHistoryInfo =>
                <tr key={cmemberHistoryInfo.id}>
                  <td className="align-middle">{cmemberHistoryInfo.memberFullname}</td>
                  <td className="align-middle"><b>{cmemberHistoryInfo.storeName || '-'}</b><br /><span className="text-darkblue">Üye No: </span><span className="text-darkblue"> #{cmemberHistoryInfo.memberId}</span></td>
                  <td className="align-middle">{cmemberHistoryInfo.transactionProducts}</td>
                  <td className="align-middle">{cmemberHistoryInfo.addedByFullname}</td>
                  <td className="align-middle">{cmemberHistoryInfo.comment}</td>
                  <td className="align-middle">{isoToDateTime(cmemberHistoryInfo.addedDate)}</td>
                  <td className="align-middle"><Link className="btn btn-orange text-center" to={"/memberEdit?id=" + cmemberHistoryInfo.memberId + "&tab=4"}><i className="fa fa-pencil text-white action-icon"></i></Link></td>
                </tr>
              )}
              {this.state.memberHistory.length === 0 ?
                <tr>
                  <td className="align-middle p-3" colSpan="7">Üye Geçmişi Bulunamadı !</td>
                </tr>
              : null}
              </tbody>
              </table>
              {
                this.state.memberHistory.length !== 0 &&
                <div className="d-flex justify-content-sm-end justify-content-start pl-1 pl-sm-0">
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={10}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  forcePage={this.state.currentPage - 1}
                  />
                </div>
                }
            </div>
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
          <div className="ibox">
            <div className="ibox-content text-darkblue p-0">
              <h4 className="collapse-title">
                <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
              </h4>
            </div>
            <div className="collapse" data-parent="#accordion" id="filterCollapse">
              <div className="ibox-content text-darkblue">
                <strong>Mesaj Tarihi ve Saati</strong>
                <input className="form-control form-input" onChange={event => this.setState({filterAddedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedStartDate} />
                <h5 className="text-darkblue"><strong>Mesajı Yazan</strong></h5>
                <Select
                  isMulti
                  placeholder="Mesajı Yazan"
                  name="colors"
                  options={selectedEmployeesOptions}
                  onChange={this.selectedEmployeeOnChange}
                  onKeyDown={this.handleKeyPress}
                  value={selectedEmployeesOptions.filter(employeeOptionInfo =>
                      this.state.filterAddedByIds.includes(employeeOptionInfo.value)
                  )}
                  filterOption={(option, searchText) =>
                      option.data.search &&
                      option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick} type="button"><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick} type="button"><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
          <div className="ibox">
            <div className="ibox-title text-darkblue">
              <h5><i className="fa fa-filter"></i> Filtrele</h5>
            </div>
            <div className="ibox-content text-darkblue">
              <strong>Mesaj Tarihi ve Saati</strong>
              <input className="form-control form-input mb-2" onChange={event => this.setState({filterAddedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedStartDate} />
              <h5 className="text-darkblue"><strong>Mesajı Yazan</strong></h5>
              <Select
                isMulti
                placeholder="Mesajı Yazan"
                name="colors"
                options={selectedEmployeesOptions}
                onChange={this.selectedEmployeeOnChange}
                onKeyDown={this.handleKeyPress}
                value={selectedEmployeesOptions.filter(employeeOptionInfo =>
                    this.state.filterAddedByIds.includes(employeeOptionInfo.value)
                )}
                filterOption={(option, searchText) =>
                    option.data.search &&
                    option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
              <div className="d-flex justify-content-end">
                <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function MemberHistory() {
  return (
    <MemberHistoryModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}