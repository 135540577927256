import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

class AgreementLanguageEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      agreementId: 0,
      language: '',
      name: '',
      content: '',
      active: 1
    };
  }

  componentDidMount() {
    this.getAgreementLanguage();
    window.scrollTo(0, 0);
  }

  getAgreementLanguage() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'agreementLanguage/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, agreementId: responseData.agreementLanguage.agreementId, language: responseData.agreementLanguage.language, name: responseData.agreementLanguage.name, content: responseData.agreementLanguage.content, active: responseData.agreementLanguage.active});
        }
        break;
        case 404: {
          this.setState({isLoading: false});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/agreements'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  activeOnChange = (event) => {

    let active = event.target.value == '' ? '' : parseInt(event.target.value);
    
    this.setState({active: active});

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'activeStatus/agreementLanguages/' + this.props.searchParams.get('id') + '/' + active, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {}
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveAgreementLanguageOnClick();
    }
  }

  saveAgreementLanguageOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.name) {
      infoMessage = 'Sözleşme adı boş geçilemez !';
    } else if (!this.state.language) {
      infoMessage = 'Sözleşme dili boş geçilemez !';
    } else if (!this.state.content) {
        infoMessage = 'Sözleşme içeriği boş geçilemez !';
      }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({language: this.state.language, name: this.state.name, content: this.state.content})
      };

      fetch(config.restApi + 'agreementLanguage/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});

        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Sözleşme bilgisi başarıyla güncellendi.',
              buttons: 'Tamam'
            });
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content text-darkblue">
                <div className="d-flex justify-content-between">
                  <h4>Sözleşme Bilgisi Düzeltme</h4>
                  <div className="d-flex">
                    <button className="btn btn-orange mr-2" onClick={this.saveAgreementLanguageOnClick} title="Kaydet"><i className="fa fa-save"></i></button>
                    <Link className="btn btn-darkblue text-white" title="Geri" to={"/agreementEdit?id=" + this.state.agreementId}><i className="fa fa-reply"></i></Link>
                  </div>
                </div>
                <div className="hr-line-dashed"></div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Sözleşme Adı<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({name: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Sözleşme Adı" type="text" value={this.state.name} />
                  </div>
                  <div className="col-md-6">
                    <strong>Sözleşme Dili<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({language: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Sözleşme Dili" type="text" value={this.state.language} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h5><strong>Durum</strong></h5>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.activeOnChange} onKeyDown={this.handleKeyPress} value={this.state.active}>
                      <option value="1">Açık</option>
                      <option value="0">Kapalı</option>
                    </select>                                  
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Sözleşme Açıklaması<strong className="text-danger ml-1">*</strong></strong>
                    <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({content: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Açıklama giriniz.." type="text" value={this.state.content} />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange" onClick={this.saveAgreementLanguageOnClick}>Kaydet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function AgreementLanguageEdit() {
  return (
    <AgreementLanguageEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}