import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToMysqlDate, inputDateTimeToIsoDateTime} from '../../../inc/helper';

class MemberWorkExpenseEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      memberId: 0,
      name: '',
      description: '',
      expenseDate: null,
      expenseResult: '',
      price: 0,
      active: 1,
      locked: 0
  };
  }

  componentDidMount() {
    this.getMemberWorkExpense();
    window.scrollTo(0, 0);
  }

  getMemberWorkExpense() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'memberWorkExpense/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, active: responseData.memberWorkExpense.active, memberId: responseData.memberWorkExpense.memberId, name: responseData.memberWorkExpense.name, description: responseData.memberWorkExpense.description, expenseDate: responseData.memberWorkExpense.expenseDate, expenseResult: responseData.memberWorkExpense.expenseResult, price: responseData.memberWorkExpense.price.toString(), locked: responseData.memberWorkExpense.locked});
        }
        break;
        case 404: {
          this.setState({isLoading: false});
        }
      break;
      default: {
        this.setState({isLoading: false});

      swal({
        dangerMode: true,
        icon: 'warning',
        title: 'Hay Aksi',
        text: responseData.message,
        buttons: 'Tamam'
      }).then(() => this.props.navigate('/memberWorkExpenseDetail?id=' + this.state.memberId));
      }
      break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  activeOnChange = (event) => {

    let active = event.target.value === '' ? '' : parseInt(event.target.value);
    
    this.setState({active: active});

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'activeStatus/memberWorkExpenses/' + this.props.searchParams.get('id') + '/' + active, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {}
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveMemberWorkExpenseOnClick();
    }
  }

  saveMemberWorkExpenseOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';

    if (!this.state.name) {
      infoMessage = 'Masraf adı boş geçilemez !';
    } else if (!this.state.description) {
      infoMessage = 'Açıklama boş geçilemez !';
    } else if (!this.state.expenseDate) {
      infoMessage = 'Harcama tarihi boş geçilemez !';
    } else if (!this.state.price) {
      infoMessage = 'Tutar boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({memberId: this.state.memberId, name: this.state.name, description: this.state.description, expenseDate: inputDateTimeToIsoDateTime(this.state.expenseDate), expenseResult: this.state.expenseResult, price: parseInt(this.state.price)})
      };

      fetch(config.restApi + 'memberWorkExpense/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});

        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Masraf bilgisi başarıyla güncellendi.',
              buttons: 'Tamam'
            });
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  saveExpenseLockedStatusOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onay Durumu',
      text: 'Harcamayı onaylamak istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'PUT',
          headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        };

        fetch(config.restApi + 'memberWorkExpenseLocked/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Harcama onaylandı.',
                buttons: 'Tamam'
              }).then(() => this.getMemberWorkExpense())
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  deleteMemberWorkExpenseOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Masrafı silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'memberWorkExpense/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Masraf başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.props.navigate('/memberWorkExpenseDetail?id=' + this.state.memberId));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>

        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content text-darkblue">
                <div className="d-flex justify-content-between">
                  <h4>Masraf Bilgisi Güncelleme</h4>
                  <div className="d-flex justify-content-end">
                      {
                        this.state.locked ? <button className="btn btn-green border mr-2" onClick={() => this.saveExpenseLockedStatusOnClick()} title="Onaylanmış Masraf"><i className="fa fa-check"></i> Onaylandı</button>
                        :
                        <button className="btn btn-orange border mr-2" onClick={() => this.saveExpenseLockedStatusOnClick()} title="Masraf Onayla"><i className="fa fa-check"></i> Masraf Onayla</button>
                      }
                      <button className="btn btn-orange mr-2" onClick={() => this.deleteMemberWorkExpenseOnClick()} title="Masraf Sil"><i className="fa fa-trash"></i></button>
                      <button className="btn btn-orange border mr-2" onClick={() => this.saveMemberWorkExpenseOnClick()} title="Kaydet"><i className="fa fa-save"></i></button>
                      <Link className="btn btn-darkblue text-white" title="Geri" to={"/memberWorkExpenseDetail?id=" + this.state.memberId}><i className="fa fa-reply"></i></Link>
                  </div>
                </div>
                <div className="hr-line-dashed"></div>
                <div className="row">
                  <div className="col">
                    <strong>Masraf Adı<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({name: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Masraf Adı" type="text" value={this.state.name}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Açıklama<strong className="text-danger ml-1">*</strong></strong>
                    <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({description: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Açıklama giriniz.." type="text" value={this.state.description} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Harcama Tarihi<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-control-sm form-input mb-2 mt-1" onChange={(event) => this.setState({expenseDate: event.target.value || null})} onKeyDown={this.handleKeyPress} type="date" value={this.state.expenseDate ? isoToMysqlDate(this.state.expenseDate) : ''} />
                  </div>
                  <div className="col-md-6">
                    <strong>Tutar<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({price: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Tutar" type="number" value={this.state.price}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h5><strong>Durum</strong></h5>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.activeOnChange} onKeyDown={this.handleKeyPress} value={this.state.active}>
                      <option value="1">Açık</option>
                      <option value="0">Kapalı</option>
                    </select>                                  
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Not</strong>
                    <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({expenseResult: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="İletmek istediğiniz notu giriniz.." type="text" value={this.state.expenseResult} />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange" onClick={() => this.saveMemberWorkExpenseOnClick()}>Kaydet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function MemberWorkExpenses() {
  return (
    <MemberWorkExpenseEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}