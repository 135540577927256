import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

class AgreementAddModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      agreement: {
        language: '',
        name: '',
        content: ''
      }
    };
      }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveAgreementOnClick();
    }
  }

  saveAgreementOnClick = (event) =>{
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.agreement.name) {
      infoMessage = 'Sözleşme adı boş geçilemez !';
    } else if(!this.state.agreement.language) {
      infoMessage = 'Sözleşme dili boş geçilemez !';
    } else if(!this.state.agreement.content) {
      infoMessage = 'Sözleşme içeriği boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    }
    else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify(this.state.agreement)
      };

      fetch(config.restApi + 'agreement', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({isLoading: false});
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Sözleşme başarıyla eklendi.',
              buttons: 'Tamam'
            }).then(() => this.props.navigate('/agreementEdit?id=' + responseData.agreementId));
          }
          break;
          default: {
            this.setState({isLoading: false});
  
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content text-darkblue">
                <div className="d-flex justify-content-between">
                  <h4>Sözleşme Ekle</h4>
                <div className="d-flex">
                  <button className="btn btn-orange mr-2" onClick={this.saveAgreementOnClick}><i className="fa fa-save"></i></button>
                  <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
                </div>
                </div>
                <div className="hr-line-dashed"></div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Sözleşme Adı<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({agreement: {...this.state.agreement, name: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Sözleşme Adı" type="text" value={this.state.agreement.name}/>
                  </div>
                  <div className="col-md-6">
                    <strong>Sözleşme Dili<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({agreement: {...this.state.agreement, language: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Sözleşme Dili" type="text" value={this.state.agreement.language}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Sözleşme İçeriği<strong className="text-danger ml-1">*</strong></strong>
                    <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({agreement: {...this.state.agreement, content: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Açıklama giriniz.." type="text" value={this.state.agreement.content} />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange" onClick={this.saveAgreementOnClick}>Kaydet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function AgreementAdd() {
  return (
    <AgreementAddModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}