import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import swal from 'sweetalert';
import Select from 'react-select'

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDate, isoToDateTime, isoToMysqlDateTime} from '../../../inc/helper';

class CrmMemberAppointmentModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      priority: [
        {id:1, icon: 'fa-free-code-camp', color: 'badge-danger', name: 'Sıcak', title: 'Sıcak'},
        {id:2, icon: 'fa-tint', color: 'badge-warning', name: 'Ilık', title: 'Ilık'},
        {id:3, icon: 'fa-snowflake-o', color: 'badge-success', name: 'Soğuk', title: 'Soğuk'}
      ],
      crmMemberAppointments: [],
      crmMemberTypes: [],
      cities: [],
      companyMarketers: [],
      towns: [],
      

      filterActive: '',
      filterAppointmentStartDate: '',
      filterAppointmentEndDate: '',
      filterCompanyName: '',
      filterCompanyType: '',
      filterCrmMemberTypeId: '',
      filterEmail: '',
      filterFullName: '',
      filterMarketerId: '',
      filterPhone: '',
      filterPriority: '',

      filterCityId: '',
      filterTownId: '',

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount() {
    this.getCrmMemberAppointments(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  filteredCrmMemberAppointments(currentPage) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const query = `limit=${this.state.limit}&page=${currentPage}&filterCrmMemberTypeId=${this.state.filterCrmMemberTypeId}&filterCompanyName=${this.state.filterCompanyName}&filterCompanyType=${this.state.filterCompanyType}&filterFullName=${this.state.filterFullName}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterPriority=${this.state.filterPriority}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterAppointmentStartDate=${this.state.filterAppointmentStartDate}&filterAppointmentEndDate=${this.state.filterAppointmentEndDate}&filterActive=${this.state.filterActive}&filterMarketerId=${this.state.filterMarketerId}`;

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `crmMemberAppointments/tr?${query}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentcrmMemberAppointments = responseData.crmMemberAppointments.slice(startIndex, endIndex);
          this.setState({isLoading: false, crmMemberAppointments: currentcrmMemberAppointments, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        case 401:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getCrmMemberAppointments(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    this.setState({isLoading: true});

    let errorMessages = [];
    let remaining = 4;
    let complete = () => {
      remaining--;
      if (remaining == 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/home'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `crmMemberAppointments/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentCrmMemberAppointments = responseData.crmMemberAppointments.slice(startIndex, endIndex);
          this.setState({crmMemberAppointments: currentCrmMemberAppointments, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'crmMemberTypes?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMemberTypes: responseData.crmMemberTypes});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'cities/tr/1?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({cities: responseData.cities, towns: []});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'company/tr/marketers?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({companyMarketers: responseData.companyMarketers});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getTowns(cityId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'towns/tr/' + cityId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({filterCityId: cityId, filterTownId: '', towns: responseData.towns});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getPhoneNumber(crmMemberAppointmentInfo) {
    let activeCrmMemberPhones = crmMemberAppointmentInfo.crmMemberPhones.filter(item => item.active == 1);

    if(activeCrmMemberPhones.length > 0) {
      let activeCrmMemberPhoneNumber = activeCrmMemberPhones[0].phoneNumber.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
      let phoneMasking = `(${activeCrmMemberPhoneNumber[1]}) ${activeCrmMemberPhoneNumber[2]} ${activeCrmMemberPhoneNumber[3]} ${activeCrmMemberPhoneNumber[4]}` 
      return phoneMasking;
    }
    
    return '-'
  }

  getRegion(crmMemberAppointmentInfo) {
    let activeCrmMemberAddresses = crmMemberAppointmentInfo.crmMemberAddresses.filter(item => item.active == 1);
    return activeCrmMemberAddresses.length > 0 ? activeCrmMemberAddresses[0].cityName + '/' + activeCrmMemberAddresses[0].townName : '-';
  }

  selectedCityOnChange = (selectedCity) => {
    let filterCityId = selectedCity ? selectedCity.value : '';
    if (filterCityId) {
      this.getTowns(filterCityId);
    } else {
      this.setState({filterCityId: '', filterTownId: '', towns: []});
    }
  }

  selectedTownOnChange = (selectedTown) => {
    this.setState({filterTownId: selectedTown ? selectedTown.value : ''});
  }

  selectedCompanyMarketerOnChange = (selectedCompanyMarketer) => {
    this.setState({filterMarketerId: selectedCompanyMarketer ? selectedCompanyMarketer.value : ''});
  };

  handlePageClick = (page) => {
    const updatedPage = page.selected + 1;
    this.setState({ currentPage: updatedPage }, () => {this.filteredCrmMemberAppointments(updatedPage);});
  }
 
  filterOnClick = (event) => {
    const query = `filterCrmMemberTypeId=${this.state.filterCrmMemberTypeId}&filterCompanyName=${this.state.filterCompanyName}&filterCompanyType=${this.state.filterCompanyType}&filterFullName=${this.state.filterFullName}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterPriority=${this.state.filterPriority}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterAppointmentStartDate=${this.state.filterAppointmentStartDate}&filterAppointmentEndDate=${this.state.filterAppointmentEndDate}&filterActive=${this.state.filterActive}&filterMarketerId=${this.state.filterMarketerId}`;
    this.filteredCrmMemberAppointments(1, query)
  }

  resetOnClick = (event) => {
    this.setState({
      filterActive: '',
      filterAppointmentStartDate: '',
      filterAppointmentEndDate: '',
      filterCompanyName: '',
      filterCompanyType: '',
      filterCrmMemberTypeId: '',
      filterEmail: '',
      filterFullName: '',
      filterMarketerId: '',
      filterPhone: '',
      filterPriority: '',
      filterCityId: '',
      filterTownId: '',
      currentPage: 1
    }, () => {this.setState({isLoading: true}, () => {this.filteredCrmMemberAppointments(this.state.currentPage);this.setState({isLoading: false});});});
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const query = `filterCrmMemberTypeId=${this.state.filterCrmMemberTypeId}&filterCompanyName=${this.state.filterCompanyName}&filterCompanyType=${this.state.filterCompanyType}&filterFullName=${this.state.filterFullName}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterPriority=${this.state.filterPriority}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterAppointmentStartDate=${this.state.filterAppointmentStartDate}&filterAppointmentEndDate=${this.state.filterAppointmentEndDate}&filterActive=${this.state.filterActive}&filterMarketerId=${this.state.filterMarketerId}`;
      this.setState({isLoading: true, currentPage: 1}, () => {this.filteredCrmMemberAppointments(this.state.currentPage, query);this.setState({isLoading: false});});
    }
  }

  handleCurrentDayFilter = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    this.setState({filterAppointmentStartDate: formattedDate, filterAppointmentEndDate: formattedDate});
    const query = `filterCrmMemberTypeId=${this.state.filterCrmMemberTypeId}&filterCompanyName=${this.state.filterCompanyName}&filterCompanyType=${this.state.filterCompanyType}&filterFullName=${this.state.filterFullName}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterPriority=${this.state.filterPriority}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterAppointmentStartDate=${this.state.filterAppointmentStartDate}&filterAppointmentEndDate=${this.state.filterAppointmentEndDate}&filterActive=${this.state.filterActive}&filterMarketerId=${this.state.filterMarketerId}`;
    this.setState({isLoading: true, currentPage: 1}, () => {this.filteredCrmMemberAppointments(this.state.currentPage, query);this.setState({isLoading: false});});
  }

  handleLasttDayFilter = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    this.setState({filterAppointmentStartDate: "2019-01-01", filterAppointmentEndDate: formattedDate, filterActive: "1"});
    const query = `filterCrmMemberTypeId=${this.state.filterCrmMemberTypeId}&filterCompanyName=${this.state.filterCompanyName}&filterCompanyType=${this.state.filterCompanyType}&filterFullName=${this.state.filterFullName}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterPriority=${this.state.filterPriority}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterAppointmentStartDate=${this.state.filterAppointmentStartDate}&filterAppointmentEndDate=${this.state.filterAppointmentEndDate}&filterActive=${this.state.filterActive}&filterMarketerId=${this.state.filterMarketerId}`;
    this.setState({isLoading: true, currentPage: 1}, () => {this.filteredCrmMemberAppointments(this.state.currentPage, query);this.setState({isLoading: false});});
  }

  handleLastOpenStatustDayFilter = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = (today.getDate() - 1).toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    this.setState({filterAppointmentStartDate: "2019-01-01", filterAppointmentEndDate: formattedDate, filterActive: "0"});
    const query = `filterCrmMemberTypeId=${this.state.filterCrmMemberTypeId}&filterCompanyName=${this.state.filterCompanyName}&filterCompanyType=${this.state.filterCompanyType}&filterFullName=${this.state.filterFullName}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterPriority=${this.state.filterPriority}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterAppointmentStartDate=${this.state.filterAppointmentStartDate}&filterAppointmentEndDate=${this.state.filterAppointmentEndDate}&filterActive=${this.state.filterActive}&filterMarketerId=${this.state.filterMarketerId}`;
    this.setState({isLoading: true, currentPage: 1}, () => {this.filteredCrmMemberAppointments(this.state.currentPage, query);this.setState({isLoading: false});});
  }

  handleNextDayFilter = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = (today.getDate() + 1).toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const nextdate = new Date();
    const NextYear = nextdate.getFullYear() + 1;
    const nextMonth = (nextdate.getMonth() + 1).toString().padStart(2, '0');
    const nextDay = (today.getDate() + 1).toString().padStart(2, '0');
    const formattedNextDate = `${NextYear}-${nextMonth}-${nextDay}`;

    this.setState({filterAppointmentStartDate: formattedDate, filterAppointmentEndDate: formattedNextDate});
    const query = `filterCrmMemberTypeId=${this.state.filterCrmMemberTypeId}&filterCompanyName=${this.state.filterCompanyName}&filterCompanyType=${this.state.filterCompanyType}&filterFullName=${this.state.filterFullName}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterPriority=${this.state.filterPriority}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterAppointmentStartDate=${this.state.filterAppointmentStartDate}&filterAppointmentEndDate=${this.state.filterAppointmentEndDate}&filterActive=${this.state.filterActive}&filterMarketerId=${this.state.filterMarketerId}`;
    this.setState({isLoading: true, currentPage: 1}, () => {this.filteredCrmMemberAppointments(this.state.currentPage, query);this.setState({isLoading: false});});
  }

  currrentDay = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate
  }

  render() {
    const selectedCompanyMarketersOptions = [
      { value: '', label: 'Seçiniz' },
      ...this.state.companyMarketers.map(companyMarketerInfo => ({
        value: companyMarketerInfo.id,
        label: `${companyMarketerInfo.firstname} ${companyMarketerInfo.lastname}`,
        search: companyMarketerInfo.firstname + ' ' + companyMarketerInfo.lastname
      }))
    ];

    const selectedCitiesOptions = [
      {value: '', label: 'Seçiniz' }, ...this.state.cities.map((cityInfo) => ({
        value: cityInfo.id,
        label: `${cityInfo.name}`,
        search: cityInfo.name
      }))
    ];
    
    const selectedTownsOptions = [
      { value: '', label: 'Seçiniz' }, ...this.state.towns.map((townInfo) => ({
        value: townInfo.id,
        label: `${townInfo.name}`,
        search: townInfo.name
      }))
    ];

    return this.state.isLoading ? 
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row d-none">
          <div className="col">
            <div className="d-flex ibox justify-content-end">
              <Link className="btn btn-orange" title="Randevu Ekle" to="/crmMemberAppointmentAdd"><i className="fa fa-plus"></i> Randevu Ekle</Link>
            </div>
          </div>
        </div>
        <div className="row custom-container">
          <div className="col-xl-9">
            <div className="ibox">
              <div className="ibox-title d-flex justify-content-between align-items-center text-darkblue pr-1">
                <h5>Randevu Listesi</h5>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="btn-open justify-content-center">
                    <div className="btn border border-darkblue text-center mr-2">
                      <span className="btn-open dropdown-toggle font-bold text-darkblue" data-toggle="dropdown"><i className="fa fa-ellipsis-v text-darkblue"></i></span>
                      <ul className="dropdown-menu overflow-auto mr-3 mt-2">
                        <li><Link className="btn btn-height dropdown-item font-bold" title="Tümü" onClick={this.resetOnClick}><i className="fa fa-list m-r-xs"></i> Tümü</Link></li>
                        <li><Link className="btn btn-height dropdown-item font-bold" title="Tamamlanan" onClick={this.handleLastOpenStatustDayFilter}><i className="fa fa-check-square-o m-r-xs"></i> Tamamlanan</Link></li>
                        <li><Link className="btn btn-height dropdown-item font-bold" title="Gecikmiş" onClick={this.handleLasttDayFilter}><i className="fa fa-history m-r-xs"></i> Gecikmiş</Link></li>
                        <li><Link className="btn btn-height dropdown-item font-bold" title="Bugün" onClick={this.handleCurrentDayFilter}><i className="fa fa-arrow-circle-down text-darkblue m-r-xs"></i> Bugün</Link></li>
                        <li><Link className="btn btn-height dropdown-item font-bold" title="Gelecek" onClick={this.handleNextDayFilter}><i className="fa fa-clock-o text-darkblue m-r-xs"></i> Gelecek</Link></li>
                      </ul>
                    </div>
                  </div>
                  <Link className="btn border btn-none text-black mr-2" title="Tümü" onClick={this.resetOnClick}><i className='fa fa-list'></i><span className="d-none d-sm-inline"> Tümü</span></Link>
                  <Link className="btn bg-green btn-none text-white mr-2" title="Tamamlanan" onClick={this.handleLastOpenStatustDayFilter}><i className='fa fa-check-square-o'></i><span className="d-none d-sm-inline"> Tamamlanan</span></Link>
                  <Link className="btn bg-red btn-none text-white mr-2" title="Gecikmiş" onClick={this.handleLasttDayFilter}><i className='fa fa-history'></i><span className="d-none d-sm-inline"> Gecikmiş</span></Link>
                  <Link className="btn bg-yellow btn-none text-white mr-2" title="Bugün" onClick={this.handleCurrentDayFilter}><i className='fa fa-arrow-circle-down'></i><span className="d-none d-sm-inline"> Bugün</span></Link>
                  <Link className="btn bg-ligthblue btn-none text-white mr-2" title="Gelecek" onClick={this.handleNextDayFilter}><i className='fa fa-clock-o'></i><span className="d-none d-sm-inline"> Gelecek</span></Link>
                </div>
               {/*  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button> */}
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                <thead>
                  <tr className="text-center text-darkblue">
                    <th>Randevu Sahibi</th>
                    <th>Firma Adı</th>
                    <th>Faaliyet Alanı</th>
                    <th>Telefon</th>
                    <th>Bölge</th>
                    <th>Satış Sorumlusu</th>
                    <th>Eklenme Zamanı</th>
                    <th>Randevu Zamanı</th>
                    <th>Randevu Önceliği</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                {this.state.crmMemberAppointments.map(crmMemberAppointmentInfo =>
                  <tr key={crmMemberAppointmentInfo.id} style={{
                    backgroundColor:
                      (isoToMysqlDateTime(crmMemberAppointmentInfo.appointmentDate) < new Date().toLocaleString("sv-SE") && crmMemberAppointmentInfo.active === 1 ? "#FFEBEE" :
                      (isoToMysqlDateTime(crmMemberAppointmentInfo.appointmentDate) < new Date().toLocaleString("sv-SE") && crmMemberAppointmentInfo.active === 0 ? "#E8F5E9" :
                      (isoToDate(crmMemberAppointmentInfo.appointmentDate).split('.').reverse().join('.') === this.currrentDay().split('.').reverse().join('.') ? "#FFF8E1" :
                      (isoToDate(crmMemberAppointmentInfo.appointmentDate).split('.').reverse().join('.') > this.currrentDay().split('.').reverse().join('.') ? "#E1F5FE" : ""))))
                    }}>
                    <td className="align-middle">{<Link title="Potansiyel Üye Profiline Git" to={'/crmMemberEdit?id=' + crmMemberAppointmentInfo.crmMemberId}>{crmMemberAppointmentInfo.crmMemberFullName}</Link>}<br /><strong className="text-darkblue">{crmMemberAppointmentInfo.crmMemberTypeName}</strong></td>
                    <td className="align-middle">{crmMemberAppointmentInfo.companyName || '-'}</td>
                    <td className="align-middle">{crmMemberAppointmentInfo.companyType || '-'}</td>
                    <td className="align-middle">{this.getPhoneNumber(crmMemberAppointmentInfo)}</td>
                    <td className="align-middle">{this.getRegion(crmMemberAppointmentInfo)}</td>
                    <td className="align-middle">{(crmMemberAppointmentInfo.crmMemberMarketers.map(crmMemberMarketerInfo => <h5 className="font-normal" key={crmMemberMarketerInfo.id}>{crmMemberMarketerInfo.fullName}</h5>))}</td>
                    <td className="align-middle">{isoToDateTime(crmMemberAppointmentInfo.addedDate)}</td>
                    <td className="align-middle">{isoToDateTime(crmMemberAppointmentInfo.appointmentDate)}</td>
                    <td className="align-middle">{this.state.priority.map(priority => priority.id === crmMemberAppointmentInfo.priority ? <button title={`${priority.name}`} className={`btn btn-middle ${priority.color}`} key={priority.id} style={{minWidth:42}}><i className={`fa ${priority.icon} text-white`} /></button> : null)}</td>
                    <td className="align-middle">{crmMemberAppointmentInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                    <td className="align-middle">
                      <Link className="btn btn-orange text-center" to={"/crmMemberAppointmentEdit?id=" + crmMemberAppointmentInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                    </td>
                  </tr>
                )}
                {this.state.crmMemberAppointments.length == 0 ?
                  <tr>
                    <td className="align-middle p-3" colSpan="11">Randevu Bulunamadı !</td>
                  </tr>
                : null}
                </tbody>
                </table>
                {
              this.state.crmMemberAppointments.length !== 0 &&
              <div className="d-flex justify-content-sm-end justify-content-start pl-1 pl-sm-0">
                <ReactPaginate
                previousLabel={"Geri"}
                nextLabel={"İleri"}
                breakLabel={"..."}  
                pageCount={this.state.pageCount}
                marginPagesDisplayed={1}
                onPageChange={page => this.handlePageClick(page)}
                pageRangeDisplayed={10}
                containerClassName={'pagination'}
                activeClassName={'activePage'}
                forcePage={this.state.currentPage - 1}
                />
              </div>
            }
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
            <div className="ibox">
              <div className="ibox-content text-darkblue p-0">
                <h4 className="collapse-title">
                  <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
                </h4>
              </div>
              <div className="collapse" data-parent="#accordion" id="filterCollapse">
                <div className="ibox-content text-darkblue">
                  <strong>Üyelik Tipi</strong>
                  <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterCrmMemberTypeId: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterCrmMemberTypeId}>
                    <option value="">Seçiniz</option>
                    {this.state.crmMemberTypes.map(crmMemberTypeInfo => <option key={crmMemberTypeInfo.id} value={crmMemberTypeInfo.id}>{crmMemberTypeInfo.name}</option>)}
                  </select>
                  <strong>Üye Adı</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.filterFullName} />
                  <strong>Firma Adı</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Firma Adı" type="text" value={this.state.filterCompanyName} />
                  <strong>E-posta</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterEmail: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.filterEmail} />
                  <strong>Telefon</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPhone: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Telefon" type="tel" value={this.state.filterPhone} />
                  <strong>Şehir</strong>
                  <Select
                    className=" form-input mb-2 mt-1 text-dark"
                    onChange={this.selectedCityOnChange}
                    options={selectedCitiesOptions}
                    isClearable={true}
                    defaultValue={selectedCitiesOptions[0]}
                    placeholder="Seçiniz"
                    value={selectedCitiesOptions.find((cityInfo) => cityInfo.value === this.state.filterCityId)}
                    filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  />
                  <strong>İlçe</strong>
                  <Select
                    className=" form-input mb-2 mt-1 text-dark"
                    isDisabled={this.state.towns.length === 0}
                    onChange={this.selectedTownOnChange}
                    options={selectedTownsOptions}
                    isClearable={true}
                    defaultValue={selectedTownsOptions[0]}
                    placeholder="Seçiniz"
                    value={selectedTownsOptions.find((townInfo) => townInfo.value === this.state.filterTownId)}
                    filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  />
                  <strong>Faaliyet Alanı</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyType: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Faliyet Alanı" type="text" value={this.state.filterCompanyType} />
                  <strong>Satış Sorumluları</strong>
                  <Select
                    className=" form-input mb-2 mt-1 text-dark"
                    options={selectedCompanyMarketersOptions}
                    isClearable={true}
                    defaultValue={selectedCompanyMarketersOptions[0]}
                    onChange={this.selectedCompanyMarketerOnChange}
                    value={selectedCompanyMarketersOptions.find((companyMarketerInfo) => companyMarketerInfo.value === this.state.filterMarketerId)}
                    filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                    placeholder="Satış Sorumlusu"
                  />
                  <strong>Öncelik Durumu</strong>
                  <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterPriority: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterPriority}>
                    <option value="">Seçiniz</option>
                    <option value="1">Sıcak</option>
                    <option value="2">Ilık</option>
                    <option value="3">Soğuk</option>
                  </select>
                  <strong>Durum</strong>
                  <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterActive: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                    <option value="">Seçiniz</option>
                    <option value="1">Açık</option>
                    <option value="0">Kapalı</option>
                  </select>
                  <strong>Başlangıç Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterAppointmentStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentStartDate} />
                  <strong>Bitiş Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterAppointmentEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentEndDate} />
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                    <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
            <div className="ibox">
              <div className="ibox-title text-darkblue">
                <h5><i className="fa fa-filter"></i> Filtrele</h5>
              </div>
              <div className="ibox-content text-darkblue">
                <strong>Üyelik Tipi</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterCrmMemberTypeId: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterCrmMemberTypeId}>
                  <option value="">Seçiniz</option>
                  {this.state.crmMemberTypes.map(crmMemberTypeInfo => <option key={crmMemberTypeInfo.id} value={crmMemberTypeInfo.id}>{crmMemberTypeInfo.name}</option>)}
                </select>
                <strong>Üye Adı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.filterFullName} />
                <strong>Firma Adı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Firma Adı" type="text" value={this.state.filterCompanyName} />
                <strong>E-posta</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterEmail: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.filterEmail} />
                <strong>Telefon</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPhone: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Telefon" type="tel" value={this.state.filterPhone} />
                <strong>Şehir</strong>
                <Select
                  className=" form-input mb-2 mt-1 text-dark"
                  onChange={this.selectedCityOnChange}
                  options={selectedCitiesOptions}
                  isClearable={true}
                  defaultValue={selectedCitiesOptions[0]}
                  placeholder="Seçiniz"
                  value={selectedCitiesOptions.find((cityInfo) => cityInfo.value === this.state.filterCityId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                />
                <strong>İlçe</strong>
                <Select
                  className=" form-input mb-2 mt-1 text-dark"
                  isDisabled={this.state.towns.length === 0}
                  onChange={this.selectedTownOnChange}
                  options={selectedTownsOptions}
                  isClearable={true}
                  defaultValue={selectedTownsOptions[0]}
                  placeholder="Seçiniz"
                  value={selectedTownsOptions.find((townInfo) => townInfo.value === this.state.filterTownId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                />
                <strong>Faaliyet Alanı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyType: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Faliyet Alanı" type="text" value={this.state.filterCompanyType} />
                <strong>Satış Sorumluları</strong>
                <Select
                  className=" form-input mb-2 mt-1 text-dark"
                  options={selectedCompanyMarketersOptions}
                  isClearable={true}
                  defaultValue={selectedCompanyMarketersOptions[0]}
                  onChange={this.selectedCompanyMarketerOnChange}
                  value={selectedCompanyMarketersOptions.find((companyMarketerInfo) => companyMarketerInfo.value === this.state.filterMarketerId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  placeholder="Satış Sorumlusu"
                />
                <strong>Öncelik Durumu</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterPriority: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterPriority}>
                  <option value="">Seçiniz</option>
                  <option value="1">Sıcak</option>
                  <option value="2">Ilık</option>
                  <option value="3">Soğuk</option>
                </select>
                <strong>Durum</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterActive: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                  <option value="">Seçiniz</option>
                  <option value="1">Açık</option>
                  <option value="0">Kapalı</option>
                </select>
                <strong>Başlangıç Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterAppointmentStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentStartDate} />
                <strong>Bitiş Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterAppointmentEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAppointmentEndDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function CrmMemberAppointments() {
  return (
    <CrmMemberAppointmentModule navigate={useNavigate()} />
  )
}