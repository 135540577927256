import React from 'react';
import Modal from 'react-modal';
import {Navigate} from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToMysqlDateTime, inputDateTimeToIsoDateTime} from '../../inc/helper';

export default class BannerAddModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,
      
      bannerInfo: {
        bannerLocationId: 0,
        url: '',
        src: '',
        periodStart: null,
        periodEnd: null
      },

      bannerLocations: []
    };

    this.bannerLocationOnChange = this.bannerLocationOnChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.periodStartOnChange = this.periodStartOnChange.bind(this);
    this.periodEndOnChange = this.periodEndOnChange.bind(this);
  }

  componentDidMount() {
    this.getBannerLocations();
    window.scrollTo(0, 0);
  }
  
  getBannerLocations() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'bannerLocations?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, bannerLocations: responseData.bannerLocations});
        break;
        default:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => <Navigate to={'/home'} replace={true}></Navigate>);
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  bannerLocationOnChange(event) {
    this.setState({bannerInfo: {...this.state.bannerInfo, bannerLocationId: parseInt(event.target.value) || 0, bannerLocationName: event.target.options[event.target.selectedIndex].text}});
  }

  periodStartOnChange(event) {
    this.setState({bannerInfo: {...this.state.bannerInfo, periodStart: inputDateTimeToIsoDateTime(event.target.value) || null}});
  } 

  periodEndOnChange(event) {
    this.setState({bannerInfo: {...this.state.bannerInfo, periodEnd: inputDateTimeToIsoDateTime(event.target.value) || null}});
  }

  handleKeyPress(event) {
    if(event.key === 'Enter') {
      this.saveOnClick();
    }
  }

  saveOnClick() {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    
    if (!this.state.bannerInfo.bannerLocationId) {
      infoMessage = 'Reklam alanı adı boş geçilemez !';
    } else if (!this.state.bannerInfo.url) {
      infoMessage = 'Web sitesi boş geçilemez !';
    } else if (!this.state.bannerInfo.src) {
      infoMessage = 'Görsel kaynağı boş geçilemez !';
    } else if (!this.state.bannerInfo.periodStart) {
      infoMessage = 'Başlangıç dönemi tarihi boş geçilemez !';
    } else if (!this.state.bannerInfo.periodEnd) {
      infoMessage = 'Bitiş dönemi tarihi boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      const selectedStartDate = new Date(this.state.bannerInfo.periodStart).getTime();
      const selectedEndDate = new Date(this.state.bannerInfo.periodEnd).getTime();
      const currentDate = new Date().getTime();
  
      if (selectedStartDate < currentDate || selectedEndDate < currentDate) {
        swal({
          dangerMode: true,
          icon: 'warning',
          title: 'Uyarı',
          text: 'Seçilen tarihler mevcut tarihten önce olamaz!',
          buttons: 'Tamam'
        });
      } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify(this.state.bannerInfo)
      };

        fetch(config.restApi + 'banner', requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              this.setState({isCompleted: true, bannerInfo: {bannerLocationId: 0, url: "", src: "", periodStart: null, periodEnd: null}});

              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Reklam başarıyla eklendi.',
                buttons: 'Tamam'
              }).then(() => this.props.onCompleted());
            }
            break;
            default: {
              this.setState({isCompleted: true});

              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    }
  }
 
  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex : 2500
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <strong>Reklam Alanı Adı<strong className="text-danger ml-1">*</strong></strong>
                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.bannerLocationOnChange} onKeyDown={this.handleKeyPress} value={this.state.bannerInfo.bannerLocationId}>
                  <option value="">Seçiniz</option>
                  {this.state.bannerLocations.map(bannerLocationInfo => <option key={bannerLocationInfo.id} value={bannerLocationInfo.id}>{bannerLocationInfo.name}</option>)}
                </select>
                <strong>Web Sitesi<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({bannerInfo: {...this.state.bannerInfo, url: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Web Sitesi" type="text" value={this.state.bannerInfo.url} />
                <strong>Görsel Kaynağı<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({bannerInfo: {...this.state.bannerInfo, src: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Görsel Kaynağı" type="text" value={this.state.bannerInfo.src}/>
                <strong>Başlangıç Dönemi Tarihi<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-control-sm form-input mb-2 mt-1" value={this.state.bannerInfo.periodStart ? isoToMysqlDateTime(this.state.bannerInfo.periodStart) : ''} onChange={this.periodStartOnChange} onKeyDown={this.handleKeyPress} type="datetime-local"  />
                <strong>Bitiş Dönemi Tarihi<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-control-sm form-input mb-2 mt-1" value={this.state.bannerInfo.periodEnd ? isoToMysqlDateTime(this.state.bannerInfo.periodEnd) : ''} onChange={this.periodEndOnChange} onKeyDown={this.handleKeyPress} type="datetime-local" />
              </div>
              <div className="modal-footer"> 
                <button className="btn btn-orange" onClick={() => this.saveOnClick()}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}
