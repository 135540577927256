
import React from 'react';
import Modal from 'react-modal';
import swal from 'sweetalert';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

export default class PhoneTypeAddModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,

      phoneTypeInfo: {
        name: '',
        sort: '0'
      }
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.saveOnClick();
    }
  }
  
  saveOnClick() {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.phoneTypeInfo.name) {
      infoMessage = 'Telefon tipi boş geçilemez !';
    } else if (!this.state.phoneTypeInfo.sort) {
      infoMessage = 'Sıralama boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({name: this.state.phoneTypeInfo.name, sort: parseInt(this.state.phoneTypeInfo.sort)})
      };

      fetch(config.restApi + 'phoneType', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isCompleted: true, phoneTypeInfo: {name: '', sort: '0'}});

            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Telefon tipi başarıyla eklendi.',
              buttons: 'Tamam'
            }).then(() => this.props.onCompleted());
          }
          break;
          default: {
            this.setState({isCompleted: true});

            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex : 2500
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <strong>Telefon Tipi<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({phoneTypeInfo: {...this.state.phoneTypeInfo, name: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Telefon Tipi" type="text" value={this.state.phoneTypeInfo.name}/>
                <strong>Sıralama<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" max="999" min="0" onChange={event => this.setState({phoneTypeInfo: {...this.state.phoneTypeInfo, sort: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Sıralama" type="number" value={this.state.phoneTypeInfo.sort}/>
              </div>
              <div className="modal-footer">
                <button className="btn btn-orange" onClick={() => this.saveOnClick()}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}