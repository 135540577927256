import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';
import GoogleMapReact from 'google-map-react';

import AddressModal from '../../component/AddressModal';
import PhoneModal from '../../component/PhoneModal';
import CancelTransactionModal from '../../component/member/payment/CancelTransactionModal';

import Images from '../../utilies/Images';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDate, isoToTime, isoToDateTime, priceFormat} from '../../inc/helper';
import InvoiceModal from '../../component/InvoiceModal';
import StatusModal from '../../component/member/StatusModal';

const Marker = () => (<i className="fa fa-map-marker fa-5x text-danger ml-n3 mt-n5"></i>);

class MemberEditModule extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddressModal: false,
      isOpenInvoiceModal: false,
      isOpenPhoneModal: false,
      isOpenCancelTransactionModal: false,
      isOpenMemberTransactionStatus: false,

      active: 1,
      member: null,
      memberHistoryComment: '',
      memberTransactionId: 0,
      memberId: 0,
      
      memberAddresses: [],
      memberHistories: [],
      memberInvoices: [],
      memberProducts: [],
      memberTransactions: [],
      otherPortals: [],
      url: '',
      products: [],
      paymentTypes: [],
      storeExist: false,
      createStore: false,
      action: '',
      cancelledDescription: '',
      quantity: 1,

     storeInfo: {
        storename: '',
        name: '',
        commercialName: '',
        description: '',
        aboutUs: '',
        email: '',
        phoneNumber: '',
        url: '',
        countryId: 1,
        cityId: 0,
        townId: 0,
        districtId: 0,
        quarterId: 0,
        address: '',
        lat: 39.886559,
        lon: 32.855350,
        zoom: 15,
        invoiceType: '',
        invoiceFullname: '',
        invoiceIdentity: '',
        invoiceCompanyName: '',
        invoiceTaxNumber: '',
        invoiceTaxOffice: '',
        invoiceCountryId: 1,
        invoiceCityId: 0,
        invoiceAddress: '',
      },

      countries: [],
      cities: [],
      towns: [],
      regions: [],
      quarters: [],

      insuranceMemberInfo: {},
      koalayAccountNotFound: false,
      koalayAccesDenied: false,

      editAddress: null,
      editPhone: null,

      selectedTabIndex: parseInt(this.props.searchParams.get('tab')) || 0
    };
  }

  componentDidMount() {
    this.getMember();
    this.getMemberHistories();
    this.getMemberProducts();
    this.getMemberTransactions();
    this.getPaymentTypes();
    this.getKoalayInfo()
    window.scrollTo(0, 0);
  }

  componentWillMount() {
  }
  
  componentDidUpdate(prevProps, prevState) {
    let newTabIndex = parseInt(this.props.searchParams.get('tab')) || 0;
    if (prevState.selectedTabIndex != newTabIndex) {
      this.setState({selectedTabIndex: newTabIndex});
    }
  }

  getPaymentTypes() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'paymentTypes', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, paymentTypes: responseData.paymentTypes});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handleKeyPressFeatherFlag = (event) => {
    if(event.key === 'Enter') {
      this.getFeatherFlag();
    }
  }

  getMember() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 5;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/members'));
        }else{
          this.setState({isLoading: false}, () => this.getLocation());
        }
      }
    }

    fetch(config.restApi + 'member/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({member: responseData.member, memberId: responseData.member.id});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/members'))
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'otherPortals?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({otherPortals: responseData.otherPortals});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + `products/tr?limit=${40}&filterActive=1`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({products: responseData.products});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'memberInvoices/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({memberInvoices: responseData.memberInvoices});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'store/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const storeInfo = responseData.storeInfo;
          this.setState({storeInfo: {...this.state.storeInfo, 
            storename: storeInfo.storename, 
            name: storeInfo.name, 
            commercialName: storeInfo.commercialName, 
            description: storeInfo.description,
            aboutUs: storeInfo.aboutUs,
            phoneNumber: storeInfo.phoneNumber,
            url: storeInfo.url,
            countryId: storeInfo.countryId,
            cityId: storeInfo.cityId,
            townId: storeInfo.townId, 
            districtId: storeInfo.districtId, 
            quarterId: storeInfo.quarterId,
            email: storeInfo.email, 
            address: storeInfo.address, 
            lat: storeInfo.lat, 
            lon: storeInfo.lon, 
            invoiceType: storeInfo.invoiceType, 
            invoiceFullname: storeInfo.invoiceFullname, 
            invoiceIdentity: storeInfo.invoiceIdentity, 
            invoiceCompanyName: storeInfo.invoiceCompanyName, 
            invoiceTaxNumber: storeInfo.invoiceTaxNumber, 
            invoiceTaxOffice: storeInfo.invoiceTaxOffice,
            invoiceCountryId: storeInfo.invoiceCountryId, 
            invoiceCityId: storeInfo.invoiceCityId ,
            invoiceAddress: storeInfo.invoiceAddress
          }, storeExist: true, createStore: false});
         
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        case 404: {
          this.setState({storeExist: false, createStore: true})
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getMemberForCrmMember() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 5;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/members'));
        }else{
          this.setState({isLoading: false}, () => this.getLocation());
        }
      }
    }

    fetch(config.restApi + 'member/tr/' + this.state.memberId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({member: responseData.member, memberId: responseData.member.id});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/members'))
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'otherPortals?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({otherPortals: responseData.otherPortals});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + `products/tr?limit=${40}&filterActive=1`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({products: responseData.products});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'memberInvoices/tr/' + this.state.memberId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({memberInvoices: responseData.memberInvoices});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'store/tr/' + this.state.memberId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const storeInfo = responseData.storeInfo;
          this.setState({storeInfo: {...this.state.storeInfo, 
            storename: storeInfo.storename, 
            name: storeInfo.name, 
            commercialName: storeInfo.commercialName, 
            description: storeInfo.description,
            aboutUs: storeInfo.aboutUs,
            phoneNumber: storeInfo.phoneNumber,
            url: storeInfo.url,
            countryId: storeInfo.countryId,
            cityId: storeInfo.cityId,
            townId: storeInfo.townId, 
            districtId: storeInfo.districtId, 
            quarterId: storeInfo.quarterId,
            email: storeInfo.email, 
            address: storeInfo.address, 
            lat: storeInfo.lat, 
            lon: storeInfo.lon, 
            invoiceType: storeInfo.invoiceType, 
            invoiceFullname: storeInfo.invoiceFullname, 
            invoiceIdentity: storeInfo.invoiceIdentity, 
            invoiceCompanyName: storeInfo.invoiceCompanyName, 
            invoiceTaxNumber: storeInfo.invoiceTaxNumber, 
            invoiceTaxOffice: storeInfo.invoiceTaxOffice,
            invoiceCountryId: storeInfo.invoiceCountryId, 
            invoiceCityId: storeInfo.invoiceCityId ,
            invoiceAddress: storeInfo.invoiceAddress
          }, storeExist: true, createStore: false});
         
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        case 404: {
          this.setState({storeExist: false, createStore: true})
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  saveNewStoreOnClick = () => {
    let sessionHash = getCookie("sessionHash");

    let infoMessage = "";

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: "info",
        title: "Eksik Bilgi",
        text: infoMessage,
        buttons: "Tamam",
      }).then(() => this.getStore());
    } else {
      this.setState({ isLoading: true });

      const requestOptions = {
        method: "POST",
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify(this.state.storeInfo),
      };

      fetch(config.restApi + 'store/tr/' + this.props.searchParams.get('id'), requestOptions)
        .then((response) => response.json())
        .then(
          (responseData) => {
            switch (responseData.status) {
              case 200:{
                  this.setState({isLoading: false});

                  swal({
                    dangerMode: true,
                    icon: 'success',
                    title: 'Tamamlandı',
                    text: 'Mağaza başarıyla eklendi.',
                    buttons: 'Tamam'
                  }).then(() => this.setState({createStore: false}))
                }
                break;
              default:{
                  this.setState({isLoading: false});

                  swal({
                    dangerMode: true,
                    icon: "warning",
                    title: "Hay Aksi",
                    text: responseData.message,
                    buttons: "Tamam",
                  });
                }
                break;
            }
          }, () => swal({dangerMode: true, icon: "error", title: "Bağlantı Hatası", text: "Lütfen internet bağlantınızı kontrol ediniz.", buttons: "Tamam"})
        );
    }
  };

  loginMemberAccount = () => {
    let sessionHash = getCookie("sessionHash");

    this.setState({ isLoading: true });

    const requestOptions = {
      method: "POST",
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'}
    };

    fetch(config.restApi + 'memberLogin/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then((response) => response.json())
    .then(
      (responseData) => {
        switch (responseData.status) {
          case 200:{
            let url = `https://uye.${config.host}/directLogin?sessionHash=` + responseData.sessionHash + '&ref=banaozel'
            window.open(url, '_blank');
            this.getMember();
          }
            break;
        default:{
            this.setState({isLoading: false});

            swal({
              dangerMode: true,
              icon: "warning",
              title: "Hay Aksi",
              text: responseData.message,
              buttons: "Tamam",
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: "error", title: "Bağlantı Hatası", text: "Lütfen internet bağlantınızı kontrol ediniz.", buttons: "Tamam"})
    );
  };

  handleSaveNewStoreButtonOpen = () => {
    this.setState({storeExist: true})
  }


  getLocation() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 1;

    if (this.state.storeInfo.countryId) remaining++;
    if (this.state.storeInfo.cityId) remaining++;
    if (this.state.storeInfo.townId) remaining++;

    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'countries/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({countries: responseData.countries});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    if (this.state.storeInfo.countryId) {
      fetch(config.restApi + 'cities/tr/' + this.state.storeInfo.countryId + '?filterActive=1', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({cities: responseData.cities});
          }
          break;
          default: {
            errorMessages.push(responseData.message);
          }
          break;
        }
        complete();
      }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
    }

    if (this.state.storeInfo.cityId) {
      fetch(config.restApi + 'towns/tr/' + this.state.storeInfo.cityId + '?filterActive=1', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({towns: responseData.towns});
          }
          break;
          default: {
            errorMessages.push(responseData.message);
          }
          break;
        }
        complete();
      }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
    }

    if (this.state.storeInfo.townId) {
      fetch(config.restApi + 'regions/tr/' + this.state.storeInfo.townId + '?filterActive=1', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({regions: responseData.regions, quarters: responseData.regions.filter(districtInfo => districtInfo.id === this.state.storeInfo.districtId)[0].quarters});
          }
          break;
          default: {
            errorMessages.push(responseData.message);
          }
          break;
        }
        complete();
      }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
    }
  }

  countryOnChange = (event) => {
    let countryId = event.target.value;
    const selectedCountry = this.state.countries.find((country) => country.id === parseInt(countryId));
    if (countryId) {
      this.getCities(countryId);
      this.setState({storeInfo: {...this.state.storeInfo, countryId: parseInt(countryId), lat: selectedCountry.lat, lon: selectedCountry.lon, zoom: selectedCountry.zoom, cityId: '', townId: '', districtId: '', quarterId: ''}, cities: [], towns: [], regions: [], quarters: []})
    } else {
      this.setState({storeInfo: {...this.storeInfo.storeInfo, countryId: '', cityId: '',townId: '', districtId: '', quarterId: ''}, cities: [], towns: [], regions: [], quarters: []})
    }
  }

  cityOnChange = (event) => {
    let cityId = event.target.value;
    const selectedCity = this.state.cities.find((city) => city.id === parseInt(cityId));
    if (cityId) {
      this.getTowns(cityId);
      this.setState({storeInfo: {...this.state.storeInfo, cityId: parseInt(cityId), lat: selectedCity.lat, lon: selectedCity.lon, zoom: selectedCity.zoom, townId: '', districtId: '', quarterId: ''}, towns: [], regions: [], quarters: []})
    } else {
      this.setState({storeInfo: {...this.storeInfo.storeInfo, cityId: '', townId: '', districtId: '', quarterId: ''}, towns: [], regions: [], quarters: []})
    }
  }

  townOnChange = (event) => {
    let townId = event.target.value;
    const selectedTown = this.state.towns.find((town) => town.id === parseInt(townId));
    if (townId) {
      this.getRegions(townId);
      this.setState({storeInfo: {...this.state.storeInfo, townId: parseInt(townId), lat: selectedTown.lat !== 0 ? selectedTown.lat : this.state.storeInfo.cityId.lat, lon: selectedTown.lon !== 0 ? selectedTown.lon : this.state.storeInfo.cityId.lon, zoom: selectedTown.zoom, districtId: '', quarterId: ''}, regions: [], quarters: []})
    } else {
      this.setState({storeInfo: {...this.state.storeInfo, townId: '', districtId: '', quarterId: ''}, regions: [], quarters: []})
    }
  }

  districtOnChange = (event) => {
    let districtId = event.target.value;
    const selectedDistrict = this.state.regions.find((region) => region.id === parseInt(districtId));
    if (districtId) {
      this.setState({storeInfo: {...this.state.storeInfo, districtId: parseInt(districtId), lat: selectedDistrict.lat !== 0 ? selectedDistrict.lat : this.state.storeInfo.townId.lat, lon: selectedDistrict.lon !== 0 ? selectedDistrict.lon : this.state.storeInfo.townId.lon, zoom: selectedDistrict.zoom, quarterId: ''}, quarters: this.state.regions.filter(districtInfo => districtInfo.id === parseInt(districtId))[0].quarters})
    } else {
      this.setState({storeInfo: {...this.state.storeInfo, districtId: '', quarterId: ''}, quarters: []})
    }
  }

  quarterOnChange = (event) => {
    let quarterId = event.target.value;
    const selectedQuarter = this.state.quarters.find((quarter) => quarter.id === parseInt(quarterId));
    const selectedTown = this.state.towns.find((town) => town.id === parseInt(this.state.storeInfo.townId));
    const selectedCity = this.state.cities.find((city) => city.id === parseInt(this.state.storeInfo.cityId));
    if (quarterId) {
      this.setState({storeInfo: {...this.state.storeInfo, quarterId: parseInt(quarterId), lat: selectedQuarter.lat || selectedTown.lat || selectedCity.lat, lon: selectedQuarter.lon || selectedTown.lon || selectedCity.lon, zoom: selectedQuarter.zoom}})
    } else {
      this.setState({storeInfo: {...this.state.storeInfo, quarterId: ''}})
    }
  }

  invoiceCountryOnChange = (event) => {
    let countryId = event.target.value;
    if (countryId) {
      this.getCities(countryId);
      this.setState({storeInfo: {...this.state.storeInfo, invoiceCountryId: parseInt(countryId), invoiceCityId: ''}, cities: []})
    } else {
      this.setState({storeInfo: {...this.storeInfo.storeInfo, invoiceCountryId: '', invoiceCityId: ''}, cities: []})
    }
  }

  invoiceCityOnChange = (event) => {
    let cityId = event.target.value;
    if (cityId) {
      this.setState({storeInfo: {...this.state.storeInfo, invoiceCityId: parseInt(cityId)}})
    } else {
      this.setState({storeInfo: {...this.storeInfo.storeInfo, invoiceCityId: ''}})
    }
  }

  getCities(countryId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'cities/tr/' + countryId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const sortedCities = responseData.cities.sort((first, last) => first.name.localeCompare(last.name));
          this.setState({cities: sortedCities, towns: [], regions: []});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }
  
  getTowns(cityId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'towns/tr/' + cityId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({towns: responseData.towns, regions: []});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getRegions(townId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'regions/tr/' + townId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({regions: responseData.regions});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }
  
  getMemberHistories() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'memberHistories/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({memberHistories: responseData.memberHistories});
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/members'))
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getMemberTransactions() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'memberTransactions/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({memberTransactions: responseData.memberTransactions});
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/members'))
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getMemberAddresses() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'memberAddresses/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({member: {...this.state.member, memberAddresses: responseData.memberAddresses}});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getMemberPhones() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'memberPhones/tr/' + this.state.memberId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({member: {...this.state.member, memberPhones: responseData.memberPhones}});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getStore() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'store/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({storeInfo: responseData.storeInfo});
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/members'))
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getPaymentStatus(memberTransactionInfo) {
    if (memberTransactionInfo.cancelledDate) {
      return '-';
    } else if (memberTransactionInfo.completed === 0 && (memberTransactionInfo.paymentStatus === 2 || memberTransactionInfo.paymentStatus === 4)) {
      return '-';
    } else if (memberTransactionInfo.completed === 1) {
      return '-';
    } else if (memberTransactionInfo.completed === 2) {
      return <button className="btn btn-orange2 btn-xs" onClick={() => this.setState({isOpenCancelTransactionModal: true, memberTransactionId: memberTransactionInfo.id})}>İptal Et</button>
    } else if (memberTransactionInfo.completed === 3) {
      return '-';
    } else if (memberTransactionInfo.transactionItems.length === 0) {
      return '-';
    } else if (memberTransactionInfo.paymentStatus === 1) {
      return <div className="d-flex flex-column">
        <Link className="btn btn-orange2 btn-xs mb-1" to={"/paymentTransactionByCreditCard?id=" + memberTransactionInfo.id}>Kredi Kartı</Link>
        <Link className="btn btn-orange2 btn-xs mb-1" to={"/paymentTransactionByBankTransfer?id=" + memberTransactionInfo.id}>Havale</Link>
        <Link className="btn btn-orange2 btn-xs mb-1" to={"/paymentTransactionByFree?id=" + memberTransactionInfo.id}>Ücretsiz</Link>
      </div>;
    } else if (memberTransactionInfo.paymentStatus === 2) {
      return '-';
    } else if (memberTransactionInfo.paymentStatus === 3) {
      return <div className="d-flex flex-column">
      <Link className="btn btn-orange2 btn-xs mb-1" to={"/paymentTransactionByCreditCard?id=" + memberTransactionInfo.id}>Kredi Kartı</Link>
      <Link className="btn btn-orange2 btn-xs mb-1" to={"/paymentTransactionByBankTransfer?id=" + memberTransactionInfo.id}>Havale</Link>
      <Link className="btn btn-orange2 btn-xs mb-1" to={"/paymentTransactionByFree?id=" + memberTransactionInfo.id}>Ücretsiz</Link>
    </div>;
    } else if (memberTransactionInfo.paymentStatus === 4) {
      return 'Bedelsiz';
    } else {
      return '-';
    }
  }

  getTransactionStatus(memberTransactionInfo) {
    if (memberTransactionInfo.cancelledDate) {
      return 'İşlem iptal edildi.';
    } else if (memberTransactionInfo.completed === 0 && (memberTransactionInfo.paymentStatus === 2 || memberTransactionInfo.paymentStatus === 4)) {
      return 'Paket tanımlaması sıraya alındı.';
    } else if (memberTransactionInfo.completed === 1) {
      return 'Ürünler tanımlanıyor.';
    } else if (memberTransactionInfo.completed === 2) {
      return 'Tamamlandı.';
    } else if (memberTransactionInfo.completed === 3) {
      return 'Ürünler tanımlanamadı.';
    } else if (memberTransactionInfo.transactionItems.length === 0) {
      return 'Sepetiniz boş.';
    } else if (memberTransactionInfo.paymentStatus === 1) {
      return 'Ödeme bekleniyor.';
    } else if (memberTransactionInfo.paymentStatus === 2) {
      return 'Ödeme tamamlandı.';
    } else if (memberTransactionInfo.paymentStatus === 3) {
      return 'Ödeme reddedildi.';
    } else if (memberTransactionInfo.paymentStatus === 4) {
      return 'Ücretsiz.';
    } else {
      return 'Bilinmeyen durum.';
    }
  }

  getInvoiceStatus(memberInvoiceInfo) {
    if (!memberInvoiceInfo.billingPeriod) {
      return <strong className="text-orange">Fatura Bekleniyor <i className="fa fa-clock-o"></i></strong>
    } else if (memberInvoiceInfo.locked === 0) {
      return <strong className="text-warning">Fatura Oluşturuldu. Onay Bekleniyor <i className="fa fa-clock-o"></i></strong>
    } else if (memberInvoiceInfo.locked === 1) {
      return <strong className="text-green">Fatura Onaylandı <i className="fa fa-check"></i></strong>
    }
  }

  getMemberProducts() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'memberProducts/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({memberProducts: responseData.memberProducts});
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/members'))
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }
  
  getProductPeriod(productPeriod){
    if (productPeriod === 'INTERVAL 1 DAY') {
      return '1 Günlük'
    } else if (productPeriod === 'INTERVAL 1 WEEK') {
      return '1 Haftalık'
    } else if (productPeriod === 'INTERVAL 1 MONTH') {
      return '1 Aylık'
    } else if (productPeriod === 'INTERVAL 2 MONTH') {
      return '2 Aylık'
    } else if (productPeriod === 'INTERVAL 3 MONTH') {
      return '3 Aylık'
    } else if (productPeriod === 'INTERVAL 6 MONTH') {
      return '6 Aylık'
    } else if (productPeriod === 'INTERVAL 1 YEAR') {
      return '1 Yıllık'
    }
    return 'Süresiz'
  }

  activeOnChange = (event) => {

    let active = event.target.value === '' ? '' : parseInt(event.target.value);

    this.setState({member: {...this.state.member, active: active}});

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'member/tr/active/' + active + '?id=' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {}
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  activeStoreOnChange = (event) => {

    let active = event.target.value === '' ? '' : parseInt(event.target.value);

    this.setState({member: {...this.state.member, active: active}});

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'store/tr/active/' + active + '?id=' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {}
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  contactSelectedOnChange(checkboxStatus, index, memberPhoneId) {
    let memberPhones = this.state.member.memberPhones;
    memberPhones[index].contactSelected = checkboxStatus;

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({contactSelected: checkboxStatus})
    };

    fetch(config.restApi + 'memberPhoneContactSelected/tr/' + memberPhoneId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          this.setState({member: {...this.state.member, memberPhones: memberPhones}});
        }
        break;
        default: {
          this.setState({isCompleted: true});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  defaultSelectedOnChange(defaultSelectedStatus, index, memberPhoneId) {
    let memberPhones = this.state.member.memberPhones;
    memberPhones[index].defaultSelected = defaultSelectedStatus;

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'memberPhoneDefaultSelected/tr/' + memberPhoneId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          this.setState({member: {...this.state.member, memberPhones: memberPhones}});
          this.getMemberPhones();
        }
        break;
        default: {
          this.setState({isCompleted: true});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  toggleCheckbox = (otherPortalId, url) => {
    this.setState((prevState) => {
      const isChecked = this.state.member.memberOtherPortals.some(memberOtherPortalInfo => memberOtherPortalInfo.otherPortalId === otherPortalId);
  
      if (isChecked) {
        return {
          member: {...prevState.member,memberOtherPortals: prevState.member.memberOtherPortals.filter(memberOtherPortalInfo => memberOtherPortalInfo.otherPortalId !== otherPortalId)},
        };
      } else {
        return { member: {...prevState.member, memberOtherPortals: [...prevState.member.memberOtherPortals, { otherPortalId: otherPortalId, url: url}]}};
      }
    });
  };

  handleTextareaChange = (otherPortalId, url) => {
    this.setState((prevState) => ({
      member: {
        ...prevState.member,
        memberOtherPortals: prevState.member.memberOtherPortals.map(memberOtherPortalInfo => {
          if (memberOtherPortalInfo.otherPortalId === otherPortalId) {
            return {
              ...memberOtherPortalInfo,
              url: url,
            };
          }
          return memberOtherPortalInfo;
        }),
      },
    }));
  };
  


  memberOtherPortalOnChange = (event) => {
    let memberOtherPortals = this.state.member.memberOtherPortals;
    let value = parseInt(event.target.id);

    if (event.target.checked) {
      memberOtherPortals.push(value);
    } else {
      memberOtherPortals.splice(memberOtherPortals.indexOf(value), 1);
    }

    this.setState({member: {...this.state.member, memberOtherPortals: memberOtherPortals}});
  }

  addToBasketOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Sepete Ekle',
      text: 'Ürünü sepete eklemek istediğinizden emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'POST',
          headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
          body: JSON.stringify({productId: id})
        };

        fetch(config.restApi + 'memberTransaction/tr/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Ürün sepete eklendi.',
                buttons: 'Tamam'
              }).then(() => this.getMemberTransactions());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  deleteToBasketOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Ürünü sepetten çıkartmak istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'memberTransactionItem/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Ürün sepetten başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getMemberTransactions());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });

            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }
  
  handleKeyPressMemberHistory = (event) => {
    if(event.key === 'Enter') {
      this.saveMemberHistoryOnClick();
    }
  }

  saveMemberHistoryOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.memberHistoryComment) {
      infoMessage = 'Yorum boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({comment: this.state.memberHistoryComment})
      };

      fetch(config.restApi + 'memberHistory/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isCompleted: true, memberHistoryComment: ''});

            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Kullanıcı hakkındaki yorum başarı ile eklendi.',
              buttons: 'Tamam'
            }).then(() => this.getMemberHistories());
          }
          break;
          default: {
            this.setState({isCompleted: true});

            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  deleteMemberHistoryCommentOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Yorumu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'memberHistory/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Yorum başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getMemberHistories());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });

            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }
  
  saveMemberAddressOnClick(addressInfo, memberId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({memberAddresses: [addressInfo]})
    };

    fetch(config.restApi + 'memberAddress/tr/' + memberId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Adres başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getMemberAddresses(), this.setState({isOpenAddressModal: false}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  updateMemberAddressOnClick(addressInfo, memberId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({memberAddresses: [addressInfo]})
    };

    fetch(config.restApi + 'memberAddress/tr/' + memberId , requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Adres başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.setState({isLoading: true}, () => {this.getMember();this.setState({isLoading: false});}), this.setState({isOpenAddressModal: false}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteMemberAddressOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Adresi silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'memberAddress/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Adres başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getMemberAddresses());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  saveMemberPhoneOnClick(phoneInfo, memberId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({memberPhones: [phoneInfo]})
    };

    fetch(config.restApi + 'memberPhone/tr/' + memberId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Telefon numarası başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getMemberPhones(), this.setState({isOpenPhoneModal: false}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  updateMemberPhoneOnClick(phoneInfo, memberId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({memberPhones: [phoneInfo]})
    };

    fetch(config.restApi + 'memberPhone/tr/' + memberId , requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Telefon numarası başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.setState({isLoading: true}, () => {this.getMember();this.setState({isLoading: false});}), this.setState({isOpenPhoneModal: false}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.setState({isLoading: true}, () => {this.getMember();this.setState({isLoading: false});}));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteMemberPhoneOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Telefonu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'memberPhone/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Telefon başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getMemberPhones());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.saveMemberOnClick();
    }
  }

  saveMemberOnClick = (event) => {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        if (errorMessages.length === 0) {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Üye bilgileri başarıyla kaydedildi.',
            buttons: 'Tamam'
          }).then(() => this.setState({isLoading: false}));
        } else {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: errorMessages[0],
            buttons: 'Tamam'
          }).then(() => this.setState({isLoading: false}));
        }
      }
    }

    const requestOptionPUT = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(this.state.member)
    };

    fetch(config.restApi + 'member/tr/' + this.props.searchParams.get('id'), requestOptionPUT)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    const requestOptionPOST = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({memberOtherPortals: this.state.member.memberOtherPortals})
    };

    fetch(config.restApi + 'memberOtherPortal/tr/' + this.props.searchParams.get('id'), requestOptionPOST)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {

        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  deleteMemberOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Üyeyi silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'member/tr/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Üye başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.props.navigate('/members'));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  memberToCrmOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Potansiyel üyeye aktarmak istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'POST',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'memberToCrm/' + this.state.memberId, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Potansiyel üyeye başarıyla aktarıldı.',
                buttons: 'Tamam'
              }).then(() => this.getMemberForCrmMember());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }
  
  cancelMemberProductOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Ürünü iptal etmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'PUT',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'cancelMemberProduct/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Ürün başarıyla iptal edildi.',
                buttons: 'Tamam'
              }).then(() => this.getMemberProducts());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  packageProductTimeOnClick(id, action) {
    let sessionHash = getCookie('sessionHash');

    this.setState({action: action, isLoading: true});

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({action: action})
    };

    fetch(config.restApi + 'memberProductExpirationDate/tr/' + id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          this.getMemberProducts();
          this.setState({isLoading: false});
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => {this.props.navigate('/home'); this.setState({isLoading: false});});
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => {this.props.navigate('/home'); this.setState({isLoading: false});});
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  onCompleted = () => {
    this.getMemberTransactions();
    this.setState({isOpenCancelTransactionModal: false});
  }

  invoiceTypeOnChange = (event) => {
    this.setState({storeInfo: {...this.state.storeInfo, invoiceType: parseInt(event.target.value) || 0}})
  }

  saveStoreOnClick = () => {
    let sessionHash = getCookie('sessionHash');

     const requestOptions = {
       method: 'PUT',
       headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
       body: JSON.stringify(this.state.storeInfo)
     };

     fetch(config.restApi + 'store/tr/' + this.props.searchParams.get('id'), requestOptions)
     .then(response => response.json())
     .then(responseData => {
       this.setState({isLoading: false});
       switch (responseData.status) {
         case 200: {
           swal({
             dangerMode: true,
             icon: 'success',
             title: 'Tamamlandı',
             text: 'Mağaza bilgisi başarıyla güncellendi.',
             buttons: 'Tamam'
           }).then(() => this.getStore());
         }
         break;
         default: {
           swal({
             dangerMode: true,
             icon: 'warning',
             title: 'Hay Aksi',
             text: responseData.message,
             buttons: 'Tamam'
           });
         }
         break;
       }
     }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getKoalayInfo = () => {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + `insuranceMemberInfo/tr/` + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, insuranceMemberInfo: responseData.insuranceMemberInfo});
        }
        break;
        case 401: {
          this.setState({isLoading: false, koalayAccesDenied: true});
        }
        break;
        case 404: {
          this.setState({isLoading: false, koalayAccountNotFound: true});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  render() {
    let sessionHash = getCookie("sessionHash");

    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>  
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      : this.state.member == null ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>  
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-content">
                <div className="d-flex justify-content-between">
                  <h4 className="text-darkblue">Üye Düzenle</h4>
                  <div className="d-flex justify-content-end mb-1">
                    <div className="btn-open justify-content-center">
                      <div className="btn border border-darkblue text-center mr-2">
                        <span className="btn-open dropdown-toggle font-bold text-darkblue" data-toggle="dropdown"><i className="fa fa-ellipsis-v text-darkblue"></i></span>
                        <ul className="dropdown-menu overflow-auto mr-3 mt-2">
                          {this.state.member.crmMemberId !== null ?
                            <li><Link className="btn btn-height dropdown-item font-bold" to={'/crmMemberEdit?id=' + this.state.member.crmMemberId} title="Potansiyel Üyeye Aktar"><i className="fa fa-angle-double-right m-r-xs"></i> Potansiyel Üye Profiline Git</Link></li>
                          :
                          <li><Link className="btn btn-height dropdown-item font-bold" onClick={this.memberToCrmOnClick} title="Potansiyel Üyeye Aktar"><i className="fa fa-angle-double-right m-r-xs"></i> Potansiyel Üyeye Aktar</Link></li>
                          }
                          <li><button className="btn btn-height dropdown-item font-bold" onClick={this.loginMemberAccount} title="Hesaba Giriş Yap"><i className="fa fa-sign-in text-darkblue m-r-xs"></i> Hesaba Giriş Yap</button></li>
                          <li><Link className="btn btn-height dropdown-item font-bold" onClick={this.deleteMemberOnClick} title="Üye Sil"><i className="fa fa-trash text-darkblue m-r-xs"></i> Üyeyi Sil</Link></li>
                        </ul>
                      </div>
                    </div>
                    <input className="col-md-1 d-md-flex d-none form-control form-input" onChange={event => this.setState({quantity: event.target.value})} onKeyDown={this.handleKeyPressFeatherFlag} placeholder="Miktar" type="number" value={this.state.quantity} />
                    <a className="d-md-flex d-none align-items-center btn btn-height btn-darkblue text-white mx-1" href={config.restApi + 'report/featherFlag/tr/' + this.props.searchParams.get('id') + `?sessionHash=${sessionHash}&quantity=${this.state.quantity}`} target="_blank" title="Olta Bayrak Excel"><i className="fa fa-file-excel-o"></i><span className="d-none d-sm-inline"></span></a>
                    {this.state.member.crmMemberId !== null ?
                      <Link className="btn btn-height bg-green btn-none text-white mr-2" to={'/crmMemberEdit?id=' + this.state.member.crmMemberId} title="Potansiyel Üye Profiline Git"><i className="fa fa-user-o"></i><span className="d-none d-sm-inline"> Potansiyel Üye Profiline Git</span></Link>
                    :
                    <button className="btn btn-height btn-none bg-orange text-white mr-2" onClick={this.memberToCrmOnClick} title="Potansiyel Üyeye Aktar"><i className="fa fa-angle-double-right"></i><span className="d-none d-sm-inline"> Potansiyel Üyeye Aktar</span></button>
                    }
                    <button className="btn btn-height btn-none bg-blue-2 text-white mr-2" onClick={this.loginMemberAccount} title="Hesaba Giriş Yap"><i className="fa fa-sign-in mr-1"></i><span className="d-none d-sm-inline">Hesaba Giriş Yap</span></button>
                    <button className="btn btn-height btn-none bg-orange text-white mr-2" onClick={this.deleteMemberOnClick} title="Üye Sil"><i className="fa fa-trash"></i><span className="d-none d-sm-inline"></span></button>
                    {this.state.selectedTabIndex !== 1 && this.state.selectedTabIndex !== 2 && this.state.selectedTabIndex !== 3 && this.state.selectedTabIndex !== 4 ?<button className="btn btn-height bg-orange text-white mr-2" onClick={this.saveMemberOnClick} title="Üye Kaydet"><i className="fa fa-save"></i><span className="d-none d-sm-inline"></span></button> : ''}
                    <button className="btn btn-height btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i><span className="d-none d-sm-inline"></span></button>
                  </div>
                </div>
                <div className="tabs-container">
                  <ul className="nav nav-tabs">
                    <li><Link className={this.state.selectedTabIndex === 0 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Üye Detayı" to={'/memberEdit?id=' + this.props.searchParams.get('id') + '&tab=0'}>Üye Detayı</Link></li>
                    <li><Link className={this.state.selectedTabIndex === 1 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Üye İşlemleri" to={'/memberEdit?id=' + this.props.searchParams.get('id') + '&tab=1'}>Üye İşlemleri</Link></li>
                    <li><Link className={this.state.selectedTabIndex === 2 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Üye Ürünleri" to={'/memberEdit?id=' + this.props.searchParams.get('id') + '&tab=2'}>Üye Ürünleri</Link></li>
                    <li><Link className={this.state.selectedTabIndex === 3 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Fatura İşlemleri" to={'/memberEdit?id=' + this.props.searchParams.get('id') + '&tab=3'}>Fatura İşlemleri</Link></li>
                    <li><Link className={this.state.selectedTabIndex === 4 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Üye Geçmişi" to={'/memberEdit?id=' + this.props.searchParams.get('id') + '&tab=4'}>Üye Geçmişi</Link></li>
                    <li><Link className={this.state.selectedTabIndex === 5 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Mağaza Bilgisi Güncelle" to={'/memberEdit?id=' + this.props.searchParams.get('id') + '&tab=5'}>Mağaza Bilgisi Güncelle</Link></li>
                    <li><Link className={this.state.selectedTabIndex === 6 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Sigorta İşlemleri" to={'/memberEdit?id=' + this.props.searchParams.get('id') + '&tab=6'}>Sigorta İşlemleri</Link></li>
                  </ul>
                  <div className="tab-content">
                    <div className={this.state.selectedTabIndex === 0 ? 'tab-pane active' : 'tab-pane'}>
                      <div className="panel-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="text-darkblue">Üye Bilgileri</h4>
                            <div className="hr-line-dashed"></div>
                            <div className="row">
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Üye Adı<strong className="text-danger ml-1">* {this.state.member.identityVerified === 1 ? <strong className="text-green">(Doğrulanmış)</strong> : <strong className="text-orange">(Doğrulanmamış)</strong> }</strong></strong></h5>
                                <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({member: {...this.state.member, firstname: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')}})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.member.firstname} />
                              </div>
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Üye Soyadı<strong className="text-danger ml-1">* {this.state.member.identityVerified === 1 ? <strong className="text-green">(Doğrulanmış)</strong> : <strong className="text-orange">(Doğrulanmamış)</strong> }</strong></strong></h5>
                                <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({member: {...this.state.member, lastname: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')}})} onKeyDown={this.handleKeyPress} placeholder="Üye Soyadı" type="text" value={this.state.member.lastname} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Kullanıcı Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                                <input className="form-control form-input mb-1" onChange={event => this.setState({member: {...this.state.member, username: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Kullanıcı Adı" type="text" value={this.state.member.username} />
                              </div>
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>E-posta<strong className="text-danger ml-1">* {this.state.member.emailVerified === 1 ? <strong className="text-green">(Doğrulanmış)</strong> : <strong className="text-orange">(Doğrulanmamış)</strong> }</strong></strong></h5>
                                <input className="form-control form-input mb-1" onChange={event => this.setState({member: {...this.state.member, email: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.member.email} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Durum</strong></h5>
                                <select className="form-control form-control-sm form-input" onChange={this.activeOnChange} onKeyDown={this.handleKeyPress} value={this.state.member.active}>
                                  <option value="1">Açık</option>
                                  <option value="0">Kapalı</option>
                                </select>
                              </div>
                              {this.state.member.customFields !== null &&
                              <div className="col-md-6">
                                <button className="btn btn-orange my-4" onClick={() => this.setState({isOpenInvoiceModal: true})} title="Transfer Verisi"><i className='fa fa-refresh mr-2'></i>Transfer Verisi</button>
                                <InvoiceModal isOpen={this.state.isOpenInvoiceModal} customFields={this.state.member.customFields} modalTitle="Transfer Verisi" modalDescription="Transfer Verisi" onRequestClose={() => this.setState({isOpenInvoiceModal: false})} />
                              </div>
                              }
                            </div>
                          </div>
                        </div>
                        <br/>
                        <div className="row">
                          <div className="col">
                            <div className="d-flex justify-content-between">
                              <h4 className="text-darkblue">Adres Bilgileri</h4>
                              <button className="btn btn-orange" onClick={() => this.setState({editAddress: null, isOpenAddressModal: true})} title="Adres Ekle"><i className="fa fa-plus"></i></button>
                            </div>
                            <div className="hr-line-dashed"></div>
                            <div className="overflow-auto">
                              <table className="table table-striped table-bordered">
                              <thead>
                                <tr className="text-center text-darkblue">
                                  <th>İl</th>
                                  <th>İlçe</th>
                                  <th>Semt</th>
                                  <th>Mahalle</th>
                                  <th>Açık Adres</th>
                                  <th>Açıklama</th>
                                  <th>Durum</th>
                                  <th>İşlem</th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {this.state.member.memberAddresses.map((memberAddressInfo) =>
                                  <tr key={memberAddressInfo.id}>
                                    <td className="align-middle">{memberAddressInfo.cityName}</td>
                                    <td className="align-middle">{memberAddressInfo.townName}</td>
                                    <td className="align-middle">{memberAddressInfo.districtName}</td>
                                    <td className="align-middle">{memberAddressInfo.quarterName}</td>
                                    <td className="align-middle">{memberAddressInfo.address}</td>
                                    <td className="align-middle">{memberAddressInfo.description}</td>
                                    <td className="align-middle">{memberAddressInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                                    <td className="align-middle" style={{minWidth:98.5}}>
                                      <button className="btn btn-orange m-1" onClick={() => {this.setState({isLoading: true, editAddress: memberAddressInfo,isOpenAddressModal: true }, () => {this.setState({ isLoading: false });});}} title="Düzenle"><i className="fa fa-pencil"></i></button>
                                      <button className="btn btn-darkblue" onClick={() => this.deleteMemberAddressOnClick(memberAddressInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                                    </td>
                                  </tr>
                                )}
                                {this.state.member.memberAddresses.length === 0 ?
                                  <tr>
                                    <td className="align-middle p-3" colSpan="8">Adres Bulunamadı !</td>
                                  </tr>
                                : null}
                              </tbody>
                              </table>
                              <AddressModal isOpen={this.state.isOpenAddressModal} modalTitle="Adres Ekle" modalDescription="Adres bilgisi eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editAddress: null, isOpenAddressModal: false})} saveOnClick={addressInfo => this.saveMemberAddressOnClick(addressInfo, this.props.searchParams.get('id'))} />
                              {this.state.editAddress ?
                                 <AddressModal defaultValue={this.state.editAddress} isOpen={this.state.isOpenAddressModal} modalTitle="Adres Düzenle" modalDescription="Adres bilgisini düzenlemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editAddress: null, isOpenAddressModal: false})} saveOnClick={addressInfo => this.updateMemberAddressOnClick(addressInfo, this.props.searchParams.get('id'))} />
                              : null}
                            </div>
                          </div>
                        </div>
                        <br/>
                        <div className="row">
                          <div className="col">
                            <div className="d-flex justify-content-between">
                              <h4 className="text-darkblue">Telefon Bilgileri</h4>
                              <button className="btn btn-orange" onClick={() => this.setState({editPhone: null, isOpenPhoneModal: true})} title="Telefon Ekle"><i className="fa fa-plus"></i></button>
                            </div>
                            <div className="hr-line-dashed"></div>
                            <div className="overflow-auto">
                              <table className="table table-striped table-bordered">
                              <thead>
                                <tr className="text-center text-darkblue">
                                  <th>Telefon Tipi</th>
                                  <th>Telefon Numarası</th>
                                  <th>Ülke Kodu</th>
                                  <th>Varsayılan</th>
                                  <th>İletişim Numarası</th>
                                  <th>Durum</th>
                                  <th>İşlem</th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {this.state.member.memberPhones.map((memberPhoneInfo, index) =>
                                  <tr key={index}>
                                    <td className="align-middle">{memberPhoneInfo.phoneTypeName}</td>
                                    <td className="align-middle">{this.phoneFormat(memberPhoneInfo.phoneNumber)} {memberPhoneInfo.verified === 1 ? <strong className="fa fa-check text-green"></strong> : <strong className=" "></strong> }</td>
                                    <td className="align-middle">{memberPhoneInfo.countryName}</td>
                                    <td className="align-middle"><input checked={memberPhoneInfo.defaultSelected} onChange={() => this.defaultSelectedOnChange(!memberPhoneInfo.defaultSelected ? 1 : 0, index, memberPhoneInfo.id)} type="checkbox" /></td>
                                    <td className="align-middle"><input checked={memberPhoneInfo.contactSelected} onChange={() => this.contactSelectedOnChange(!memberPhoneInfo.contactSelected ? 1 : 0, index, memberPhoneInfo.id)} type="checkbox" /></td>
                                    <td className="align-middle">{memberPhoneInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                                    <td className="align-middle" style={{minWidth:98.5}}>
                                      <button className="btn btn-orange m-1" onClick={() => {this.setState({isLoading: true, editPhone: memberPhoneInfo, isOpenPhoneModal: true }, () => {this.setState({ isLoading: false });});}} title="Düzenle"><i className="fa fa-pencil"></i></button>
                                      <button className="btn btn-darkblue" onClick={() => this.deleteMemberPhoneOnClick(memberPhoneInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                                    </td>
                                  </tr>
                                )}
                                {this.state.member.memberPhones.length === 0 ?
                                  <tr>
                                    <td className="align-middle p-3" colSpan="7">Telefon Bulunamadı !</td>
                                  </tr>
                                : null}
                              </tbody>
                              </table>
                              <PhoneModal isOpen={this.state.isOpenPhoneModal} modalTitle="Telefon Ekle" modalDescription="Telefon bilgisi eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editPhone: null, isOpenPhoneModal: false})} saveOnClick={phoneInfo => this.saveMemberPhoneOnClick(phoneInfo, this.state.memberId)} />
                              {this.state.editPhone ?
                                <PhoneModal defaultValue={this.state.editPhone} isOpen={this.state.isOpenPhoneModal} modalTitle="Telefon Düzenle" modalDescription="Telefon bilgisini düzenlemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editPhone: null, isOpenPhoneModal: false})} saveOnClick={phoneInfo => this.updateMemberPhoneOnClick(phoneInfo, this.props.searchParams.get('id'))} />
                              : null}
                            </div>
                          </div>
                        </div>
                        <br/>
                        <div className="row">
                          <div className="col">
                            <h4 className="text-darkblue">Detay Bilgiler</h4>
                            <div className="hr-line-dashed"></div>
                            <div className="row">
                              <div className="col-md-10 mb-3">
                                <h5 className="text-darkblue"><strong>Üye Olunan Portallar</strong></h5>
                                {this.state.otherPortals.map((otherPortalInfo) =>
                                  <div key={otherPortalInfo.id}>
                                    <div className="icheckbox_square-green d-block d-md-flex align-items-center" style={{minHeight:75}}>
                                      <label className="d-block d-md-flex col-md-3 pointer mt-1">
                                        <input
                                          checked={this.state.member.memberOtherPortals.some(memberOtherPortalInfo => memberOtherPortalInfo.otherPortalId === otherPortalInfo.id)}
                                          id={otherPortalInfo.id}
                                          onChange={() => this.toggleCheckbox(otherPortalInfo.id)}
                                          type="checkbox"
                                        /><span className="font-normal ml-1">{otherPortalInfo.name}</span>
                                      </label>
                                      {this.state.member.memberOtherPortals.map(memberOtherPortalInfo => memberOtherPortalInfo.otherPortalId).includes(otherPortalInfo.id) && (
                                        <input
                                        className="form-control form-input d-block d-md-flex col-md-6 m-2"
                                        onChange={(e) => this.handleTextareaChange(otherPortalInfo.id, e.target.value)}
                                        placeholder="Portal adresini yazınız."
                                        type="text"
                                        value={this.state.member.memberOtherPortals.find(memberOtherPortalInfo => memberOtherPortalInfo.otherPortalId === otherPortalInfo.id)?.url || ''}
                                      />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="text-row text-right mt-1">
                              <button className="btn bg-orange text-white" onClick={this.saveMemberOnClick}><i className="fa fa-save"></i> Kaydet</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CancelTransactionModal isOpen={this.state.isOpenCancelTransactionModal} memberTransactionId={this.state.memberTransactionId} modalTitle="İşlem İptali" modalDescription="İşlemi iptal edebilmek için lütfen aşağıdaki bilgileri doldurunuz !" onCompleted={this.onCompleted} onRequestClose={() => this.setState({isOpenCancelTransactionModal: false})} />
                    <div className={this.state.selectedTabIndex === 1 ? 'tab-pane active' : 'tab-pane'}>
                      <div className="panel-body">
                        <div className="row custom-container mb-3">
                          <div className="col overflow-auto">
                            <div className="ibox table-width">
                              <div className="ibox-title text-darkblue">
                                <h5>İşlem Detayları</h5>
                              </div>
                              <div className="table-responsive overflow-x-auto">
                                <table className="table table-striped table-bordered">
                                <thead>
                                  <tr className="text-center text-darkblue">
                                    <th>Ürün İçeriği</th>
                                    <th>Ödeme Tipi</th>
                                    <th>Toplam Tutar</th>
                                    <th>İade Tutarı</th>
                                    <th>Ödeme Tarihi</th>
                                    <th>Durum</th>
                                    <th>Ödeme İşlemi</th>
                                  </tr>
                                </thead>
                                <tbody className="text-center">
                                  {this.state.memberTransactions.map((memberTransactionInfo) =>
                                    <tr key={memberTransactionInfo.id}>
                                      <td className="align-middle">
                                        {memberTransactionInfo.paymentStatus === 2 || memberTransactionInfo.paymentStatus === 4 ? <span>{memberTransactionInfo.transactionProducts.replace('INTERVAL ', '')}</span>
                                        : <span className="align-middle">
                                            {memberTransactionInfo.transactionItems.map((transactionItemInfo) =>
                                            <ul className="list-style text-dark" key={transactionItemInfo.id}>
                                              <li>{transactionItemInfo.productName + ' (' + this.getProductPeriod(transactionItemInfo.productPeriod) + ' - ' + transactionItemInfo.productPrice + ' ₺ )'}<a className="fa fa-trash float-right" onClick={() => this.deleteToBasketOnClick(transactionItemInfo.id)} title="Sepetten Ürün Sil"></a></li>
                                            </ul>
                                            )}
                                          {memberTransactionInfo.transactionItems.length === 0 ?
                                            '-'
                                          : null}
                                          </span>
                                        }
                                      </td>
                                      <td className="align-middle">{this.state.paymentTypes.find(payment => payment.id === memberTransactionInfo.paymentType)?.name || '-'}<br/>{memberTransactionInfo.paymentInfo}</td>
                                      <td className="align-middle">{priceFormat(memberTransactionInfo.price)} ₺</td>
                                      <td className="align-middle">{priceFormat(memberTransactionInfo.refund)} ₺</td>
                                      <td className="align-middle">{memberTransactionInfo.paymentDate ? isoToDateTime(memberTransactionInfo.paymentDate) : '-'}</td>
                                      <td className="align-middle">{this.getTransactionStatus(memberTransactionInfo)}<br/>{memberTransactionInfo.cancelledDescription &&<button className="btn btn-default btn-circle" onClick={() => this.setState({cancelledDescription: memberTransactionInfo.cancelledDescription, isOpenMemberTransactionStatus: true})} title="Açıklama"><i className="fa fa-info-circle"></i></button>}<br/>{memberTransactionInfo.cancelledDate ? isoToDateTime(memberTransactionInfo.cancelledDate) : ''}</td>
                                      <td className="align-middle">{this.getPaymentStatus(memberTransactionInfo)}</td>
                                    </tr>
                                  )}
                                  {this.state.memberTransactions.length === 0 && <tr><td className="align-middle p-3" colSpan="7">Üyeye Ait İşlem Bulunamadı !</td></tr>
                                  }
                                </tbody>
                                </table>
                                {this.state.isOpenMemberTransactionStatus === true &&
                                  <StatusModal isOpen={this.state.isOpenMemberTransactionStatus} cancelledDescription={this.state.cancelledDescription} modalTitle="Açıklama" modalDescription="" onRequestClose={() => this.setState({isOpenMemberTransactionStatus: false})} />
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col">
                            <h3 className="text-darkblue">Ürünlerimiz</h3>
                          </div>
                        </div>
                        <div className="row">
                          {this.state.products.map((productInfo) =>
                            <div className="col-md-6 col-lg-3 mb-3" key={productInfo.id}>
                              <div className="card w-100 h-100">
                                <img className="card-img-top" src={Images.productImg} alt="Card image cap"/>
                                <span className="text-white bg-darkblue border-darkblue font-bold p-2 text-right">{priceFormat(productInfo.price)} ₺</span>
                                <div className="card-body d-flex flex-column">
                                  <small className="font-bold text-darkblue">{this.getProductPeriod(productInfo.productPeriod)}</small>
                                  <h5 className="card-title">{productInfo.name}</h5>
                                  <p className="card-text">{productInfo.description}</p>
                                  <div className="position-relative mb-2">
                                    {productInfo.productItems.map((itemInfo, index) =>
                                      <ul className="text-dark pl-3 mb-1" key={index}>
                                        <li>{itemInfo.name + ' ( ' + this.getProductPeriod(itemInfo.productPeriod) + ' - ' + itemInfo.quantity + ' Adet )'}</li>
                                      </ul>
                                    )}
                                    {productInfo.productItems.length === 0 ?
                                      <ul className="text-dark pl-3">
                                        <li>Tekli Ürün !</li>
                                      </ul>
                                    : null}
                                  </div>
                                  <div className="d-flex justify-content-end mt-auto"> 
                                    <button className="btn btn-xs btn-orange2" onClick={() => this.addToBasketOnClick(productInfo.id)} title="Sepete Ekle"><i className="fa fa-cart-plus"></i> Sepete Ekle</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className={this.state.selectedTabIndex === 2 ? 'tab-pane active' : 'tab-pane'}>
                      <div className="panel-body">
                        <div className="row">
                          <div className="col">
                            <div className="d-flex">
                              <h4 className="text-darkblue">Üyeye Ait Ürünler</h4>
                            </div>
                            <div className="table-responsive overflow-x-auto mt-3">
                              <table className="table table-striped table-bordered">
                              <thead>
                                <tr className="text-center text-darkblue">
                                  <th>Ürün Adı</th>
                                  <th>Ürün Başlangıç Tarihi</th>
                                  <th>Ürün Bitiş Tarihi</th>
                                  <th>Ürün İptali</th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {this.state.memberProducts.map((memberProductInfo) =>
                                  <tr key={memberProductInfo.id}>
                                    <td className="align-middle">{memberProductInfo.productName + ' (' + memberProductInfo.quantity + ' Adet )'}</td>
                                    <td className="align-middle">{memberProductInfo.active === 0 ? <s>{isoToDateTime(memberProductInfo.periodStart)}</s> : isoToDateTime(memberProductInfo.periodStart)}</td>
                                    <td className="align-middle"><button className="btn btn-orange mr-1" onClick={() => this.packageProductTimeOnClick(memberProductInfo.id, 'decrease')} title="-1 Ay"><i className="fa fa-minus"></i></button>{memberProductInfo.active === 0 ? <s>{isoToDateTime(memberProductInfo.periodEnd)}</s> : isoToDateTime(memberProductInfo.periodEnd)}<button className="btn btn-orange ml-1" onClick={() => {this.packageProductTimeOnClick(memberProductInfo.id, 'increase')}} title="+1 Ay"><i className="fa fa-plus"></i></button></td>
                                    <td className="align-middle">{memberProductInfo.active !== 0 ? <button className="btn btn-darkblue" onClick={() => this.cancelMemberProductOnClick(memberProductInfo.id)} title="İptal Et"><i className="fa fa-times"></i></button> : <span className="text-orange">Ürün iptal edildi.</span>}</td>
                                  </tr>
                                )}
                                {this.state.memberProducts.length === 0 && <tr><td className="align-middle p-3" colSpan="4">Üyeye Ait Paket Bulunamadı !</td></tr>}
                              </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={this.state.selectedTabIndex === 3 ? 'tab-pane active' : 'tab-pane'}>
                      <div className="panel-body">
                        <div className="row">
                          <div className="col">
                            <div className="d-flex">
                              <h4 className="text-darkblue">Fatura Bilgileri</h4>
                            </div>
                            <div className="table-responsive overflow-x-auto mt-3">
                              <table className="table table-striped table-bordered">
                              <thead>
                                <tr className="text-center text-darkblue">
                                  <th>Ürün Detayı</th>
                                  <th>Satış Sorumluları</th>
                                  <th>Ödeme Tarihi</th>
                                  <th>Fatura Tarihi</th>
                                  <th>Fatura Durumu</th>
                                  <th>Fatura Tutarı</th>
                                  <th>Fatura Düzenle</th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {this.state.memberInvoices.map((memberInvoiceInfo)=>
                                  <tr key={memberInvoiceInfo.id}>
                                    <td className="align-middle">{memberInvoiceInfo.transactionProducts.replace('INTERVAL ', '')}</td>
                                    <td className="align-middle">{(memberInvoiceInfo.invoiceMarketers.map((invoiceMarketerInfo) => <h5 className="font-normal" key={invoiceMarketerInfo.id}>{invoiceMarketerInfo.memberFullName + ' (' + priceFormat(invoiceMarketerInfo.price) + ' ₺)'}</h5>))}</td>
                                    <td className="align-middle">{memberInvoiceInfo.paymentDate ? isoToDateTime(memberInvoiceInfo.paymentDate) : '-'}</td>
                                    <td className="align-middle">{memberInvoiceInfo.billingPeriod ? isoToDateTime(memberInvoiceInfo.billingPeriod) : '-'}</td>
                                    <td className="align-middle">{this.getInvoiceStatus(memberInvoiceInfo)}</td>
                                    <td className="align-middle">{priceFormat(memberInvoiceInfo.price)} ₺</td>
                                    <td>
                                      <Link className="btn btn-orange text-center" to={"/transactionInvoiceEdit?id=" + memberInvoiceInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                                    </td>
                                  </tr>
                                )}
                                {this.state.memberInvoices.length === 0 && <tr><td className="align-middle p-3" colSpan="7">Fatura Bulunamadı !</td></tr>}
                              </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={this.state.selectedTabIndex === 4 ? 'tab-pane active' : 'tab-pane'}>
                      <div className="panel-body">
                        <div className="row">
                          <div className="col mb-3">
                            <strong className="text-darkblue">Üye Not<strong className="text-danger ml-1">*</strong></strong>
                            <textarea className="form-control form-input mt-2" onChange={event => this.setState({memberHistoryComment: event.target.value.trimStart()})} onKeyDown={this.handleKeyPressMemberHistory} placeholder="Üye hakkında not giriniz." value={this.state.memberHistoryComment} />
                            <div className="float-right mt-2">
                              <button className="btn btn-darkblue" onClick={this.saveMemberHistoryOnClick}><i className="fa fa-save"></i> Kaydet</button>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="dark-timeline" id="vertical-timeline">
                              {this.state.memberHistories.map((memberHistoryInfo) =>
                                <div className="vertical-timeline-block p-0" key={memberHistoryInfo.id}>
                                  <div className="vertical-timeline-icon bg-darkblue">
                                    <i className="fa fa-comments-o text-white"></i>
                                  </div>
                                  <div className="vertical-timeline-content">
                                    <h3 className="font-bold text-dark">{memberHistoryInfo.modifiedFullName}</h3>
                                    <h4 className="comment mb-2">{memberHistoryInfo.comment}</h4>
                                    <a className="float-right mt-3" onClick={() => this.deleteMemberHistoryCommentOnClick(memberHistoryInfo.id)} title="Üye Geçmişi Sil"><i className="fa fa-trash text-darkblue"></i></a>
                                    <span className="vertical-date">{isoToDate(memberHistoryInfo.modifiedDate)}<br />
                                      <h5 className="text-orange">{isoToTime(memberHistoryInfo.modifiedDate)}</h5>
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                            {this.state.memberHistories.length === 0 &&  <p className="text-center">Üye Geçmişi Bulunamadı !</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.storeExist === true ? (
                    <div className={this.state.selectedTabIndex === 5 ? 'tab-pane active' : 'tab-pane'}>
                      <div className="panel-body">
                        <div className="row">
                          <div className="col">
                            <div className="d-flex justify-content-between">
                              <h4 className="text-darkblue">Mağaza Bilgileri</h4>
                                {this.state.createStore === true ? <button className="btn btn-orange" onClick={this.saveNewStoreOnClick}>Mağaza Yarat</button>: <button className="btn btn-orange"  onClick={this.saveStoreOnClick}>Kaydet</button>}
                            </div>
                            <div className="hr-line-dashed"></div>
                            <div className="row">
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Mağaza Domain Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                                <input className="form-control form-control-sm form-input mb-1" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, storename: event.target.value}})} placeholder="Mağaza Domain Adı" value={this.state.storeInfo.storename} />
                              </div>
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Mağaza Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                                <input className="form-control form-control-sm form-input mb-1" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, name: event.target.value}})} placeholder="Mağaza Adı" value={this.state.storeInfo.name} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Mağaza Ticari Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                                <input className="form-control form-input mb-1" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, commercialName: event.target.value}})} placeholder="Mağaza Ticari Adı" value={this.state.storeInfo.commercialName} />
                              </div>
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Mağaza Açıklaması<strong className="text-danger ml-1">*</strong></strong></h5>
                                <input className="form-control form-input mb-1" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, description: event.target.value}})} placeholder="Mağaza Açıklaması" value={this.state.storeInfo.description} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Hakkımızda</strong></h5>
                                <input className="form-control form-input mb-1" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, aboutUs: event.target.value}})} placeholder="Hakkımızda" value={this.state.storeInfo.aboutUs} />
                              </div>
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Email<strong className="text-danger ml-1">*</strong></strong></h5>
                                <input className="form-control form-input mb-1" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, email: event.target.value}})} placeholder="Email" value={this.state.storeInfo.email} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Telefon</strong></h5>
                                <input className="form-control form-input mb-1" maxLength={10} onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, phoneNumber: event.target.value.replace(/\D+/g, '')}})} placeholder="Telefon" value={this.state.storeInfo.phoneNumber} />
                              </div>
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Url</strong></h5>
                                <input className="form-control form-input mb-1" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, url: event.target.value}})} placeholder="Url" value={this.state.storeInfo.url} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <h5 className="text-darkblue"><strong>Durum</strong></h5>
                                <select className="form-control form-control-sm form-input" onChange={this.activeStoreOnChange} onKeyDown={this.handleKeyPress} value={this.state.storeInfo.active}>
                                  <option value="1">Açık</option>
                                  <option value="0">Kapalı</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br/>
                        <div className="row">
                          <div className="col-12">
                            <h4 className="text-darkblue">Konum Bilgisi</h4>
                            <div className="hr-line-dashed mt-0"></div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-4">
                                <h5 className="text-darkblue"><strong>Ülke<strong className="text-danger ml-1">*</strong></strong></h5>
                                <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={true} onChange={this.countryOnChange} value={this.state.storeInfo.countryId}>
                                  <option value="">Seçiniz</option>
                                  {this.state.countries.map((countryInfo) => <option key={countryInfo.id} value={countryInfo.id}>{countryInfo.name}</option>)}
                                </select>
                              </div>
                              <div className="col-md-4">
                                <h5 className="text-darkblue"><strong>İl<strong className="text-danger ml-1">*</strong></strong></h5>
                                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.cityOnChange} value={this.state.storeInfo.cityId}>
                                  <option value="">Seçiniz</option>
                                  {this.state.cities.map((cityInfo) => <option key={cityInfo.id} value={cityInfo.id}>{cityInfo.name}</option>)}
                                </select>
                              </div>
                              <div className="col-md-4">
                                <h5 className="text-darkblue"><strong>İlçe<strong className="text-danger ml-1">*</strong></strong></h5>
                                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.townOnChange} value={this.state.storeInfo.townId}>
                                  <option value="">Seçiniz</option>
                                  {this.state.towns.map((townInfo) => <option key={townInfo.id} value={townInfo.id}>{townInfo.name}</option>)}
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <h5 className="text-darkblue"><strong>Semt<strong className="text-danger ml-1">*</strong></strong></h5>
                                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.districtOnChange} value={this.state.storeInfo.districtId}>
                                  <option value="">Seçiniz</option>
                                  {this.state.regions.map((regionInfo) => <option key={regionInfo.id} value={regionInfo.id}>{regionInfo.name}</option>)}
                                </select>
                              </div>
                              <div className="col-md-4">
                                <h5 className="text-darkblue"><strong>Mahalle<strong className="text-danger ml-1">*</strong></strong></h5>
                                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.quarterOnChange} value={this.state.storeInfo.quarterId}>
                                  <option value="">Seçiniz</option>
                                  {this.state.quarters.map((quarterInfo) => <option key={quarterInfo.id} value={quarterInfo.id}>{quarterInfo.name}</option>)}
                                </select>
                              </div>
                              <div className="col-12 mt-3">
                                <div className="google-maps">
                                {this.state.storeInfo.lat !== null && this.state.storeInfo.lon !== null &&
                                  <GoogleMapReact bootstrapURLKeys={{key: "AIzaSyDFDWC7Et5MZXsiJJ2qd_NTT6JdENiMIcM"}}  
                                  center={{ lat: this.state.storeInfo.lat, lng: this.state.storeInfo.lon }}
                                  zoom={this.state.storeInfo.zoom}
                                  defaultCenter={{ lat: this.state.storeInfo.lat, lng: this.state.storeInfo.lon }}
                                  defaultZoom={this.state.storeInfo.zoom}
                                  onClick={(event) => {this.setState({storeInfo: {...this.state.storeInfo, lat: event.lat, lon: event.lng}});}}
                                  >
                                   <Marker lat={this.state.storeInfo.lat} lng={this.state.storeInfo.lon} />
                                  </GoogleMapReact>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 mt-4">
                                <h5 className="text-darkblue"><strong>Mağaza Adresi<strong className="text-danger ml-1">*</strong></strong></h5>
                                <textarea className="form-control form-input mb-1" placeholder="Adres" type="text" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, address: event.target.value}})} value={this.state.storeInfo.address} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <br/>
                        <div className="row">
                          <div className="col">
                            <h4 className="text-darkblue">Fatura Bilgileri</h4>
                            <div className="hr-line-dashed"></div>
                            <div className="row">
                              <div className="col">
                                <h5><strong>Fatura Tipi<strong className="text-danger ml-1">*</strong></strong></h5>
                                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.invoiceTypeOnChange} value={this.state.storeInfo.invoiceType}>
                                  <option value={""}>Seçiniz</option>
                                  <option value={1}>Şahıs Şirketi Faturası</option>
                                  <option value={2}>Kurumsal Fatura</option>
                                </select>
                              </div>
                            </div>
                            {
                              this.state.storeInfo.invoiceType === 0 ? 
                              <div className="row">
                                <div className="co">
                                  <h5 className="text-darkblue"><strong>Ülke<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={true} onChange={this.invoiceCountryOnChange} value={this.state.storeInfo.invoiceCountryId}>
                                    <option value="">Seçiniz</option>
                                    {this.state.countries.map((countryInfo) => <option key={countryInfo.id} value={countryInfo.id}>{countryInfo.name}</option>)}
                                  </select>
                                </div>
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>İl<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.invoiceCityOnChange} value={this.state.storeInfo.invoiceCityId}>
                                    <option value="">Seçiniz</option>
                                    {this.state.cities.map((cityInfo) => <option key={cityInfo.id} value={cityInfo.id}>{cityInfo.name}</option>)}
                                  </select>
                                </div>
                                <div className="col-12">
                                  <h5 className="text-darkblue"><strong>Fatura Adresi<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <textarea className="form-control form-input mb-1" placeholder="Fatura Adresi" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, invoiceAddress: event.target.value}})} type="text" value={this.state.storeInfo.invoiceAddress}/>
                                </div>
                              </div>
                              : this.state.storeInfo.invoiceType === 1 ? 
                              <div className="row">
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>Fatura Sahibi<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <input className="form-control form-input mb-1" placeholder="Fatura Sahibi" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, invoiceFullname: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')}})} type="text" value={this.state.storeInfo.invoiceFullname}/>
                                </div>
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>TC Kimlik Numarası<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <input className="form-control form-input mb-1" maxLength={11} placeholder="TC Kimlik Numarası" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, invoiceIdentity: event.target.value.replace(/\D+/g, '')}})} type="text" value={this.state.storeInfo.invoiceIdentity}/>
                                </div>
                                <div className="col-md-12">
                                  <h5 className="text-darkblue"><strong>Vergi Dairesi<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <input className="form-control form-input mb-1" placeholder="Vergi Dairesi" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, invoiceTaxOffice: event.target.value}})} type="text" value={this.state.storeInfo.invoiceTaxOffice}/>
                                </div>
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>Ülke<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={true} onChange={this.invoiceCountryOnChange} value={this.state.storeInfo.invoiceCountryId}>
                                    <option value="">Seçiniz</option>
                                    {this.state.countries.map((countryInfo) => <option key={countryInfo.id} value={countryInfo.id}>{countryInfo.name}</option>)}
                                  </select>
                                </div>
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>İl<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.invoiceCityOnChange} value={this.state.storeInfo.invoiceCityId}>
                                    <option value="">Seçiniz</option>
                                    {this.state.cities.map((cityInfo) => <option key={cityInfo.id} value={cityInfo.id}>{cityInfo.name}</option>)}
                                  </select>
                                </div>
                                <div className="col-12">
                                  <h5 className="text-darkblue"><strong>Fatura Adresi<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <textarea className="form-control form-input mb-1" placeholder="Fatura Adresi" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, invoiceAddress: event.target.value}})} type="text" value={this.state.storeInfo.invoiceAddress}/>
                                </div>
                              </div>
                              : 
                              <div className="row">
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>Fatura Şirket Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <input className="form-control form-input mb-1" placeholder="Şirket Adı" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, invoiceCompanyName: event.target.value}})} type="text" value={this.state.storeInfo.invoiceCompanyName}/>
                                </div>
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>Vergi Numarası<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <input className="form-control form-input mb-1" maxLength={10} placeholder="Vergi Numarası" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, invoiceTaxNumber: event.target.value.replace(/\D+/g, '')}})} type="text" value={this.state.storeInfo.invoiceTaxNumber}/>
                                </div>
                                <div className="col-md-12">
                                  <h5 className="text-darkblue"><strong>Vergi Dairesi<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <input className="form-control form-input mb-1" placeholder="Vergi Dairesi" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, invoiceTaxOffice: event.target.value}})} type="text" value={this.state.storeInfo.invoiceTaxOffice}/>
                                </div>
                                <div className="col">
                                  <h5 className="text-darkblue"><strong>Ülke<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={true} onChange={this.invoiceCountryOnChange} value={this.state.storeInfo.invoiceCountryId}>
                                    <option value="">Seçiniz</option>
                                    {this.state.countries.map((countryInfo) => <option key={countryInfo.id} value={countryInfo.id}>{countryInfo.name}</option>)}
                                  </select>
                                </div>
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>İl<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.invoiceCityOnChange} value={this.state.storeInfo.invoiceCityId}>
                                    <option value="">Seçiniz</option>
                                    {this.state.cities.map((cityInfo) => <option key={cityInfo.id} value={cityInfo.id}>{cityInfo.name}</option>)}
                                  </select> 
                                </div>
                                <div className="col-12">
                                  <h5 className="text-darkblue"><strong>Fatura Adresi<strong className="text-danger ml-1">*</strong></strong></h5>
                                  <textarea className="form-control form-input mb-1" placeholder="Fatura Adresi" onChange={(event) => this.setState({storeInfo: {...this.state.storeInfo, invoiceAddress: event.target.value}})} type="text" value={this.state.storeInfo.invoiceAddress}/>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div> ) :  (
                    this.state.selectedTabIndex === 5 ? (
                      <div className="d-flex justify-content-center mt-3">
                        <button className="btn bg-orange text-white mr-2" onClick={this.handleSaveNewStoreButtonOpen} title="Mağaza Ekle">
                          <i className="fa fa-plus"></i><span className="d-none d-sm-inline"> Mağaza Ekle</span>
                        </button>
                      </div>
                    ) : null
                  )}
                  <div className={this.state.selectedTabIndex === 6 ? 'tab-pane active' : 'tab-pane'}>
                      <div className="panel-body">
                        <div className="row">
                          <div className="col">
                            <div className="d-flex justify-content-between">
                              <h4 className="text-darkblue">Sigorta İşlemleri</h4>
                            </div>
                            <div className="hr-line-dashed"></div>
                            {this.state.koalayAccountNotFound ?
                              <div className="text-center">
                                <h4>Üyenin sigorta üyeliği bulunmamaktadır.</h4>
                              </div>
                            : this.state.koalayAccesDenied ?
                              <div className="text-center">
                                <h4>Bu bölümü görüntülemeye yetkiniz yok.</h4>
                              </div>
                            :
                              <div className="row">
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>Kullanıcı Kodu</strong></h5>
                                  <input className="form-control form-control-sm form-input mb-1" readOnly={true} placeholder="Kullanıcı Kodu" value={this.state.insuranceMemberInfo.memberCode} />
                                </div>
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>Koalay Kullanıcı Adı</strong></h5>
                                  <input className="form-control form-control-sm form-input mb-1" readOnly={true} placeholder="Koalay Kullanıcı Adı" value={this.state.insuranceMemberInfo.thirdId} />
                                </div>
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>Hesap Durumu</strong></h5>
                                  <input className="form-control form-control-sm form-input mb-1" readOnly={true} placeholder="Hesap Durumu" value={this.state.insuranceMemberInfo.active === 1 ? "Açık" : "Kapalı"} />
                                </div>
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>Erişim Durumu</strong></h5>
                                  <input className="form-control form-control-sm form-input mb-1" readOnly={true} placeholder="Erişim Durumu" value={this.state.insuranceMemberInfo.status === 1 ? "Açık" : "Kapalı"} />
                                </div>
                                <div className="col-md-6">
                                  <h5 className="text-darkblue"><strong>Giriş Sayısı</strong></h5>
                                  <input className="form-control form-control-sm form-input mb-1" readOnly={true} placeholder="Giriş Sayısı" value={this.state.insuranceMemberInfo.hit} />
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        <br/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

  }
}

export default function MemberEdit() {
  return (
    <MemberEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}