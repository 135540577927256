import React from 'react';
import Modal from 'react-modal';
import {Navigate} from 'react-router-dom';
import Select from 'react-select';

import swal from 'sweetalert';
import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

export default class ConnectCategoryAttributeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,

      attributeInfo: {
        attributeId: 0,
        listed: 0,
        required: 0,
        categoryId: this.props.categoryId
      },
      
      attributes: [],
    };
  }

  componentDidMount() {
    this.getCategoryAttributes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categoryId !== this.props.categoryId) {
      this.setState(prevState => ({
        attributeInfo: {...prevState.attributeInfo, categoryId: this.props.categoryId}
      }));
    }
  }

  getCategoryAttributes() {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'attributes/tr/', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, attributes: responseData.attributes})
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/categories'));
        break;
        default:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getCategoryAttributes()));
  }

  selectedCategoryAttributeOnChange = (selectedCategoryAttribute) => {
    this.setState({attributeInfo: {...this.state.attributeInfo, attributeId: selectedCategoryAttribute.value}});
  };

  listedOnChange = (event) => {
    const isChecked = event.target.checked;
    this.setState(prevState => ({
      attributeInfo: {
        ...prevState.attributeInfo,
        listed: isChecked ? 1 : 0
      }
    }));
  }

  requiredOnChange = (event) => {
    const isChecked = event.target.checked;
    this.setState(prevState => ({
      attributeInfo: {
        ...prevState.attributeInfo,
        required: isChecked ? 1 : 0
      }
    }));
  }

  saveOnClick = () => {

    let infoMessage = '';

    if(!this.state.attributeInfo.attributeId) {
      infoMessage = "Özellik adı boş geçilemez !";
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.props.saveOnClick(this.state.attributeInfo);
    }
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.saveOnClick();
    }
  }

  render() {
    let items = [];
    this.state.attributes.forEach(attributeInfo => {
      attributeInfo.items.forEach(itemInfo => {
        itemInfo.groupId = attributeInfo.id;
        itemInfo.groupName = attributeInfo.name;
        itemInfo.groupDescription = attributeInfo.description;
        items.push(itemInfo);
      });
    });

    const selectedCategoryAttributeOptions = items.map((itemInfo) => ({
      value: itemInfo.id,
      label: <><b>{itemInfo.name}</b> - <span>({itemInfo.groupName})</span></>,
      search: itemInfo.name + ' ' + itemInfo.groupName
    }));

    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex:2500
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <strong>Özellik Adı<strong className="text-danger ml-1">*</strong></strong>
                <Select
                  className=" form-input mb-2 mt-1 text-dark"
                  options={selectedCategoryAttributeOptions}
                  onChange={this.selectedCategoryAttributeOnChange}
                  onKeyDown={this.handleKeyPress}
                  value={selectedCategoryAttributeOptions.find((selectedCategoryAttributeOptionInfo) => selectedCategoryAttributeOptionInfo.value === this.state.selectedCategoryAttributeOptionInfo)}
                  placeholder="Özel Alan Adı"
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  filterOption={(option, searchText) => option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  styles={{
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex:3000
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 3000,
                    }),
                  }}
                />
                <div className="d-flex mt-4">
                  <strong>Liste öğesi mi?</strong>
                  <div className="d-flex ml-2">
                    <input checked={this.state.attributeInfo.listed} onChange={this.listedOnChange} onKeyDown={this.handleKeyPress} type="checkbox" />
                  </div>
                </div>
                <div className="d-flex mt-4">
                 <strong>Zorunlu Alan</strong>
                 <div className="d-flex ml-2">
                    <input checked={this.state.attributeInfo.required} onChange={this.requiredOnChange} onKeyDown={this.handleKeyPress} type="checkbox" />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-orange" onClick={this.saveOnClick}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}