import React from 'react';
import {useNavigate, Link} from 'react-router-dom';

import swal from 'sweetalert';

import config from '../../../inc/config';
import {getCookie} from '../../../inc/cookies';

import AddedCategoryAttributeGroupsModal from '../../../component/category/categoryAttribute/AddedCategoryAttributeGroupsModal';

class AttributeGroupsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddedCategoryAttributeGroupsModal: false,

      attributeGroups: []
    };
  }

  componentDidMount() {
    this.getAttributeGroups();
    window.scrollTo(0, 0);
  }

/*   getAttributeGroups() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'attributeGroups/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => responseData.status === 200 ? this.setState({isLoading: false, attributeGroups: responseData.attributeGroups}) : swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => this.props.navigate('/')), () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getAttributeGroups()));
  } */

  getAttributeGroups() {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'attributeGroups/tr/', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, attributeGroups: responseData.attributeGroups})
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'));
        break;
        default:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getAttributeGroups()));
  }

  saveCategoryAttributeGroupOnClick(categoryAttributeGroupInfo) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({name: categoryAttributeGroupInfo.name, description: categoryAttributeGroupInfo.description,   sort: parseInt(categoryAttributeGroupInfo.sort)})
    };

    fetch(config.restApi + 'attributeGroup/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Grup başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getAttributeGroups(), this.setState({isOpenAddedCategoryAttributeGroupsModal: false}), categoryAttributeGroupInfo.name= '', categoryAttributeGroupInfo.description= '', categoryAttributeGroupInfo.sort= 0);
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCategoryAttributeGroupOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Grubu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'attributeGroup/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Grup başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getAttributeGroups());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return this.state.isLoading ?
    <div className="ibox-content sk-loading position-static">
    <div className="sk-spinner sk-spinner-fading-circle">
      <div className="sk-circle1 sk-circle"></div>
      <div className="sk-circle2 sk-circle"></div>
      <div className="sk-circle3 sk-circle"></div>
      <div className="sk-circle4 sk-circle"></div>
      <div className="sk-circle5 sk-circle"></div>
      <div className="sk-circle6 sk-circle"></div>
      <div className="sk-circle7 sk-circle"></div>
      <div className="sk-circle8 sk-circle"></div>
      <div className="sk-circle9 sk-circle"></div>
      <div className="sk-circle10 sk-circle"></div>
      <div className="sk-circle11 sk-circle"></div>
      <div className="sk-circle12 sk-circle"></div>
    </div>
  </div>
  :
  <>
  <div className="row">
    <div className="col">
      <div className="ibox">
        <div className="ibox-title d-flex justify-content-between text-darkblue pr-3">
          <h5>Özellik Grup Listesi</h5>
          <button className="btn btn-orange" onClick={() => this.setState({isOpenAddedCategoryAttributeGroupsModal: true})} title="Ekle"><i className="fa fa-plus"></i></button>
        </div>
        <div className="ibox-content">
          <div className="row mb-3">
            <div className="col">
              <div className="overflow-auto">
                <table className="table table-striped table-bordered">
                <thead>
                  <tr className="text-center text-darkblue">
                    <th>Grup Adı</th>
                    <th>Açıklama</th>
                    <th>Sıralama</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr> 
                </thead>
                <tbody className="text-center">
                  {
                    this.state.attributeGroups.map((attributeGroupInfo) => 
                    <tr key={attributeGroupInfo.id}>
                      <td className="align-middle">{attributeGroupInfo.name}</td>
                      <td className="align-middle">{attributeGroupInfo.description}</td>
                      <td className="align-middle">{attributeGroupInfo.sort}</td>
                      <td className="align-middle">{attributeGroupInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                      <td className="align-middle" style={{minWidth:98.5}}>
                        <Link className="btn btn-outline btn-orange text-center m-1" title="Düzenle" to={'/categoryAttributeGroupEdit?id=' + attributeGroupInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                        <button className="btn btn-outline btn-darkblue" onClick={() => this.deleteCategoryAttributeGroupOnClick(attributeGroupInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                      </td>
                    </tr>
                    )
                  }
                  {this.state.attributeGroups.length === 0 ?
                    <tr>
                      <td className="align-middle p-3" colSpan="5">Özellik Grubu Bulunamadı !</td>
                    </tr>
                  : null}
                </tbody>
                </table>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange" onClick={() => this.setState({isOpenAddedCategoryAttributeGroupsModal: true})} title="Ekle"><i className="fa fa-plus"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddedCategoryAttributeGroupsModal isOpen={this.state.isOpenAddedCategoryAttributeGroupsModal} modalTitle="Grup Ekle" modalDescription="Grup eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({isOpenAddedCategoryAttributeGroupsModal: false})} saveOnClick={categoryAttributeGroupInfo => this.saveCategoryAttributeGroupOnClick(categoryAttributeGroupInfo)} />
      </div>
    </div>
  </div>
  </>
  }
}

export default function AttributeGroups() {
  return (
    <AttributeGroupsModule navigate={useNavigate()} />
  )
}
