import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import swal from 'sweetalert';

import SearchMemberModal from '../../../component/memberWorkItemModals/SearchMemberModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

class MemberWorkItemModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenSearchMemberModal: false,

      companyEmployees: []
    };
  }

  componentDidMount() {
    this.getCompanyEmployees();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  getCompanyEmployees() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'company/tr/employees?limit=1000&filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, companyEmployees: responseData.companyEmployees});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-1 pl-0">
                <div className="col"><h5>Zimmet Sahibi Üye Listesi</h5></div>
                <button className="btn btn-orange" onClick={() => this.setState({isOpenSearchMemberModal: true})} title="Arama"><i className="fa fa-search"></i> Üye Bul</button>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Adı</th>
                      <th>Soyadı</th>
                      <th>Kullanıcı Adı</th>
                      <th>Email</th>
                      <th>Görüntüle</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.companyEmployees.map(companyEmployeeInfo =>
                      <tr key={companyEmployeeInfo.id}>
                        <td className="align-middle">{companyEmployeeInfo.firstname}</td>
                        <td className="align-middle">{companyEmployeeInfo.lastname}</td>
                        <td className="align-middle">{companyEmployeeInfo.username}</td>
                        <td className="align-middle">{companyEmployeeInfo.email}</td>
                        <td className="align-middle">
                          <Link className="btn btn-orange text-center" to={"/memberWorkItemDetail?id=" + companyEmployeeInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                        </td>
                      </tr>
                    )}
                    {this.state.companyEmployees.length === 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="5">Üye Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <SearchMemberModal isOpen={this.state.isOpenSearchMemberModal} companyEmployees={this.state.companyEmployees} modalDescription="Zimmet bilgisine ulaşmak istediğiniz üyeyi seçiniz !" modalTitle="Üye Bul" onRequestClose={() => this.setState({isOpenSearchMemberModal: false})} />
      </>
  }
}

export default function MemberWorkItems() {
  return (
    <MemberWorkItemModule navigate={useNavigate()} />
  )
}