import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToMysqlDateTime, inputDateTimeToIsoDateTime} from '../../../inc/helper';

class MemberWorkItemEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      memberId: 0,
      workItemName: '',
      workItemId: 0,
      deliveryDate: null,
      deliveryNote: '',
      returnDate: null,
      returnNote: '',
      active: 1,
  };
  }

  componentDidMount() {
    this.getMemberWorkItem();
    window.scrollTo(0, 0);
  }

  activeOnChange = (event) => {

    let active = event.target.value === '' ? '' : parseInt(event.target.value);

    this.setState({ active: active});

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'activeStatus/memberWorkItems/' + this.props.searchParams.get('id') + '/' + active, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {}
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getMemberWorkItem() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'memberWorkItem/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, memberId: responseData.memberWorkItem.memberId, workItemName: responseData.memberWorkItem.workItemName, workItemId: responseData.memberWorkItem.workItemId, deliveryDate: responseData.memberWorkItem.deliveryDate, deliveryNote: responseData.memberWorkItem.deliveryNote, returnDate: responseData.memberWorkItem.returnDate, returnNote: responseData.memberWorkItem.returnNote});
        }
        break;
        case 404: {
          this.setState({isLoading: false});
        }
      break;
      default: {
        this.setState({isLoading: false});

      swal({
        dangerMode: true,
        icon: 'warning',
        title: 'Hay Aksi',
        text: responseData.message,
        buttons: 'Tamam'
      }).then(() => this.props.navigate('/memberWorkItemDetail?id=' + this.state.memberId));
      }
      break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveMemberWorkItemOnClick();
    }
  }

  saveMemberWorkItemOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.deliveryDate) {
      infoMessage = 'Teslim edilme tarihi boş geçilemez !';
    }
    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({deliveryDate: inputDateTimeToIsoDateTime(this.state.deliveryDate), deliveryNote: this.state.deliveryNote, returnDate: inputDateTimeToIsoDateTime(this.state.returnDate), returnNote: this.state.returnNote})
      };

      fetch(config.restApi + 'memberWorkItem/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});

        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Zimmet bilgisi başarıyla güncellendi.',
              buttons: 'Tamam'
            });
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  deleteMemberWorkItemOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Kişiye ait zimmeti silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'memberWorkItem/tr/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Zimmet başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.props.navigate('/memberWorkItemDetail?id=' + this.state.memberId));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content text-darkblue">
                <div className="d-flex justify-content-between">
                  <h4 className="text-darkblue">Zimmet Bilgisi Güncelleme</h4>
                  <div className="d-flex">
                    <button className="btn btn-orange mr-2" onClick={this.deleteMemberWorkItemOnClick} title="Sil"><i className="fa fa-trash"></i></button>
                    <button className="btn btn-orange mr-2" onClick={this.saveMemberWorkItemOnClick} title="Kaydet"><i className="fa fa-save"></i></button>
                    <Link className="btn btn-darkblue text-white" title="Geri" to={"/memberWorkItemDetail?id=" + this.state.memberId}><i className="fa fa-reply"></i></Link>
                  </div>
                </div>
                <div className="hr-line-dashed"></div>
                <div className="row">
                  <div className="col">
                    <strong>Ürün Adı</strong>
                    <input className="form-control form-input mb-2 mt-1" placeholder="Ürün Adı" readOnly type="text" value={this.state.workItemName} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Teslim Edilme Tarihi<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-control-sm form-input mb-2 mt-1" onChange={(event) => this.setState({deliveryDate: event.target.value || null})} onKeyDown={this.handleKeyPress} type="datetime-local" value={this.state.deliveryDate ? isoToMysqlDateTime(this.state.deliveryDate) : ''} />
                  </div>
                  <div className="col-md-6">
                    <strong>Teslim Edilme Notu</strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({deliveryNote: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Teslim Edilme Notu" type="text" value={this.state.deliveryNote}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Teslim Alma Tarihi</strong>
                    <input className="form-control form-control-sm form-input mb-2 mt-1" onChange={(event) => this.setState({returnDate: event.target.value || null})} onKeyDown={this.handleKeyPress} type="datetime-local" value={this.state.returnDate ? isoToMysqlDateTime(this.state.returnDate) : ''} />
                  </div>
                  <div className="col-md-6">
                    <strong>Teslim Alma Notu</strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({returnNote: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Teslim Alma Notu" type="text" value={this.state.returnNote}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h5><strong>Durum</strong></h5>
                    <select className="form-control form-control-sm form-input" onChange={this.activeOnChange} onKeyDown={this.handleKeyPress} value={this.state.active}>
                      <option value="1">Açık</option>
                      <option value="0">Kapalı</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange" onClick={this.saveMemberWorkItemOnClick}>Kaydet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function MemberWorkItemEdit() {
  return (
    <MemberWorkItemEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}