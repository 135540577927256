export default {
  host: 'satisgaranti.com',
  restApi: 'https://api.satisgaranti.com:8443/v1.0/',
  languages: ['tr', 'en' ,'de', 'fr'],
  dataTypes: [
    {id: 1, name: 'BOOLEAN'},
    {id: 2, name: 'DOUBLE'},
    {id: 3, name: 'ID'},
    {id: 4, name: 'LONG'},
    {id: 5, name: 'STRING'}
  ],
  inputTypes: [
    {id: 1, name: 'COMBO'},
    {id: 2, name: 'HIDDEN'},
    {id: 3, name: 'LIST'},
    {id: 4, name: 'VALUE'}
  ],
  customWidgetList: [
    {id: 1, name: 'alertCheckBox'},
    {id: 2, name: 'alertEditText'},
    {id: 3, name: 'alertNumberText'},
    {id: 4, name: 'alertDatePicker'},
    {id: 5, name: 'alertPhoneNumber'},
    {id: 6, name: 'alertPrice'},
    {id: 7, name: 'alertPriceRange'},
    {id: 8, name: 'alertRadioButton'},
    {id: 9, name: 'alertRange'},
    {id: 10, name: 'browseFile'},
    {id: 11, name: 'categories'},
    {id: 12, name: 'contractBox'},
    {id: 13, name: 'checkBox'},
    {id: 14, name: 'chooseRegion'},
    {id: 15, name: 'radioButton'},
    {id: 16, name: 'showText'},
    {id: 17, name: 'showUrl'}       
  ],
  categoryAttributeGroupList: [
    {id: 1, name: 'Genel Özellikler'},
    {id: 2, name: 'Dış Özellikler'}
  ]
}