import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import AgreementLanguageAddModal from '../../../component/siteManagementModals/agreements/AgreementLanguageAddModal'

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

class AgreementEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isAgreementLanguageAddModal: false,

      agreement: []
    };

    this.onCompleted = this.onCompleted.bind(this);
  }

  componentDidMount() {
    this.getAgreement();
    window.scrollTo(0, 0);
  }

  componentWillMount() {
  }

  getAgreement() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'agreement/' + this.props.searchParams.get("id"), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, agreement: responseData.agreement});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getAgreementLanguages() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'agreementLanguages/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({agreement: {...this.state.agreement, agreementLanguages: responseData.agreementLanguages}});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteAgreementLanguageOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Sözleşme dilini silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'agreementLanguage/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Sözleşme dili başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getAgreementLanguages());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  onCompleted() {
    this.getAgreementLanguages();
    this.setState({isAgreementLanguageAddModal: false});
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content text-darkblue">
                <div className="d-flex justify-content-between">
                  <h4>Sözleşme Dil Listesi</h4>
                  <div className="d-flex">
                    <button className="btn btn-orange mr-2" onClick={() => this.setState({isAgreementLanguageAddModal: true})} title="Sözleşme Dili Ekle"><i className="fa fa-plus"></i></button>
                    <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="ibox">
                      <div className="text-darkblue">
                        <AgreementLanguageAddModal agreementId={this.props.searchParams.get('id')} isOpen={this.state.isAgreementLanguageAddModal} modalTitle="Sözleşme Ekle" modalDescription="Sözleşme eklemek için aşağıdaki alanları doldurunuz !" onCompleted={this.onCompleted} onRequestClose={() => this.setState({isAgreementLanguageAddModal: false})} />
                        <div className="hr-line-dashed"></div>
                        <div className="overflow-auto">
                          <table className="table table-striped table-bordered">
                          <thead>
                            <tr className="text-center text-darkblue">
                              <th>Sözleşme Dili</th>
                              <th>Sözleşme Adı</th>
                              <th>Sözleşme İçeriği</th>
                              <th>Durum</th>
                              <th>İşlem</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {this.state.agreement.agreementLanguages.map((agreementLanguageInfo) =>
                              <tr key={agreementLanguageInfo.id}>
                                <td className="align-middle">{agreementLanguageInfo.language}</td>
                                <td className="align-middle">{agreementLanguageInfo.name}</td>
                                <td className="align-middle">{agreementLanguageInfo.content}</td>
                                <td className="align-middle">{agreementLanguageInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                                <td className="align-middle">
                                  <Link className="btn btn-orange text-center mr-2" to={"/agreementLanguageEdit?id=" + agreementLanguageInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                                  <button className="btn btn-darkblue" onClick={() => this.deleteAgreementLanguageOnClick(agreementLanguageInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                                </td>
                              </tr>
                            )}
                            {this.state.agreement.agreementLanguages.length == 0 ?
                              <tr>
                                <td className="align-middle p-3" colSpan="5">Sözleşme Dilleri Bulunamadı !</td>
                              </tr>
                            : null}
                          </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function AgreementEdit() {
  return (
    <AgreementEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}