import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import ProductToPacketAddModal from '../../../component/siteManagementModals/products/ProductToPacketAddModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

class ProductEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenProductToPacketAddModal: false,

      name: '',
      description: '',
      quantity: '0',
      price: '0',
      priceOld: '0',
      categoryIds: 0,
      productPeriod: '',
      productTypeId: 0,
      sort: '0',
      active: 1,

      categories: [],
      productItems: [],
      productTypes: []
    };
  }

  componentDidMount() {
    this.getProduct();
    this.getProductItemDetail();
    window.scrollTo(0, 0);
  }

  getProduct() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 3;
    let complete = () => {
      remaining--;
      if (remaining == 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'product/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, active: responseData.product.active, name: responseData.product.name, description: responseData.product.description, quantity: responseData.product.quantity.toString(), price: responseData.product.price.toString(), priceOld: responseData.product.priceOld.toString(), categoryIds: responseData.product.categoryIds, productPeriod: responseData.product.productPeriod, productTypeId: responseData.product.productTypeId, sort: responseData.product.sort.toString()});
          }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'productTypes?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, productTypes: responseData.productTypes});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'categories/tr/0?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, categories: responseData.categories});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getProductItemDetail() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'productItems/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({productItems: responseData.productItems});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  activeOnChange = (event) => {

    let active = event.target.value == '' ? '' : parseInt(event.target.value);
    
    this.setState({active: active});

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'product/tr/active/' + active + '?id=' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {}
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

  }

  saveProductToPacketAddOnClick(productToPacketInfo) {
    
    let productItems = this.state.productItems;
    let items = [];
    productItems.forEach(item => items.push(item.id));
    items.push(productToPacketInfo.id);

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({productIds: items}),
    };

    fetch(config.restApi + 'productItem/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          this.getProductItemDetail();
          this.setState({isOpenProductToPacketAddModal: false})
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteProductToPacketOnClick(index) {
    let productItems = this.state.productItems;
    let items = [];
    productItems.forEach(item => items.push(item.id));
    items.splice(index, 1);

    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Ürünü paket içerisinden çıkartmak istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'PUT',
          headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
          body: JSON.stringify({productIds: items}),
        };

        fetch(config.restApi + 'productItem/'+ this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Ürün paket içerisinde başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getProductItemDetail());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveProductOnClick();
    }
  }

  saveProductOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = ''; 
  
    if (!this.state.name) {
      infoMessage = 'Ürün adı boş geçilemez !';
    } else if (!this.state.description) {
      infoMessage = 'Açıklama boş geçilemez !';
    } else if (this.state.productTypeId > 1 && !this.state.quantity) {
      infoMessage = 'Ürün miktarı boş geçilemez !';
    } else if (!this.state.price) {
      infoMessage = 'Güncel fiyat bilgisi boş geçilemez !';
    } else if (this.state.productTypeId !=2 && !this.state.productPeriod) {
      infoMessage = 'Geçerlilik süresi boş geçilemez !';
    } else if (this.state.productTypeId < 1) {
      infoMessage = 'Ürün tipi boş geçilemez !';
    } else if (!this.state.sort) {
      infoMessage = 'Sıralama boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});
      
      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({name: this.state.name, description: this.state.description, quantity: parseInt(this.state.quantity), price: parseInt(this.state.price), priceOld: parseInt(this.state.priceOld), categoryIds: this.state.categoryIds, productPeriod: this.state.productPeriod, productTypeId: parseInt(this.state.productTypeId), sort: parseInt(this.state.sort)})
      };

      fetch(config.restApi + 'product/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});

        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Ürün bilgisi başarıyla güncellendi.',
              buttons: 'Tamam'
            });
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  deleteProductOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Ürünü silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'product/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Ürün başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.props.navigate('/products'));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
        <div className="col-12">
          <div className="ibox">
            <div className="ibox-content">
              <div className="d-flex justify-content-between text-darkblue">
                <h4>Ürün Düzenleme</h4>
                <div className="d-flex">
                  <button className="btn btn-orange mr-2" onClick={this.deleteProductOnClick} title="Sil"><i className="fa fa-trash"></i></button>
                  <button className="btn btn-orange mr-2" onClick={this.saveProductOnClick} title="Kaydet"><i className="fa fa-save"></i></button>
                  <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
                </div>
              </div>
              <div className="hr-line-dashed"></div>
                <div className="row">
                  <div className="col-md-6">
                    <strong className="text-darkblue">Ürün Adı<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({name: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Ürün Adı" type="text" value={this.state.name} />
                  </div>
                  <div className="col-md-6">
                    <strong className="text-darkblue">Ürün Tipi<strong className="text-danger ml-1">*</strong></strong>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={(event) => this.setState({productTypeId: event.target.value || 0})} onKeyDown={this.handleKeyPress} value={this.state.productTypeId}>
                      <option value="-1">Seçiniz</option>
                      {this.state.productTypes.map(productTypeInfo => <option key={productTypeInfo.id} value={productTypeInfo.id}>{productTypeInfo.name}</option>)}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="text-darkblue"><strong>Kategori Adı</strong></h5>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={this.state.productTypeId <= 1} onChange={(event) => this.setState({categoryIds: parseInt(event.target.value) || ''})} onKeyDown={this.handleKeyPress} value={this.state.categoryIds}>
                      <option value="">Seçiniz</option>
                      {this.state.categories.map(categoryInfo => <option key={categoryInfo.id} value={categoryInfo.id}>{categoryInfo.name}</option>)}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <h5 className="text-darkblue"><strong>Ürün Miktarı<strong className="text-danger ml-1">*</strong></strong></h5>
                    <input className="form-control form-input mb-2 mt-1" disabled={this.state.productTypeId <= 1} min="0" onChange={event => this.setState({quantity: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Ürün Miktarı" type="number" value={this.state.quantity}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong className="text-darkblue">Güncel Fiyat<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({price: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Güncel Fiyat" type="number" value={this.state.price} />
                  </div>
                  <div className="col-md-6">
                    <strong className="text-darkblue">Eski Fiyat</strong>
                    <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({priceOld: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Eski Fiyat" type="number" value={this.state.priceOld} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6" >
                    <strong className="text-darkblue">Geçerlilik Süresi<strong className="text-danger ml-1">*</strong></strong>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={this.state.productTypeId <= 1} onChange={(event) => this.setState({productPeriod: event.target.value || ''})} onKeyDown={this.handleKeyPress} value={this.state.productPeriod}>
                    <option value="">Seçiniz</option>
                      <option value="INTERVAL 1 DAY">1 Günlük</option>
                      <option value="INTERVAL 1 WEEK">1 Haftalık</option>
                      <option value="INTERVAL 1 MONTH">1 Aylık</option>
                      <option value="INTERVAL 2 MONTH">2 Aylık</option>
                      <option value="INTERVAL 3 MONTH">3 Aylık</option>
                      <option value="INTERVAL 6 MONTH">6 Aylık</option>
                      <option value="INTERVAL 1 YEAR">1 Yıllık</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <strong className="text-darkblue">Sıralama<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" max="999" min="0" onChange={event => this.setState({sort: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Sıralama" type="number" value={this.state.sort}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="text-darkblue"><strong>Durum</strong></h5>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.activeOnChange} onKeyDown={this.handleKeyPress} value={this.state.active}>
                      <option value="1">Açık</option>
                      <option value="0">Kapalı</option>
                    </select>                                  
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong className="text-darkblue">Ürün Açıklaması<strong className="text-danger ml-1">*</strong></strong>
                    <textarea className="form-control input-bordercolor form-input mb-2 mt-1" onChange={event => this.setState({description: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Ürün Açıklaması" value={this.state.description}></textarea>
                  </div>
                </div>
                {
                  this.state.productTypeId==1 && 
                  (
                    <div className="row">
                      <div className="col">
                        <div className="ibox">
                          <div className="ibox-content text-darkblue">
                            <div className="d-flex justify-content-between">
                              <h4>Paket İçeriği</h4>
                              <button className="btn btn-orange" onClick={() => this.setState({isOpenProductToPacketAddModal: true})} title="Paket İçerisine Ürün Ekle"><i className="fa fa-plus"></i></button>
                            </div>
                            <div className="hr-line-dashed"></div>
                            <div className="overflow-auto">
                              <table className="table table-striped table-bordered">
                              <thead>
                                <tr className="text-center text-darkblue">
                                  <th>Ürün Adı</th>
                                  <th>İşlem</th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {this.state.productItems.map((productItemInfo, index) =>
                                  <tr key={index}>
                                    <td className="align-middle">{productItemInfo.name}</td>
                                    <td className="align-middle">
                                    <button className="btn btn-darkblue" onClick={() => this.deleteProductToPacketOnClick(index)} title="Sil"><i className="fa fa-trash"></i></button>
                                    </td>
                                  </tr>
                                )}
                                {this.state.productItems.length === 0 ?
                                  <tr>
                                    <td className="align-middle p-3" colSpan="2">Ürün Bulunamadı !</td>
                                  </tr>
                                : null}
                              </tbody>
                              </table>
                              <ProductToPacketAddModal isOpen={this.state.isOpenProductToPacketAddModal} modalTitle="Paket İçerisine Ürün Ekle" modalDescription="Paket içerisine ürün eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({isOpenProductToPacketAddModal: false})} saveOnClick={productToPacketInfo => this.saveProductToPacketAddOnClick(productToPacketInfo)}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange" onClick={this.saveProductOnClick}>Kaydet</button>
                </div>
            </div>

          </div>
        </div>
      </div>
      </>
  }
}

export default function ProductEdit() {
  return (
    <ProductEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}