import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import swal from 'sweetalert';
import Select from 'react-select'

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDateTime} from '../../inc/helper';

class MemberModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      members: [],
      cities: [],
      towns: [],

      filterActive: '',
      filterRegisterStartDate: '',
      filterPaymentDate: '',
      filterEmail: '',
      filterFullName: '',
      filterId: '',
      filterPhone: '',
      filterUsername: '',

      filterCityId: '',
      filterTownId: '',

      filterStoreName: '',
      filterStoreCommercialName: '',

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    }
  }

  componentDidMount() {
    this.getMembers(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  filteredMembers(currentPage) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});
  
    const query = `limit=${this.state.limit}&page=${currentPage}&filterId=${this.state.filterId}&filterFullName=${this.state.filterFullName}&filterUsername=${this.state.filterUsername}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterStoreName=${this.state.filterStoreName}&filterStoreCommercialName=${this.state.filterStoreCommercialName}&filterRegisterStartDate=${this.state.filterRegisterStartDate}&filterPaymentDate=${this.state.filterPaymentDate}&filterActive=${this.state.filterActive}`;
  
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + sessionHash },
    };
    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;
    
    fetch(config.restApi + `members/tr?${query}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          const currentMembers = responseData.members.slice(startIndex, endIndex);
          this.setState({isLoading: false, members: currentMembers, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam',
          });
        }
        break;
      }
    }, () => swal({dangerMode: true,  icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }
  

  getMembers(currentPage) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining == 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/home'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `members/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentMembers = responseData.members.slice(startIndex, endIndex);
          this.setState({members: currentMembers, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'cities/tr/1?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({cities: responseData.cities, towns: []});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getTowns(cityId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'towns/tr/' + cityId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({filterCityId: cityId, filterTownId: '', towns: responseData.towns});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getPhoneNumber(memberInfo) {
    let activeMemberPhones = memberInfo.memberPhones.filter(item => item.active == 1);

    if(activeMemberPhones.length > 0) {
      let activeMemberPhoneNumber = activeMemberPhones[0].phoneNumber.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
      let phoneMasking = `(${activeMemberPhoneNumber[1]}) ${activeMemberPhoneNumber[2]} ${activeMemberPhoneNumber[3]} ${activeMemberPhoneNumber[4]}` 
      return phoneMasking;
    }
    
    return '-'
  }

  getRegion(memberInfo) {
    let activeMemberAddresses = memberInfo.memberAddresses.filter(item => item.active == 1);
    return activeMemberAddresses.length > 0 ? activeMemberAddresses[0].cityName + '/' + activeMemberAddresses[0].townName : '-';
  }

  handlePageClick = (page) => {
    const updatedPage = page.selected + 1;
    this.setState({ currentPage: updatedPage }, () => {this.filteredMembers(updatedPage);});
  }

  filterActiveOnChange = (event) => {
    this.setState({filterActive: event.target.value});
  }

  selectedCityOnChange = (selectedCity) => {
    let  filterCityId = selectedCity ? selectedCity.value : '';
    if (filterCityId) {
      this.getTowns(filterCityId);
    } else {
      this.setState({filterCityId: '', filterTownId: '', towns: []});
    }
  }

  selectedTownOnChange = (selectedTown) => {
    this.setState({filterTownId: selectedTown ? selectedTown.value : ''});
  }

  filterOnClick = () => {
    const query = `filterId=${this.state.filterId}&filterFullName=${this.state.filterFullName}&filterUsername=${this.state.filterUsername}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterStoreName=${this.state.filterStoreName}&filterStoreCommercialName=${this.state.filterStoreCommercialName}&filterRegisterStartDate=${this.state.filterRegisterStartDate}&filterPaymentDate=${this.state.filterPaymentDate}&filterActive=${this.state.filterActive}`;
    this.filteredMembers(1, query)
  }

  resetOnClick = (event) => {
    this.setState({
      filterActive: '',
      filterRegisterStartDate: '',
      filterPaymentDate: '',
      filterEmail: '',
      filterId: '',
      filterFullName: '',
      filterUsername: '',
      filterPhone: '',
      filterCityId: '',
      filterTownId: '',
      filterStoreName: '',
      filterStoreCommercialName: '',
      currentPage: 1
    }, () => {this.setState({isLoading: true}, () => {this.filteredMembers(this.state.currentPage);this.setState({isLoading: false});});});
  }  

  handleKeyPress = (event, page) => {
    if (event.key === 'Enter') {
      const query = `filterId=${this.state.filterId}&filterFullName=${this.state.filterFullName}&filterUsername=${this.state.filterUsername}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterStoreName=${this.state.filterStoreName}&filterStoreCommercialName=${this.state.filterStoreCommercialName}&filterRegisterStartDate=${this.state.filterRegisterStartDate}&filterPaymentDate=${this.state.filterPaymentDate}&filterActive=${this.state.filterActive}`;
  
      this.setState({isLoading: true, currentPage: 1}, () => {this.filteredMembers(this.state.currentPage, query);this.setState({isLoading: false});});
    }
  }

  render() {
    const selectedCitiesOptions = [
      {value: '', label: 'Seçiniz' }, ...this.state.cities.map((cityInfo) => ({
        value: cityInfo.id,
        label: `${cityInfo.name}`,
        search: cityInfo.name
      }))
    ];
    
    
    const selectedTownsOptions = [
      { value: '', label: 'Seçiniz' }, ...this.state.towns.map((townInfo) => ({
        value: townInfo.id,
        label: `${townInfo.name}`,
        search: townInfo.name
      }))
    ];
    

    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="row custom-container">
        <div className="col-xl-9">
          <div className="ibox">
            <div className="ibox-title text-darkblue">
              <h5>Üye Listesi</h5>
            </div>
            <div className="table-responsive overflow-x-auto">
              <table className="table table-striped table-bordered">
              <thead>
                <tr className="text-center text-darkblue">
                  <th>Adı Soyadı</th>
                  <th>Kullanıcı Adı</th>
                  <th>E-posta</th>
                  <th>Telefon</th>
                  <th>Bölge</th>
                  <th>Kayıt Tarihi</th>
                  <th>Durum</th>
                  <th>İşlem</th>
                </tr>
              </thead>
              <tbody className="text-center">
              {this.state.members.map((memberInfo) =>
                <tr key={memberInfo.id}>
                  <td className="align-middle">{memberInfo.crmMemberId !=null ? <Link title="Potansiyel Üye Profiline Git" to={'/crmMemberEdit?id=' + memberInfo.crmMemberId}><i className="fa fa-star text-gold mr-1"></i></Link> : ''} {memberInfo.firstname} {memberInfo.lastname} {memberInfo.identityVerified === 1 ? <strong className="fa fa-check text-green"></strong> : <strong className=" "></strong>}<br /><span className="text-darkblue">Üye No: </span><span className="text-darkblue"> # {memberInfo.id}</span></td>
                  <td className="align-middle">{memberInfo.username}</td>
                  <td className="align-middle">{memberInfo.email} {memberInfo.emailVerified === 1 ? <i className="fa fa-check text-green"></i> : "" }</td>
                  <td className="align-middle">{this.getPhoneNumber(memberInfo)}</td>
                  <td className="align-middle">{this.getRegion(memberInfo)}</td>
                  <td className="align-middle">{isoToDateTime(memberInfo.addedDate)}</td>
                  <td className="align-middle">{memberInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                  <td className="align-middle">
                    <Link className="btn btn-orange text-center" to={"/memberEdit?id=" + memberInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                  </td>
                </tr>
              )}
              {this.state.members.length == 0 ?
                <tr>
                  <td className="align-middle p-3" colSpan="8">Üye Bulunamadı !</td>
                </tr>
              : null}
              </tbody>
              </table>
              {
                this.state.members.length !== 0 &&
                <div className="d-flex justify-content-sm-end justify-content-start pl-1 pl-sm-0">
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={10}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  forcePage={this.state.currentPage - 1}
                  />
                </div>
              }
            </div>
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
          <div className="ibox">
            <div className="ibox-content text-darkblue p-0">
              <h4 className="collapse-title">
                <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
              </h4>
            </div>
            <div className="collapse" data-parent="#accordion" id="filterCollapse">
              <div className="ibox-content text-darkblue">
                <strong>Üye No</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterId: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye No" type="text" value={this.state.filterId} />
                <strong>Üye Adı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.filterFullName} />
                <strong>Kullanıcı Adı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterUsername: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Kullanıcı Adı" type="text" value={this.state.filterUsername} />
                <strong>E-posta</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterEmail: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.filterEmail} />
                <strong>Telefon</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPhone: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Telefon" type="tel" value={this.state.filterPhone} />
                <strong>Şehir</strong>
                <Select
                  className="form-input mb-2 mt-1 text-dark"
                  onChange={this.selectedCityOnChange}
                  options={selectedCitiesOptions}
                  isClearable={true}
                  defaultValue={selectedCitiesOptions[0]}
                  placeholder="Seçiniz"
                  value={selectedCitiesOptions.find((cityInfo) => cityInfo.value === this.state.filterCityId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                />
                <strong>İlçe</strong>
                <Select
                  className="form-input mb-2 mt-1 text-dark"
                  isDisabled={this.state.towns.length === 0}
                  onChange={this.selectedTownOnChange}
                  options={selectedTownsOptions}
                  isClearable={true}
                  defaultValue={selectedTownsOptions[0]}
                  placeholder="Seçiniz"
                  value={selectedTownsOptions.find((townInfo) => townInfo.value === this.state.filterTownId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                />
                <strong>Firma Adı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterStoreName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Kullanıcı Adı" type="text" value={this.state.filterStoreName} />
                <strong>Firma Ünvanı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterStoreCommercialName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Kullanıcı Adı" type="text" value={this.state.filterStoreCommercialName} />
                <strong>Durum</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={this.filterActiveOnChange} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                  <option value="">Seçiniz</option>
                  <option value="1">Açık</option>
                  <option value="0">Kapalı</option>
                </select>
                <strong>Kayıt Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterRegisterStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterRegisterStartDate} />
                <strong>Ödeme Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPaymentDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterPaymentDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick} type="button"><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick} type="button"><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
          <div className="ibox">
            <div className="ibox-title text-darkblue">
              <h5><i className="fa fa-filter"></i> Filtrele</h5>
            </div>
            <div className="ibox-content text-darkblue">
              <strong>Üye No</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterId: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye No" type="text" value={this.state.filterId} />
              <strong>Üye Adı</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.filterFullName} />
              <strong>Kullanıcı Adı</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterUsername: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Kullanıcı Adı" type="text" value={this.state.filterUsername} />
              <strong>E-posta</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterEmail: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.filterEmail} />
              <strong>Telefon</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPhone: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Telefon" type="tel" value={this.state.filterPhone} />
              <strong>Şehir</strong>
              <Select
                className="form-input mb-2 mt-1 text-dark"
                onChange={this.selectedCityOnChange}
                options={selectedCitiesOptions}
                isClearable={true}
                defaultValue={selectedCitiesOptions[0]}
                placeholder="Seçiniz"
                value={selectedCitiesOptions.find((cityInfo) => cityInfo.value === this.state.filterCityId)}
                filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
              />
              <strong>İlçe</strong>
              <Select
                className="form-input mb-2 mt-1 text-dark"
                isDisabled={this.state.towns.length === 0}
                onChange={this.selectedTownOnChange}
                options={selectedTownsOptions}
                isClearable={true}
                defaultValue={selectedTownsOptions[0]}
                placeholder="Seçiniz"
                value={selectedTownsOptions.find((townInfo) => townInfo.value === this.state.filterTownId)}
                filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
              />
              <strong>Firma Adı</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterStoreName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Firma Adı" type="text" value={this.state.filterStoreName} />
              <strong>Firma Ünvanı</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterStoreCommercialName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Firma Ünvanı" type="text" value={this.state.filterStoreCommercialName} />
              <strong>Durum</strong>
              <select className="form-control form-input mb-2 mt-1" onChange={this.filterActiveOnChange} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                <option value="">Seçiniz</option>
                <option value="1">Açık</option>
                <option value="0">Kapalı</option>
              </select>
              <strong>Kayıt Tarihi</strong>
              <input className="form-control form-input  mb-2 mt-1" onChange={event => this.setState({filterRegisterStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterRegisterStartDate} />
              <strong>Ödeme Tarihi</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPaymentDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterPaymentDate} />
              <div className="d-flex justify-content-end">
                <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick} type="button"><i className="fa fa-refresh"></i> Sıfırla</button>
                <button className="btn btn-orange mt-3" onClick={this.filterOnClick} type="button"><i className="fa fa-filter"></i> Filtrele</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function Members() { return (<MemberModule navigate={useNavigate()} />)}