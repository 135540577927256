import React from 'react';
import Modal from 'react-modal';
import swal from 'sweetalert';

import config from '../../../inc/config';

export default class AddedCategoryFieldModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,

      categoryFieldInfo: {
        name: '',
        description: '',
        dataType: '',
        inputType: '',
        widgetType: '',
        sort: 0
      },

      dataTypes: config.dataTypes,
      inputTypes: config.inputTypes,
      customWidgetList: config.customWidgetList
    };

  }

  componentDidMount() {
  }

  dataTypeOnChange = (event) => {
    const selectedOptionName = event.target.value;
    if (selectedOptionName === "") {
      this.setState({ categoryFieldInfo: { ...this.state.categoryFieldInfo, dataType: "" } });
    } else {
      const selectedOption = this.state.dataTypes.find((dataInfo) => dataInfo.name === selectedOptionName);
      this.setState({ categoryFieldInfo: { ...this.state.categoryFieldInfo, dataType: selectedOption.name } });
    }
  };

  inputTypeOnChange = (event) => {
    const selectedOptionName = event.target.value;
    if (selectedOptionName === "") {
      this.setState({ categoryFieldInfo: { ...this.state.categoryFieldInfo, inputInfo: "" } });
    } else {
      const selectedOption = this.state.inputTypes.find((inputInfo) => inputInfo.name === selectedOptionName);
      this.setState({ categoryFieldInfo: { ...this.state.categoryFieldInfo, inputType: selectedOption.name } });
    }
  };

  fieldTypeOnChange = (event) => {
    const selectedOptionName = event.target.value;
    if (selectedOptionName === "") {
      this.setState({ categoryFieldInfo: { ...this.state.categoryFieldInfo, widgetType: "" } });
    } else {
      const selectedOption = this.state.customWidgetList.find((widgetInfo) => widgetInfo.name === selectedOptionName);
      this.setState({ categoryFieldInfo: { ...this.state.categoryFieldInfo, widgetType: selectedOption.name } });
    }
  };

  saveOnClick() {

    let infoMessage = '';

    if(!this.state.categoryFieldInfo.name) {
      infoMessage = "Alan adı boş geçilemez !";
    } /* else if (!this.state.categoryFieldInfo.type) {
      infoMessage = "Tip boş geçilemez !";
    }  */else if (!this.state.categoryFieldInfo.description) {
      infoMessage = "Açıklama boş geçilemez !";
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.props.saveOnClick(this.state.categoryFieldInfo);
    }
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.saveOnClick();
    }
  }

  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex : 2500
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <strong>Alan Adı<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({categoryFieldInfo: {...this.state.categoryFieldInfo, name: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Alan Adı" type="text" value={this.state.categoryFieldInfo.name} />
                <strong>Alan Tipi<strong className="text-danger ml-1">*</strong></strong>
                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.fieldTypeOnChange} onKeyDown={this.handleKeyPress} value={this.state.categoryFieldInfo.fieldType}>
                  <option value="">Seçiniz</option>
                  {this.state.customWidgetList.map((selectWidgetNameInfo) => <option key={selectWidgetNameInfo.id} value={selectWidgetNameInfo.name}>{selectWidgetNameInfo.name}</option>)}
                </select>
                <strong>Giriş Tipi<strong className="text-danger ml-1">*</strong></strong>
                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.inputTypeOnChange} onKeyDown={this.handleKeyPress} value={this.state.categoryFieldInfo.fieldType}>
                  <option value="">Seçiniz</option>
                  {this.state.inputTypes.map((inputTypeInfo) => <option key={inputTypeInfo.id} value={inputTypeInfo.name}>{inputTypeInfo.name}</option>)}
                </select>
                <strong>Veri Tipi<strong className="text-danger ml-1">*</strong></strong>
                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.dataTypeOnChange} onKeyDown={this.handleKeyPress} value={this.state.categoryFieldInfo.fieldType}>
                  <option value="">Seçiniz</option>
                  {this.state.dataTypes.map((dataTypeInfo) => <option key={dataTypeInfo.id} value={dataTypeInfo.name}>{dataTypeInfo.name}</option>)}
                </select>
                <strong>Dil Seçeneği</strong>
                <select className="form-control form-control-sm form-input mb-2 mt-1" disabled onKeyDown={this.handleKeyPress}>
                  <option value="">Türkçe</option>
                </select>
                <strong>Açıklama<strong className="text-danger ml-1">*</strong></strong>
                <textarea className="form-control form-input mt-1" onChange={(event) =>  this.setState({categoryFieldInfo: {...this.state.categoryFieldInfo, description: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Açıklama giriniz." value={this.state.categoryFieldInfo.description} />
                <strong>Sıralama<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({categoryFieldInfo: {...this.state.categoryFieldInfo, sort: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Sıralama" type="number" value={this.state.categoryFieldInfo.sort}/>
              </div>
              <div className="modal-footer">
                <button className="btn btn-orange" onClick={() => this.saveOnClick()}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}