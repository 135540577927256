import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {inputDateTimeToIsoDateTime, isoToMysqlDateTime} from '../../inc/helper';

class BannerEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      bannerLocationId: 0,
      bannerLocationName: '',
      url: '',
      src: '',
      periodStart: null,
      periodEnd: null,
      active: 1,

      bannerLocations: []
    };
  }

  componentDidMount() {
    this.getBannerDetails();
    window.scrollTo(0, 0);
  }

  getBannerDetails() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'banner/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, active: responseData.banner.active, bannerLocationId: responseData.banner.bannerLocationId, bannerLocationName: responseData.banner.bannerLocationName, url: responseData.banner.url, src: responseData.banner.src, periodStart: responseData.banner.periodStart, periodEnd: responseData.banner.periodEnd});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'bannerLocations/', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, bannerLocations: responseData.bannerLocations});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  activeOnChange = (event) => {

    let active = event.target.value === '' ? '' : parseInt(event.target.value);
    
    this.setState({active: active});

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'activeStatus/bannerList/' + this.props.searchParams.get('id') + '/' + active, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {}
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveBannerOnClick();
    }
  }

  saveBannerOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';

    if (!this.state.bannerLocationId) {
      infoMessage = 'Reklam alanı boş geçilemez !';
    } else if (!this.state.url) {
      infoMessage = 'Web adresi boş geçilemez !';
    } else if (!this.state.src) {
      infoMessage = 'Görsel kaynağı boş geçilemez !';
    } else if (!this.state.periodStart) {
      infoMessage = 'Başlangıç dönemi tarihi boş geçilemez !';
    } else if (!this.state.periodEnd) {
      infoMessage = 'Bitiş dönemi tarihi boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    }  else {
      const selectedStartDate = new Date(this.state.periodStart).getTime();
      const selectedEndDate = new Date(this.state.periodEnd).getTime();
      const currentDate = new Date().getTime();
  
      if (selectedStartDate < currentDate || selectedEndDate < currentDate) {
        swal({
          dangerMode: true,
          icon: 'warning',
          title: 'Uyarı',
          text: 'Seçilen tarihler mevcut tarihten önce olamaz!',
          buttons: 'Tamam'
        });
      } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({bannerLocationId: this.state.bannerLocationId, periodStart: inputDateTimeToIsoDateTime(this.state.periodStart), periodEnd: inputDateTimeToIsoDateTime(this.state.periodEnd), url: this.state.url, src: this.state.src})
      };

        fetch(config.restApi + 'banner/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Reklam bilgisi başarıyla güncellendi.',
                buttons: 'Tamam'
              });
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    }
  }

  deleteBannerOnClick() {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Reklamı silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'banner/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Reklam başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.props.navigate('/banners'));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content text-darkblue">
              <div className="d-flex justify-content-between">
                <h4 className="text-darkblue">Reklam Bilgisi Güncelleme</h4>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mr-2" onClick={() => this.deleteBannerOnClick()} title="Sil"><i className="fa fa-trash"></i></button>
                  <button className="btn btn-orange mr-2" onClick={() => this.saveBannerOnClick()} title="Kaydet"><i className="fa fa-save"></i></button>
                  <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
                </div>
              </div>
                <div className="hr-line-dashed"></div>
                <div className="row">
                  <div className="col">
                    <strong>Reklam Alanı Adı<strong className="text-danger ml-1">*</strong></strong>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={(event) => this.setState({bannerLocationId: parseInt(event.target.value) || ''})} onKeyDown={this.handleKeyPress} value={this.state.bannerLocationId}>
                      {this.state.bannerLocations.map(bannerLocationInfo => <option key={bannerLocationInfo.id} value={bannerLocationInfo.id}>{bannerLocationInfo.name}</option>)}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Web Adresi<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({url: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Web Adresi" type="text" value={this.state.url}/>
                  </div>
                  <div className="col-md-6">
                    <strong>Görsel Kaynağı<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({src: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Görsel Kaynağı" type="text" value={this.state.src}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Başlangıç Dönemi Tarihi<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-control-sm form-input mb-2 mt-1" onChange={(event) => this.setState({periodStart: event.target.value || null})} onKeyDown={this.handleKeyPress} type="datetime-local" value={this.state.periodStart ? isoToMysqlDateTime(this.state.periodStart) : ''} />
                  </div>
                  <div className="col-md-6">
                    <strong>Bitiş Dönemi Tarihi<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-control-sm form-input mb-2 mt-1" onChange={(event) => this.setState({periodEnd: event.target.value || null})} onKeyDown={this.handleKeyPress} type="datetime-local" value={this.state.periodEnd ? isoToMysqlDateTime(this.state.periodEnd) : ''} />
                  </div>
                  <div className="col-md-6">
                    <h5><strong>Durum</strong></h5>
                    <select className="form-control form-control-sm form-input" onChange={this.activeOnChange} onKeyDown={this.handleKeyPress} value={this.state.active}>
                      <option value="1">Açık</option>
                      <option value="0">Kapalı</option>
                    </select>                                  
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange" onClick={() => this.saveBannerOnClick()}>Kaydet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function BannerEdit() {
  return (
    <BannerEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}