import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToMysqlDate, inputDateTimeToIsoDateTime} from '../../../inc/helper';

class MyWorkExpenseEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      name: '',
      description: '',
      expenseDate: null,
      price: 0
  };

  this.deleteMyWorkExpenseOnClick = this.deleteMyWorkExpenseOnClick.bind(this);
  this.expenseDateOnChange = this.expenseDateOnChange.bind(this);
  this.saveMyWorkExpenseOnClick = this.saveMyWorkExpenseOnClick.bind(this);
  }

  componentDidMount() {
    this.getMyWorkExpense();
    window.scrollTo(0, 0);
  }

  getMyWorkExpense() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'myWorkExpense/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, name: responseData.myWorkExpense.name, description: responseData.myWorkExpense.description, expenseDate: responseData.myWorkExpense.expenseDate, price: responseData.myWorkExpense.price.toString()});
        }
        break;
        case 404: {
          this.setState({isLoading: false});
        }
      break;
      default: {
        this.setState({isLoading: false});

      swal({
        dangerMode: true,
        icon: 'warning',
        title: 'Hay Aksi',
        text: responseData.message,
        buttons: 'Tamam'
      }).then(() => this.props.navigate('/myWorkExpenses'));
      }
      break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  expenseDateOnChange(event) {
    this.setState({expenseDate: event.target.value || null});
  }

  saveMyWorkExpenseOnClick() {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';

    if (!this.state.name) {
      infoMessage = 'Masraf adı boş geçilemez !';
    } else if (!this.state.description) {
      infoMessage = 'Açıklama boş geçilemez !';
    } else if (!this.state.expenseDate) {
      infoMessage = 'Harcama tarihi boş geçilemez !';
    } else if (!this.state.price) {
      infoMessage = 'Tutar boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({name: this.state.name, description: this.state.description, expenseDate: inputDateTimeToIsoDateTime(this.state.expenseDate), price: parseInt(this.state.price)})
      };

      fetch(config.restApi + 'myWorkExpense/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});

        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Masraf bilgisi başarıyla güncellendi.',
              buttons: 'Tamam'
            });
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  deleteMyWorkExpenseOnClick() {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Masrafı silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'myWorkExpense/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Masraf başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.props.navigate('/myWorkExpenses'));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>

        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content text-darkblue vh-100">
                <div className="d-flex justify-content-between">
                  <h4>Masraf Bilgisi Güncelleme</h4>
                  <div className="d-flex ibox justify-content-end">
                    <button className="btn btn-orange mr-2" onClick={() => this.deleteMyWorkExpenseOnClick()} title="Sil"><i className="fa fa-trash"></i></button>
                    <button className="btn btn-orange mr-2" onClick={() => this.saveMyWorkExpenseOnClick()} title="Kaydet"><i className="fa fa-save"></i></button>
                    <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
                  </div>
                </div>
                <div className="hr-line-dashed"></div>
                <div className="row">
                  <div className="col">
                    <strong>Masraf Adı<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({name: event.target.value})} placeholder="Masraf Adı" type="text" value={this.state.name}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Açıklama<strong className="text-danger ml-1">*</strong></strong>
                    <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({description: event.target.value})}  placeholder="Açıklama giriniz.." type="text" value={this.state.description} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Harcama Tarihi<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-control-sm form-input mb-2 mt-1" value={this.state.expenseDate ? isoToMysqlDate(this.state.expenseDate) : ''} onChange={this.expenseDateOnChange} type="date"/>
                  </div>
                  <div className="col-md-6">
                    <strong>Tutar<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({price: event.target.value})} placeholder="Tutar" type="number" value={this.state.price}/>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-5">
                  <button className="btn btn-orange" onClick={this.saveMyWorkExpenseOnClick}>Kaydet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function MyWorkExpenseEdit() {
  return (
    <MyWorkExpenseEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}