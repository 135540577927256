import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import swal from 'sweetalert';
import Select from 'react-select'

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDateTime} from '../../../inc/helper';

class CrmMembersRegisteredModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      crmMembers: [],
      crmMemberTypes: [],
      cities: [],
      towns: [],

      filterActive: '',
      filterAddedStartDate: '',
      filterCompanyName: '',
      filterCompanyType: '',
      filterCrmMemberTypeId: '',
      filterEmail: '',
      filterFullName: '',
      filterIsMember: 1,
      filterId: '',
      filterPhone: '',

      filterCityId: '',
      filterTownId: '',

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    }
  }

  componentDidMount() {
    this.getCrmMembers(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  filteredCrmMembers(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const query = `limit=${this.state.limit}&page=${currentPage}&filterCrmMemberTypeId=${this.state.filterCrmMemberTypeId}&filterCompanyName=${this.state.filterCompanyName}&filterCompanyType=${this.state.filterCompanyType}&filterId=${this.state.filterId}&filterFullName=${this.state.filterFullName}&filterIsMember=${this.state.filterIsMember}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterActive=${this.state.filterActive}`;

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `crmMembers/tr?${query}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentCRMMembers = responseData.crmMembers.slice(startIndex, endIndex);
          this.setState({crmMembers: currentCRMMembers, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getCrmMembers(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 3;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `crmMembers/tr?limit=${this.state.limit}&page=${currentPage}&filterIsMember=${this.state.filterIsMember}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentCrmMembers = responseData.crmMembers.slice(startIndex, endIndex);
          this.setState({crmMembers: currentCrmMembers, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'crmMemberTypes?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMemberTypes: responseData.crmMemberTypes});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'cities/tr/1?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({cities: responseData.cities, towns: []});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getTowns(cityId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'towns/tr/' + cityId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({filterCityId: cityId, filterTownId: '', towns: responseData.towns});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getPhoneNumber(crmMemberInfo) {
    let activeCrmMemberPhones = crmMemberInfo.crmMemberPhones.filter(item => item.active === 1);

    if(activeCrmMemberPhones.length > 0) {
      let activeCrmMemberPhoneNumber = activeCrmMemberPhones[0].phoneNumber.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
      let phoneMasking = `(${activeCrmMemberPhoneNumber[1]}) ${activeCrmMemberPhoneNumber[2]} ${activeCrmMemberPhoneNumber[3]} ${activeCrmMemberPhoneNumber[4]}` 
      return phoneMasking;
    }
    
    return '-'
  }

  getRegion(crmMemberInfo) {
    let activeCrmMemberAddresses = crmMemberInfo.crmMemberAddresses.filter(item => item.active === 1);
    return activeCrmMemberAddresses.length > 0 ? activeCrmMemberAddresses[0].cityName + '/' + activeCrmMemberAddresses[0].townName : '-';
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.filteredCrmMembers(this.state.currentPage))
  }

  selectedCityOnChange = (selectedCity) => {
    let filterCityId = selectedCity ? selectedCity.value : '';
    if (filterCityId) {
      this.getTowns(filterCityId);
    } else {
      this.setState({filterCityId: '', filterTownId: '', towns: []});
    }
  }

  selectedTownOnChange = (selectedTown) => {
    this.setState({filterTownId: selectedTown ? selectedTown.value : ''});
  }

  filterOnClick = (event) => {
    const query = `filterCrmMemberTypeId=${this.state.filterCrmMemberTypeId}&filterCompanyName=${this.state.filterCompanyName}&filterCompanyType=${this.state.filterCompanyType}&filterId=${this.state.filterId}&filterFullName=${this.state.filterFullName}&filterIsMember=${this.state.filterIsMember}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterActive=${this.state.filterActive}`;
    this.filteredCrmMembers(1, query)
  }

  resetOnClick = (event) => {
    this.setState({
      filterActive: '',
      filterAddedStartDate: '',
      filterCompanyName: '',
      filterCompanyType: '',
      filterCrmMemberTypeId: '',
      filterEmail: '',
      filterFullName: '',
      filterIsMember: 1,
      filterId: '',
      filterPhone: '',
      filterCityId: '',
      filterTownId: '',
      currentPage: 1
    }, () => {this.setState({isLoading: true}, () => {this.filteredCrmMembers(this.state.currentPage);this.setState({isLoading: false});});});
  }

  handleKeyPress = (event, page) => {
    if (event.key === 'Enter') {
      const query = `filterCrmMemberTypeId=${this.state.filterCrmMemberTypeId}&filterCompanyName=${this.state.filterCompanyName}&filterCompanyType=${this.state.filterCompanyType}&filterId=${this.state.filterId}&filterFullName=${this.state.filterFullName}&filterIsMember=${this.state.filterIsMember}&filterEmail=${this.state.filterEmail}&filterPhone=${this.state.filterPhone}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterActive=${this.state.filterActive}`;
      this.setState({isLoading: true, currentPage: 1}, () => {this.filteredCrmMembers(this.state.currentPage, query);this.setState({isLoading: false});});
    }
  }

  render() {

    const selectedCitiesOptions = [
      {value: '', label: 'Seçiniz' }, ...this.state.cities.map((cityInfo) => ({
        value: cityInfo.id,
        label: `${cityInfo.name}`
      }))
    ];
    
    const selectedTownsOptions = [
      { value: '', label: 'Seçiniz' }, ...this.state.towns.map((townInfo) => ({
        value: townInfo.id,
        label: `${townInfo.name}`
      }))
    ];

    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="row custom-container">
        <div className="col-xl-9">
          <div className="ibox">
            <div className="ibox-title text-darkblue">
              <h5>Nihai Üye Listesi</h5>
            </div>
            <div className="table-responsive overflow-x-auto">
              <table className="table table-striped table-bordered">
              <thead>
                <tr className="text-center text-darkblue">
                  <th>Adı Soyadı</th>
                  <th>Firma Adı</th>  
                  <th>Faaliyet Alanı</th>
                  <th>Telefon</th>
                  <th>Email</th>
                  <th>Bölge</th>
                  <th>Eklenme Tarihi</th>
                  <th>Durum</th>
                  <th>İşlem</th>
                </tr>
              </thead>
              <tbody className="text-center">
              {this.state.crmMembers.map(crmMemberInfo =>
                <tr key={crmMemberInfo.id}>
                  <td className="align-middle">{crmMemberInfo.memberId !== 0 ? <Link title="Üye Profiline Git" to={'/memberEdit?id=' + crmMemberInfo.memberId}><i className="fa fa-star text-gold mr-1"></i></Link> : ''} {crmMemberInfo.firstname} {crmMemberInfo.lastname}<br /><span className="text-darkblue">CRM Üye No: </span><span className="text-darkblue"> # {crmMemberInfo.id}</span></td>
                  <td className="align-middle">{crmMemberInfo.companyName || '-'} <br/><strong className="text-darkblue">{crmMemberInfo.crmMemberTypeName}</strong></td>
                  <td className="align-middle">{crmMemberInfo.companyType || '-'}</td>
                  <td className="align-middle">{this.getPhoneNumber(crmMemberInfo)}</td>
                  <td className="align-middle">{crmMemberInfo.email || '-'}</td>
                  <td className="align-middle">{this.getRegion(crmMemberInfo)}</td>
                  <td className="align-middle">{isoToDateTime(crmMemberInfo.addedDate)}</td>
                  <td className="align-middle">{crmMemberInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                  <td className="align-middle">
                    <Link className="btn btn-orange text-center" to={"/crmMemberEdit?id=" + crmMemberInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                  </td>
                </tr>
              )}
              {this.state.crmMembers.length === 0 ?
                <tr>
                  <td className="align-middle p-3" colSpan="9">Potansiyel Üye Bulunamadı !</td>
                </tr>
              : null}
              </tbody>
              </table>
              {
                  this.state.crmMembers.length !== 0 &&
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={2}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  />
                }
            </div>
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
          <div className="ibox">
            <div className="ibox-content text-darkblue p-0">
              <h4 className="collapse-title">
                <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
              </h4>
            </div>
            <div className="collapse" data-parent="#accordion" id="filterCollapse">
              <div className="ibox-content text-darkblue">
                <strong>Üye No</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterId: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye No" type="text" value={this.state.filterId} />
                <strong>Üyelik Tipi</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterCrmMemberTypeId: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterCrmMemberTypeId}>
                  <option value="">Seçiniz</option>
                  {this.state.crmMemberTypes.map(crmMemberTypeInfo => <option key={crmMemberTypeInfo.id} value={crmMemberTypeInfo.id}>{crmMemberTypeInfo.name}</option>)}
                </select>
                <strong>Üye Adı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.filterFullName} />
                <strong>Firma Adı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Firma Adı" type="text" value={this.state.filterCompanyName} />
                <strong>E-posta</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterEmail: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.filterEmail} />
                <strong>Telefon</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPhone: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Telefon" type="tel" value={this.state.filterPhone} />
                <strong>Şehir</strong>
                <Select
                  className=" form-input mb-2 mt-1 text-dark"
                  onChange={this.selectedCityOnChange}
                  options={selectedCitiesOptions}
                  isClearable={true}
                  defaultValue={selectedCitiesOptions[0]}
                  placeholder="Seçiniz"
                  value={selectedCitiesOptions.find((cityInfo) => cityInfo.value === this.state.filterCityId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                />
                <strong>İlçe</strong>
                <Select
                  className=" form-input mb-2 mt-1 text-dark"
                  isDisabled={this.state.towns.length === 0}
                  onChange={this.selectedTownOnChange}
                  options={selectedTownsOptions}
                  isClearable={true}
                  defaultValue={selectedTownsOptions[0]}
                  placeholder="Seçiniz"
                  value={selectedTownsOptions.find((townInfo) => townInfo.value === this.state.filterTownId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                />
                <strong>Faaliyet Alanı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyType: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Faliyet Alanı" type="tel" value={this.state.filterCompanyType} />
                <strong>Durum</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterActive: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                  <option value="">Seçiniz</option>
                  <option value="1">Açık</option>
                  <option value="0">Kapalı</option>
                </select>
                <strong>Eklenme Tarihi</strong>
                <input className="form-control form-input" onChange={event => this.setState({filterAddedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedStartDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick} type="button"><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick} type="button"><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
          <div className="ibox">
            <div className="ibox-title text-darkblue">
              <h5><i className="fa fa-filter"></i> Filtrele</h5>
            </div>
            <div className="ibox-content text-darkblue">
            <strong>Üye No</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterId: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye No" type="text" value={this.state.filterId} />
              <strong>Üyelik Tipi</strong>
              <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterCrmMemberTypeId: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterCrmMemberTypeId}>
                <option value="">Seçiniz</option>
                {this.state.crmMemberTypes.map(crmMemberTypeInfo => <option key={crmMemberTypeInfo.id} value={crmMemberTypeInfo.id}>{crmMemberTypeInfo.name}</option>)}
              </select>
              <strong>Üye Adı</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.filterFullName} />
              <strong>Firma Adı</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Firma Adı" type="text" value={this.state.filterCompanyName} />
              <strong>E-posta</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterEmail: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.filterEmail} />
              <strong>Telefon</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPhone: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Telefon" type="tel" value={this.state.filterPhone} />
              <strong>Şehir</strong>
              <Select
                className=" form-input mb-2 mt-1 text-dark"
                onChange={this.selectedCityOnChange}
                options={selectedCitiesOptions}
                isClearable={true}
                defaultValue={selectedCitiesOptions[0]}
                placeholder="Seçiniz"
                value={selectedCitiesOptions.find((cityInfo) => cityInfo.value === this.state.filterCityId)}
                filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
              />
              <strong>İlçe</strong>
              <Select
                className=" form-input mb-2 mt-1 text-dark"
                isDisabled={this.state.towns.length === 0}
                onChange={this.selectedTownOnChange}
                options={selectedTownsOptions}
                isClearable={true}
                defaultValue={selectedTownsOptions[0]}
                placeholder="Seçiniz"
                value={selectedTownsOptions.find((townInfo) => townInfo.value === this.state.filterTownId)}
                filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
              />
              <strong>Faaliyet Alanı</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterCompanyType: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Faliyet Alanı" type="text" value={this.state.filterCompanyType} />
              <strong>Durum</strong>
              <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterActive: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                <option value="">Seçiniz</option>
                <option value="1">Açık</option>
                <option value="0">Kapalı</option>
              </select>
              <strong>Eklenme Tarihi</strong>
              <input className="form-control form-input mb-2" onChange={event => this.setState({filterAddedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedStartDate} />
              <div className="d-flex justify-content-end">
                <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function CrmMembersRegistered() {
  return (
    <CrmMembersRegisteredModule navigate={useNavigate()} />
  )
}