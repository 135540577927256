import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import { getCookie } from '../../../inc/cookies';
import config from '../../../inc/config';

import AddedCategoryAttributeGroupLanguageModal from '../../../component/category/categoryAttribute/AddedCategoryAttributeGroupLanguageModal';

class CategoryAttributeGroupEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddedCategoryAttributeGroupLanguageModal: false,

      sort: 0,

      groupLanguageId: 0,
      attributeGroupLanguages: [],
      editAttributeGroupLanguages: null,
    };
  }

  componentDidMount() {
    this.getCategoryAttributeGroupDetail();
    window.scrollTo(0, 0);
  }

  getCategoryAttributeGroupDetail() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/attributeGroups'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'attributeGroup/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({sort: responseData.attributeGroupInfo.sort});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'attributeGroupLanguages/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({attributeGroupLanguages: responseData.attributeGroupLanguages});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  saveAttributeGroupOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.sort && this.state.sort === 0) {
      infoMessage = 'Sıralama boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({sort: parseInt(this.state.sort)})
      };

      fetch(config.restApi + 'attributeGroup/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});

        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Grup başarıyla güncellendi.',
              buttons: 'Tamam'
            });
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  saveCategoryAttributeGroupLanguageOnClick(categoryAttributeGroupLanguageInfo, id) {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({name: categoryAttributeGroupLanguageInfo.name, language: categoryAttributeGroupLanguageInfo.language, description: categoryAttributeGroupLanguageInfo.description})
    };

    fetch(config.restApi + 'attributeGroupLanguage/tr/' + id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Gruba ait çeviri başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getCategoryAttributeGroupDetail(), this.setState({isOpenAddedCategoryAttributeGroupLanguageModal: false}), categoryAttributeGroupLanguageInfo.name = '', categoryAttributeGroupLanguageInfo.language = '', categoryAttributeGroupLanguageInfo.description = '');
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  updateCategoryAttributeGroupLanguageOnClick(categoryAttributeGroupLanguageInfo, id) {
    
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(categoryAttributeGroupLanguageInfo)
    };

    fetch(config.restApi + 'attributeGroupLanguage/tr/' + id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Çeviri başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.getCategoryAttributeGroupDetail(), this.setState({isOpenAddedCategoryAttributeGroupLanguageModal: false, editAttributeGroupLanguages: categoryAttributeGroupLanguageInfo}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCategoryAttributeGroupLanguageOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Çeviriyi silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'attributeGroupLanguage/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Çeviri başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCategoryAttributeGroupDetail());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.saveAttributeGroupOnClick();
    }
  }
  // handleClick = (fieldLanguageInfo) => {
  //   this.setState({
  //     editAttributeGroupLanguages: fieldLanguageInfo,
  //     isOpenAddedCategoryAttributeGroupLanguageModal: true,
  //     groupLanguageId: fieldLanguageInfo.id,
  //     buttonRef: this.buttonRef.current,
  //   }, () => {
  //     this.state.buttonRef.focus();
  //   });
  // }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
      <div className="row">
        <div className="col">
          <div className="ibox">
            <div className="ibox-title text-darkblue d-flex justify-content-between pr-2">
              <h5>Özellik Grubu Düzenle</h5>
              <div>
                <button className="btn btn-orange mr-2" title="Kaydet" onClick={this.saveAttributeGroupOnClick}><i className="fa fa-save"></i></button>
                <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
              </div>
            </div>
            <div className="ibox-content">  
              <div className="row mb-5">
                <div className="col-md-6">
                  <h5 className="text-darkblue"><strong>Sıralama<strong className="text-danger ml-1">*</strong></strong></h5>
                  <input className="form-control form-input mb-2 mt-1" min="0" onChange={(event) => this.setState({sort: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Sıralama" type="number" value={this.state.sort} />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darkblue">Özellik Grubuna Ait Çeviri Listesi</h4>
                    <button className="btn btn-orange" onClick={() => this.setState({editAttributeGroupLanguages: null, isOpenAddedCategoryAttributeGroupLanguageModal: true})} title="Dil Ekle"><i className="fa fa-plus"></i></button>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="row">
                    <div className="col">
                    <div className="overflow-auto">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="text-center text-darkblue">
                          <th>Çeviri</th>
                          <th>Dil</th>
                          <th>Açıklama</th>
                          <th>Durum</th>
                          <th>İşlem</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {this.state.attributeGroupLanguages.map((attributeGroupLanguageInfo) =>
                          <tr key={attributeGroupLanguageInfo.id}>
                            <td className="align-middle text-orange">{attributeGroupLanguageInfo.name}</td>
                            <td className="align-middle">{attributeGroupLanguageInfo.language}</td>
                            <td className="align-middle">{attributeGroupLanguageInfo.description}</td>
                            <td className="align-middle">{attributeGroupLanguageInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                            <td className="align-middle" style={{minWidth:98.5}}>
                              <button className="btn btn-outline btn-orange text-center m-1" onClick={() => this.setState({isOpenAddedCategoryAttributeGroupLanguageModal: true, groupLanguageId: attributeGroupLanguageInfo.id, editAttributeGroupLanguages: attributeGroupLanguageInfo})} title="Düzenle"><i className="fa fa-pencil text-white action-icon"></i></button>
                              <button className="btn btn-outline btn-darkblue" onClick={() => this.deleteCategoryAttributeGroupLanguageOnClick(attributeGroupLanguageInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                            </td>
                          </tr>
                        )}
                        {this.state.attributeGroupLanguages.length === 0 ?
                          <tr>
                            <td className="align-middle p-3" colSpan="5">Çeviri Listesi Bulunamadı !</td>
                          </tr>
                        : null}
                      </tbody> 
                      </table>
                    </div>
                    </div>
                  </div>
                  <AddedCategoryAttributeGroupLanguageModal isOpen={this.state.isOpenAddedCategoryAttributeGroupLanguageModal} modalTitle="Çeviri Ekle" modalDescription="Özel alana ait çeviri eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editAttributeGroupLanguages: null, isOpenAddedCategoryAttributeGroupLanguageModal: false})} saveOnClick={categoryAttributeGroupLanguageInfo => this.saveCategoryAttributeGroupLanguageOnClick(categoryAttributeGroupLanguageInfo, this.props.searchParams.get('id'))} />
                  {this.state.editAttributeGroupLanguages ?
                  <AddedCategoryAttributeGroupLanguageModal buttonRef={this.state.buttonRef} defaultValue={this.state.editAttributeGroupLanguages} isOpen={this.state.isOpenAddedCategoryAttributeGroupLanguageModal} modalTitle="Çeviri Düzenle" modalDescription="Çeviri bilgisini düzenlemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editAttributeGroupLanguages: null, isOpenAddedCategoryAttributeGroupLanguageModal: false})} saveOnClick={categoryAttributeGroupLanguageInfo => this.updateCategoryAttributeGroupLanguageOnClick(categoryAttributeGroupLanguageInfo, this.state.groupLanguageId)} />
                  : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  }
}

export default function CategoryAttributeGroupEdit() {
  return (
    <CategoryAttributeGroupEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}