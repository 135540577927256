import carImage from '../assets/img/carImage.jpg'
import clogo from '../assets/img/clogo.png';
import pattern from '../assets/img/pattern.png';
import productImg from '../assets/img/productImg.jpg';
import userImage from '../assets/img/userImg.png';
import wlogo from '../assets/img/wlogo.png';
import messageBackground from '../assets/img/messageBackground.png';
import showCase from '../assets/img/showCase.png';

const Images = {
  carImage,
  clogo,
  pattern,
  productImg,
  userImage,
  wlogo,
  messageBackground,
  showCase
}

export default Images;