import React from 'react';
import {useNavigate} from 'react-router-dom';

class HomeModule extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='d-flex justify-content-center'>
        <h1>Anasayfa</h1>
      </div>
    );
  }
}

export default function Home() {
  return (
    <HomeModule navigate={useNavigate()} />
  )
}