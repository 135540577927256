import React from 'react';
import {Link, useNavigate, useSearchParams } from 'react-router-dom';
import swal from 'sweetalert';
import CrmMemberMarketerAddModal from '../../../component/crmMemberAppointmentModals/CrmMemberMarketerAddModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToMysqlDateTime, inputDateTimeToIsoDateTime, isoToDate, isoToTime} from '../../../inc/helper';


class CrmMemberAppointmentEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenCrmMemberMarketerAddModal: false,
      priorityUser: [
        {id:1, name: "Sıcak"},
        {id:2, name: "Ilık"},
        {id:3, name: "Soğuk"}
      ],

      appointmentDate: null,
      description: '',
      comment: '',
      crmMemberAppointmentStatusId: 0,
      crmMemberAppointmentStatusName: '',
      crmMemberFullName: '',
      companyName: '',
      active: 1,
      crmMemberId: 0,
      priority: 0,
      crmMemberAddresses: [],
      crmMemberPhones: [],

      crmMemberAppointmentHistoryComment: '',
      crmMemberAppointmentHistories: [],

      crmMemberMarketers:[],
      crmMemberAppointmentStatuses: [],
      crmMemberAppointmentMarketerIds: [],

      navigate: this.props.navigate,

      selectedTabIndex: parseInt(this.props.searchParams.get('tab')) || 0

    };

    this.activeOnChange = this.activeOnChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyPressCrmAppointmentHistory = this.handleKeyPressCrmAppointmentHistory.bind(this);
  }

  componentDidMount() {
    this.getCrmMemberAppointment();
    this.getCrmMemberAppointmentMarketers();
    this.getCrmMemberAppointmentHistories();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    let newTabIndex = parseInt(this.props.searchParams.get('tab')) || 0;
    if(prevState.selectedTabIndex !== newTabIndex) {
      this.setState({selectedTabIndex: newTabIndex});
    }
  }

  getCrmMemberAppointment() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/crmMemberAppointments'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    
    fetch(config.restApi + 'crmMemberAppointment/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, crmMemberAppointmentStatusId: responseData.crmMemberAppointment.crmMemberAppointmentStatusId, priority: responseData.crmMemberAppointment.priority, crmMemberAppointmentStatusName: responseData.crmMemberAppointment.crmMemberAppointmentStatusName, crmMemberId: responseData.crmMemberAppointment.crmMemberId, crmMemberAddresses: responseData.crmMemberAppointment.crmMemberAddresses, crmMemberPhones: responseData.crmMemberAppointment.crmMemberPhones, crmMemberFullName: responseData.crmMemberAppointment.crmMemberFullName, companyName: responseData.crmMemberAppointment.companyName, description: responseData.crmMemberAppointment.description, appointmentDate: responseData.crmMemberAppointment.appointmentDate, crmMemberMarketers: responseData.crmMemberAppointment.crmMemberMarketers, active: responseData.crmMemberAppointment.active});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'crmMemberAppointmentStatuses?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, crmMemberAppointmentStatuses: responseData.crmMemberAppointmentStatuses});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getCrmMemberAppointmentMarketers() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'crmMemberAppointmentMarketers/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMemberMarketers: responseData.crmMemberAppointmentMarketers});
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/crmMemberAppointments'));
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }
  
  getCrmMemberAppointmentHistories() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'crmMemberAppointmentHistories/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMemberAppointmentHistories: responseData.crmMemberAppointmentHistories});
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/crmMemberAppointments'));
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  activeOnChange(event) {

    let active = event.target.value === '' ? '' : parseInt(event.target.value);
    
    this.setState({active: active});

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'crmMemberAppointment/tr/active/' + active + '?id=' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {}
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

  }

  saveCrmMemberMarketerOnClick = (companyMarketerInfo) => {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({crmMemberAppointmentMarketerIds: [companyMarketerInfo.id]})
    };

    fetch(config.restApi + 'crmMemberAppointmentMarketer/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          this.setState({isOpenCrmMemberMarketerAddModal: false})
          this.getCrmMemberAppointmentMarketers();
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCrmMemberMarketerOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Satış temsilcisini silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'crmMemberAppointmentMarketer/tr/' + this.props.searchParams.get('id') + '/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Satış temsilcisi başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCrmMemberAppointmentMarketers());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  saveCrmMemberAppointmentOnClick = () => {

    let sessionHash = getCookie('sessionHash');

    let infoMessage = ''; 
  
    if(!this.state.crmMemberAppointmentStatusId) {
      infoMessage = 'Potansiyel üye durum bilgisi boş geçilemez !';
    } else if(!this.state.appointmentDate) {
      infoMessage = 'Randevu tarihi boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});
      
      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({appointmentDate: inputDateTimeToIsoDateTime(this.state.appointmentDate), comment: this.state.comment, priority: this.state.priority, crmMemberAppointmentStatusId: this.state.crmMemberAppointmentStatusId})
      };

        fetch(config.restApi + 'crmMemberAppointment/tr/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Randevu bilgisi başarıyla güncellendi.',
                buttons: 'Tamam'
              });
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
  }

  deleteCrmMemberAppointmentOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Randevuyu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'crmMemberAppointment/tr/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Randevu başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.props.navigate('/crmMemberAppointments'));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  handleKeyPress(event) {
    if(event.key === 'Enter') {
      this.saveCrmMemberAppointmentOnClick();
    }
  }

  handleKeyPressCrmAppointmentHistory(event) {
    if(event.key === 'Enter') {
      this.saveCrmMemberAppointmentHistoryOnClick();
    }
  }

  saveCrmMemberAppointmentHistoryOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.comment) {
      infoMessage = 'Yorum boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({comment: this.state.comment})
      };

      fetch(config.restApi + 'crmMemberAppointmentHistory/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isCompleted: true, comment: ''});

            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Randevu hakkındaki yorum başarı ile eklendi.',
              buttons: 'Tamam'
            }).then(() => this.getCrmMemberAppointmentHistories());
          }
          break;
          default: {
            this.setState({isCompleted: true});

            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  deleteCrmMemberAppoitmentHistoryCommentOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Yorumu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'crmMemberAppointmentHistory/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Yorum başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCrmMemberAppointmentHistories());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });

            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  handleDateChange(event) {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();

    if (selectedDate < currentDate) {
      swal({
        dangerMode: true,
        icon: 'warning',
        title: 'Uyarı',
        text: 'Seçilen tarihler mevcut tarihten önce olamaz!',
        buttons: 'Tamam'
      });
    } else {
        this.setState({ appointmentDate: selectedDate });
    }
  }

  handlePriorityChange = (event) => {
    const selectedPriority = parseInt(event.target.value);
    this.setState({ priority: selectedPriority});
  };

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
      <div className="row">
        <div className="col">
          <div className="ibox">
            <div className="ibox-content">
              <div className="d-flex justify-content-between">
                <h4>Randevu Düzenle</h4>
                <div className="d-flex ibox justify-content-end">
                  <div className="btn-open justify-content-center">
                    <div className="btn border border-darkblue text-center mr-2">
                      <span className="btn-open dropdown-toggle font-bold text-darkblue" data-toggle="dropdown"><i className="fa fa-ellipsis-v text-darkblue"></i></span>
                      <ul className="dropdown-menu overflow-auto mr-3 mt-2">
                        <li><Link className="btn btn-height dropdown-item font-bold" to={"/crmMemberEdit?id=" + this.state.crmMemberId} title="Potansiyel Üyeler"><i className="fa fa-user-o m-r-xs"></i> Potansiyel Üye Profiline Git</Link></li>
                        <li><Link className="btn btn-height dropdown-item font-bold" onClick={() => this.deleteCrmMemberAppointmentOnClick()} title="Sil"><i className="fa fa-trash text-darkblue m-r-xs"></i> Üyeyi Sil</Link></li>
                      </ul>
                    </div>
                  </div>
                  <Link className="btn bg-green btn-none text-white mr-2" to={"/crmMemberEdit?id=" + this.state.crmMemberId} title="Potansiyel Üyeler"><i className="fa fa-user-o"></i><span className="d-none d-sm-inline"> Potansiyel Üye Profiline Git</span></Link>
                  <button className="btn btn-orange btn-none mr-2" onClick={() => this.deleteCrmMemberAppointmentOnClick()} title="Sil"><i className="fa fa-trash"></i></button>
                  {this.state.selectedTabIndex !== 1 ? <button className="btn btn-orange mr-2" onClick={() => this.saveCrmMemberAppointmentOnClick()} title="Kaydet"><i className="fa fa-save"></i></button>: ''}
                  <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
                </div>
              </div>
              <div className="tabs-container">
                <ul className="nav nav-tabs">
                  <li><Link className={this.state.selectedTabIndex === 0 ? "nav-link pr-1 pl-1 pr-md-2 pl-md-2 active" : "nav-link pr-1 pl-1 pr-md-2 pl-md-2"} title="Randevu Düzenle" to={'/crmMemberAppointmentEdit?id=' + this.props.searchParams.get('id') + '&tab=0'}>Randevu Düzenle</Link></li>
                  <li><Link className={this.state.selectedTabIndex === 1 ? "nav-link pr-1 pl-1 pr-md-2 pl-md-2 active" : "nav-link pr-1 pl-1 pr-md-2 pl-md-2"} title="Randevu Geçmişi" to={'/crmMemberAppointmentEdit?id=' + this.props.searchParams.get('id') + '&tab=1'}>Randevu Geçmişi</Link></li>
                </ul>
                <div className="tab-content">
                  <div className={this.state.selectedTabIndex === 0 ? "tab-pane active" : "tab-pane"}>
                    <div className="panel-body">
                        <div className="row">
                          <div className="col">
                            <div className="ibox">
                              <div className=" text-darkblue">
                                <div className="row mb-2">
                                  <div className="col-md-6">
                                    <strong>Randevu Sahibi</strong>
                                    <input className="form-control form-input mb-2 mt-1" placeholder="Adı Soyadı" readOnly type="text" value={this.state.crmMemberFullName} />
                                  </div>
                                  <div className="col-md-6">
                                    <strong>Firma Adı</strong>
                                    <input className="form-control form-input mb-2 mt-1" placeholder="Firma Adı" readOnly type="text" value={this.state.companyName !== '' ? this.state.companyName : '-'} />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <h5><strong>Potansiyel Üye Durumu<strong className="text-danger ml-1">*</strong></strong></h5>
                                    <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={(event) => this.setState({crmMemberAppointmentStatusId: parseInt(event.target.value) || ''})} onKeyDown={this.handleKeyPress} value={this.state.crmMemberAppointmentStatusId}>
                                      {this.state.crmMemberAppointmentStatuses.map(crmMemberAppointmentStatusInfo => <option key={crmMemberAppointmentStatusInfo.id} value={crmMemberAppointmentStatusInfo.id}>{crmMemberAppointmentStatusInfo.name}</option>)}
                                    </select>
                                  </div>
                                  <div className="col-md-6">
                                    <h5><strong>Durum</strong></h5>
                                    <select className="form-control form-control-sm form-input" onChange={this.activeOnChange} onKeyDown={this.handleKeyPress} value={this.state.active}>
                                      <option value="1">Açık</option>
                                      <option value="0">Kapalı</option>
                                    </select>                                  
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col">
                                    <h5><strong>Randevu Tarihi<strong className="text-danger ml-1">*</strong></strong></h5>
                                    <input className="form-control form-control-sm form-input mb-2 mt-1" value={this.state.appointmentDate ? isoToMysqlDateTime(this.state.appointmentDate) : ''} onChange={(event) => this.handleDateChange(event)} onKeyDown={this.handleKeyPress} type="datetime-local" />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-10">
                                  <h5><strong>Randevu Önceliği<strong className="text-danger ml-1">*</strong></strong></h5>
                                  {this.state.priorityUser.map((priority, index) => (
                                  <div className="align-middle" key={index}>
                                    <div className="d-flex">
                                      <label className="d-flex cursor">
                                        <input className="mr-1" type="radio" id={`optionsRadios${priority.id}`} name="priority" onChange={this.handlePriorityChange} value={priority.id} checked={priority.id === this.state.priority}/>
                                        {priority.name}
                                      </label>
                                    </div>
                                  </div>
                                  ))}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <div className="d-flex justify-content-between">
                                      <h4 className="text-darkblue">Adres Bilgileri</h4>
                                    </div>
                                    <div className="hr-line-dashed"></div>
                                    <div className="overflow-auto">
                                      <table className="table table-striped table-bordered">
                                      <thead>
                                        <tr className="text-center text-darkblue">
                                          <th>İl</th>
                                          <th>İlçe</th>
                                          <th>Semt</th>
                                          <th>Mahalle</th>
                                          <th>Açık Adres</th>
                                          <th>Açıklama</th>
                                          <th>Durum</th>
                                        </tr>
                                      </thead>
                                      <tbody className="text-center">
                                        {this.state.crmMemberAddresses.map(crmMemberAddressInfo =>
                                          <tr key={crmMemberAddressInfo.id}>
                                            <td className="align-middle">{crmMemberAddressInfo.cityName}</td>
                                            <td className="align-middle">{crmMemberAddressInfo.townName}</td>
                                            <td className="align-middle">{crmMemberAddressInfo.districtName}</td>
                                            <td className="align-middle">{crmMemberAddressInfo.quarterName}</td>
                                            <td className="align-middle">{crmMemberAddressInfo.address}</td>
                                            <td className="align-middle">{crmMemberAddressInfo.description}</td>
                                            <td className="align-middle">{crmMemberAddressInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                                          </tr>
                                        )}
                                        {this.state.crmMemberAddresses.length === 0 ?
                                          <tr>
                                            <td className="align-middle p-3" colSpan="8">Adres Bulunamadı !</td>
                                          </tr>
                                        : null}
                                      </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col  mb-5">
                                    <div className="d-flex justify-content-between">
                                      <h4 className="text-darkblue">Telefon Bilgileri</h4>
                                    </div>
                                    <div className="hr-line-dashed"></div>
                                    <div className="overflow-auto">
                                      <table className="table table-striped table-bordered">
                                      <thead>
                                        <tr className="text-center text-darkblue">
                                          <th>Telefon Tipi</th>
                                          <th>Telefon Numarası</th>
                                          <th>Ülke</th>
                                          <th>Durum</th>
                                        </tr>
                                      </thead>
                                      <tbody className="text-center">
                                        {this.state.crmMemberPhones.map((crmMemberPhoneInfo, index) =>
                                          <tr key={index}>
                                            <td className="align-middle">{crmMemberPhoneInfo.phoneTypeName}</td>
                                            <td className="align-middle">{this.phoneFormat(crmMemberPhoneInfo.phoneNumber)}</td>
                                            <td className="align-middle">{crmMemberPhoneInfo.countryName}</td>
                                            <td className="align-middle">{crmMemberPhoneInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                                          </tr>
                                        )}
                                        {this.state.crmMemberPhones.length === 0 ?
                                          <tr>
                                            <td className="align-middle p-3" colSpan="6">Telefon Bulunamadı !</td>
                                          </tr>
                                        : null}
                                      </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col">
                                    <div className="d-flex justify-content-between">
                                      <h4>Satış Sorumluları</h4>
                                      <button className="btn btn-orange" onClick={() => this.setState({isOpenCrmMemberMarketerAddModal: true})} title="Satış Sorumlusu Ekle"><i className="fa fa-plus"></i></button>
                                    </div>
                                    <div className="hr-line-dashed"></div>
                                    <div className="overflow-auto">
                                      <table className="table table-striped table-bordered">
                                      <thead>
                                        <tr className="text-center text-darkblue">
                                          <th>Satış Sorumlusu</th>
                                          <th>İşlem</th>
                                        </tr>
                                      </thead>
                                      <tbody className="text-center">
                                        {this.state.crmMemberMarketers.map((crmMemberMarketerInfo, index) =>
                                          <tr key={index}>
                                            <td className="align-middle">{crmMemberMarketerInfo.fullName}</td>
                                            <td className="align-middle">
                                              <button className="btn btn-darkblue" onClick={() => this.deleteCrmMemberMarketerOnClick(crmMemberMarketerInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                                            </td>
                                          </tr>
                                        )}
                                        {this.state.crmMemberMarketers.length === 0 ?
                                          <tr>
                                            <td className="align-middle p-3" colSpan="2">Satış Sorumlusu Bulunamadı !</td>
                                          </tr>
                                        : null}
                                      </tbody>
                                      </table>
                                      <CrmMemberMarketerAddModal isOpen={this.state.isOpenCrmMemberMarketerAddModal} modalTitle="Satış Sorumlusu Ekle" modalDescription="Satış sorumlusu bilgisi eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({isOpenCrmMemberMarketerAddModal: false})} saveOnClick={companyMarketerInfo => this.saveCrmMemberMarketerOnClick(companyMarketerInfo)} />
                                    </div>
                                    {/* <div className="ibox">
                                      <div className="ibox-content text-darkblue">
                                        <div className="d-flex justify-content-between">
                                          <h4>Satış Sorumluları</h4>
                                          <button className="btn btn-orange" onClick={() => this.setState({isOpenCrmMemberMarketerAddModal: true})} title="Satış Sorumlusu Ekle"><i className="fa fa-plus"></i></button>
                                        </div>
                                        <div className="hr-line-dashed"></div>
                                        <div className="overflow-auto">
                                          <table className="table table-striped table-bordered">
                                          <thead>
                                            <tr className="text-center text-darkblue">
                                              <th>Satış Sorumlusu</th>
                                              <th>İşlem</th>
                                            </tr>
                                          </thead>
                                          <tbody className="text-center">
                                            {this.state.crmMemberMarketers.map((crmMemberMarketerInfo, index) =>
                                              <tr key={index}>
                                                <td className="align-middle">{crmMemberMarketerInfo.fullName}</td>
                                                <td className="align-middle">
                                                  <button className="btn btn-darkblue" onClick={() => this.deleteCrmMemberMarketerOnClick(crmMemberMarketerInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                                                </td>
                                              </tr>
                                            )}
                                            {this.state.crmMemberMarketers.length === 0 ?
                                              <tr>
                                                <td className="align-middle p-3" colSpan="2">Satış Sorumlusu Bulunamadı !</td>
                                              </tr>
                                            : null}
                                          </tbody>
                                          </table>
                                          <CrmMemberMarketerAddModal isOpen={this.state.isOpenCrmMemberMarketerAddModal} modalTitle="Satış Sorumlusu Ekle" modalDescription="Satış sorumlusu bilgisi eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({isOpenCrmMemberMarketerAddModal: false})} saveOnClick={companyMarketerInfo => this.saveCrmMemberMarketerOnClick(companyMarketerInfo)} />
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col">
                                    <strong>Açıklama</strong>
                                    <textarea className="form-control form-control-sm form-input mb-2 mt-1" disabled onChange={event => this.setState({description: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Açıklama giriniz." type="text" value={this.state.description} />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <button className="btn btn-orange" onClick={() => this.saveCrmMemberAppointmentOnClick()}>Kaydet</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className={this.state.selectedTabIndex === 1 ? "tab-pane active" : "tab-pane"}>
                    <div className="panel-body">
                      <div className="row">
                        <div className="col mb-3">
                          <strong className="text-darkblue">Not<strong className="text-danger ml-1">*</strong></strong>
                          <textarea className="form-control form-input mb-2 mt-1" onChange={event => this.setState({comment: event.target.value})} onKeyDown={this.handleKeyPressCrmAppointmentHistory} placeholder="Randevu hakkında açıklama giriniz.." value={this.state.comment} />
                          <div className="float-right">
                            <button className="btn btn-darkblue" onClick={() => this.saveCrmMemberAppointmentHistoryOnClick()}><i className="fa fa-save"></i> Kaydet</button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="dark-timeline" id="vertical-timeline">
                            {this.state.crmMemberAppointmentHistories.map(crmMemberAppointmentHistoryInfo =>
                              <div className="vertical-timeline-block p-0" key={crmMemberAppointmentHistoryInfo.id}>
                                <div className="vertical-timeline-icon bg-darkblue">
                                  <i className="fa fa-comments-o text-white"></i>
                                </div>
                                <div className="vertical-timeline-content">
                                  <h3 className="font-bold text-dark">{crmMemberAppointmentHistoryInfo.addedFullName}</h3>
                                  <h4 className="mb-2">{crmMemberAppointmentHistoryInfo.comment}</h4>
                                  <a className="float-right mt-3" onClick={() => this.deleteCrmMemberAppoitmentHistoryCommentOnClick(crmMemberAppointmentHistoryInfo.id)} title="Randevu Geçmiş Sil"><i className="fa fa-trash text-darkblue"></i></a>
                                  <span className="vertical-date">{isoToDate(crmMemberAppointmentHistoryInfo.addedDate)}<br />
                                  <h5 className="text-orange">{isoToTime(crmMemberAppointmentHistoryInfo.addedDate)}</h5>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          {this.state.crmMemberAppointmentHistories.length === 0 ?
                            <p className="text-center">Randevu Geçmişi Bulunamadı !</p>
                          : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
      </>

  }
}

export default function CrmMemberAppointmentEdit() {
  return (
    <CrmMemberAppointmentEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}