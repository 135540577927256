import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import { getCookie } from '../../../inc/cookies';
import config from '../../../inc/config';

import AddedCategoryAttributeLanguageModal from '../../../component/category/categoryAttribute/AddedCategoryAttributeLanguageModal';

class CategoryAttributeEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddedCategoryAttributeLanguageModal: false,

      sort: 0,
      name: '',

      attributeLanguageId: 0,
      attributeLanguages: [],
      editCategoryAttributeLanguages: null,

    };
  }

  componentDidMount() {
    this.getCategoryAttributeDetail();
    window.scrollTo(0, 0);
  }

  getCategoryAttributeDetail() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/categoryAttributes'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'attribute/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({name: responseData.attributeInfo.name, sort: responseData.attributeInfo.sort});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'attributeLanguages/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({attributeLanguages: responseData.attributeLanguages});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  saveCategoryAttributeOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.sort && this.state.sort === 0) {
      infoMessage = 'Sıralama boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({sort: parseInt(this.state.sort)})
      };

      fetch(config.restApi + 'attribute/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});

        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Özellik başarıyla güncellendi.',
              buttons: 'Tamam'
            });
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  saveCategoryAttributeLanguageOnClick(categoryAttributeLanguageInfo, id) { 

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({name: categoryAttributeLanguageInfo.name, language: categoryAttributeLanguageInfo.language, description: categoryAttributeLanguageInfo.description})
    };

    fetch(config.restApi + 'attributeLanguage/tr/' + id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Özelliğe ait çeviri başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getCategoryAttributeDetail(), this.setState({isOpenAddedCategoryAttributeLanguageModal: false}), categoryAttributeLanguageInfo.name = '', categoryAttributeLanguageInfo.language = '', categoryAttributeLanguageInfo.description = '');
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  updateCategoryAttributeLanguageOnClick(categoryAttributeLanguageInfo, id) {
    
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(categoryAttributeLanguageInfo)
    };

    fetch(config.restApi + 'attributeLanguage/tr/' + id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Çeviri başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.getCategoryAttributeDetail(), this.setState({isOpenAddedCategoryAttributeLanguageModal: false, editCategoryAttributeLanguages: categoryAttributeLanguageInfo}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCategoryAttributeLanguageOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Çeviriyi silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'attributeLanguage/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Çeviri başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCategoryAttributeDetail());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.saveCategoryAttributeOnClick();
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
      <div className="row">
        <div className="col">
          <div className="ibox">
            <div className="ibox-title text-darkblue d-flex justify-content-between pr-2">
              <h5><span className="text-orange">{this.state.name}</span> Özelliğini Düzenle</h5>
              <div>
                <button className="btn btn-orange mr-2" title="Kaydet" onClick={() => this.saveCategoryAttributeOnClick()}><i className="fa fa-save"></i></button>
                <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
              </div>
            </div>
            <div className="ibox-content">  
              <div className="row mb-5">
                <div className="col-md-6">
                  <h5 className="text-darkblue"><strong>Sıralama<strong className="text-danger ml-1">*</strong></strong></h5>
                  <input className="form-control form-input mb-2 mt-1" min="0" onChange={(event) => this.setState({sort: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Sıralama" type="number" value={this.state.sort} />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darkblue"><span className="text-orange">{this.state.name} 'e</span> Ait Çeviri Listesi</h4>
                    <button className="btn btn-orange" onClick={() => this.setState({editCategoryAttributeLanguages: null, isOpenAddedCategoryAttributeLanguageModal: true})} title="Dil Ekle"><i className="fa fa-plus"></i></button>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="row">
                    <div className="col">
                    <div className="overflow-auto">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="text-center text-darkblue">
                          <th>Çeviri</th>
                          <th>Dil</th>
                          <th>Açıklama</th>
                          <th>Durum</th>
                          <th>İşlem</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {this.state.attributeLanguages.map((attributeLanguageInfo) =>
                          <tr key={attributeLanguageInfo.id}>
                            <td className="align-middle text-orange">{attributeLanguageInfo.name}</td>
                            <td className="align-middle">{attributeLanguageInfo.language}</td>
                            <td className="align-middle">{attributeLanguageInfo.description}</td>
                            <td className="align-middle">{attributeLanguageInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                            <td className="align-middle" style={{minWidth: 98.5}}>
                              <button className="btn btn-outline btn-orange text-center m-1" onClick={() => this.setState({isOpenAddedCategoryAttributeLanguageModal: true, attributeLanguageId: attributeLanguageInfo.id, editCategoryAttributeLanguages: attributeLanguageInfo})} title="Düzenle"><i className="fa fa-pencil text-white action-icon"></i></button>
                              <button className="btn btn-outline btn-darkblue" onClick={() => this.deleteCategoryAttributeLanguageOnClick(attributeLanguageInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                            </td>
                          </tr>
                        )}
                        {this.state.attributeLanguages.length === 0 ?
                          <tr>
                            <td className="align-middle p-3" colSpan="4">Çeviri Listesi Bulunamadı !</td>
                          </tr>
                        : null}
                      </tbody> 
                      </table>
                    </div>
                    </div>
                  </div>
                  <AddedCategoryAttributeLanguageModal isOpen={this.state.isOpenAddedCategoryAttributeLanguageModal} modalTitle="Çeviri Ekle" modalDescription="Özelliğe ait çeviri eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editCategoryAttributeLanguages: null, isOpenAddedCategoryAttributeLanguageModal: false})} saveOnClick={categoryAttributeLanguageInfo => this.saveCategoryAttributeLanguageOnClick(categoryAttributeLanguageInfo, this.props.searchParams.get('id'))} />
                  {this.state.editCategoryAttributeLanguages ?
                  <AddedCategoryAttributeLanguageModal buttonRef={this.state.buttonRef} defaultValue={this.state.editCategoryAttributeLanguages} isOpen={this.state.isOpenAddedCategoryAttributeLanguageModal} modalTitle="Çeviri Düzenle" modalDescription="Çeviri bilgisini düzenlemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editCategoryAttributeLanguages: null, isOpenAddedCategoryAttributeLanguageModal: false})} saveOnClick={categoryAttributeLanguageInfo => this.updateCategoryAttributeLanguageOnClick(categoryAttributeLanguageInfo, this.state.attributeLanguageId)} />
                  : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  }
}

export default function CategoryAttributeEdit() {
  return (
    <CategoryAttributeEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}