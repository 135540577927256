function getCookie(cname, defaultValue) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return defaultValue;
  }
  
  function setCookie(cname, cvalue, expires = 0) {
    if (expires > 0) {
        let date = new Date();
        date.setTime(date.getTime() + expires * 1000);
        document.cookie = cname + "=" + cvalue + ";expires=" + date.toUTCString() + ";path=/;domain=" + ((document.location.hostname.endsWith('.com') && '.satisgarantim.com') || (document.location.hostname.endsWith('.net') && '.satisgarantim.net') || 'localhost');
    } else {
        document.cookie = cname + "=" + cvalue + ";path=/;domain=" + ((document.location.hostname.endsWith('.com') && '.satisgarantim.com') || (document.location.hostname.endsWith('.net') && '.satisgarantim.net') || 'localhost');
    }
    return true;
  }
  
  function removeCookie(cname) {
    let date = new Date();
    document.cookie = cname + "=;expires=" + date.toUTCString() + ";path=/;domain=" + ((document.location.hostname.endsWith('.com') && '.satisgarantim.com') || (document.location.hostname.endsWith('.net') && '.satisgarantim.net') || 'localhost');
    return true;
  }
  
  module.exports = {getCookie, setCookie, removeCookie};