import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import MyWorkExpenseAddModal from '../../../component/specialForMe/MyWorkExpenseAddModal'

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDate, priceFormat} from '../../../inc/helper';

class MyWorkExpenseModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenMyWorkExpenseAddModal: false,

      filterExpenseStartDate: '',
      filterExpenseEndDate: '',

      myWorkExpenses: []
    };

    this.onCompleted = this.onCompleted.bind(this);
  }

  componentDidMount() {
    this.getMyWorkExpenses();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  getMyWorkExpenses() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'myWorkExpenses/tr?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, myWorkExpenses: responseData.myWorkExpenses});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  filteredMyWorkExpenses(query) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'myWorkExpenses/tr?' + query, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, myWorkExpenses: responseData.myWorkExpenses});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  filterOnClick = (event) => {
    this.filteredMyWorkExpenses('filterExpenseStartDate=' + this.state.filterExpenseStartDate + '&filterExpenseEndDate=' + this.state.filterExpenseEndDate);
  }

  resetOnClick = (event) => {
    this.setState({
      filterExpenseStartDate: '',
      filterExpenseEndDate: ''
    });
    this.getMyWorkExpenses();
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.filterOnClick();
    }
  }

  onCompleted() {
    this.getMyWorkExpenses();
    this.setState({isOpenMyWorkExpenseAddModal: false});
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-xl-9">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-3">
                <h5>Harcamalarım</h5>
                <button className="btn btn-orange mb-2" onClick={() => this.setState({isOpenMyWorkExpenseAddModal: true})} title="Ekle"><i className="fa fa-plus"></i> Masraf Ekle</button>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Masraf Adı</th>
                      <th>Açıklama</th>
                      <th>Harcama Tutarı</th>
                      <th>Harcama Tarihi</th>
                      <th>Muhasebe Notu</th>
                      <th>Onay Durumu</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.myWorkExpenses.map(myWorkExpenseInfo =>
                      <tr key={myWorkExpenseInfo.id}>
                        <td className="align-middle">{myWorkExpenseInfo.name}</td>
                        <td className="align-middle">{myWorkExpenseInfo.description}</td>
                        <td className="align-middle">{priceFormat(myWorkExpenseInfo.price)} ₺</td>
                        <td className="align-middle">{myWorkExpenseInfo.expenseDate ? isoToDate(myWorkExpenseInfo.expenseDate) : "-"}</td>
                        <td className="align-middle text-success">{myWorkExpenseInfo.expenseResult ? myWorkExpenseInfo.expenseResult : "-"}</td>
                        <td className="align-middle">{myWorkExpenseInfo.locked === 1 ? <i className="fa fa-check text-green font-bold pointer" title="Onaylandı"></i> : <i className="fa fa-reply text-orange font-bold pointer" title="Onaylanmadı"></i>}</td>
                        <td className="align-middle">
                          <Link className="btn btn-orange text-center" to={"/myWorkExpenseEdit?id=" + myWorkExpenseInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                        </td>
                      </tr>
                    )}
                    {this.state.myWorkExpenses.length === 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="7">Harcama Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
            <div className="ibox">
              <div className="ibox-content text-darkblue p-0">
                <h4 className="collapse-title">
                  <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
                </h4>
              </div>
              <div className="collapse" data-parent="#accordion" id="filterCollapse">
                <div className="ibox-content text-darkblue">
                  <strong>Harcama Başlangıç Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterExpenseStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterExpenseStartDate} />
                  <strong>Harcama Bitiş Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterExpenseEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterExpenseEndDate} />
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                    <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
            <div className="ibox">
              <div className="ibox-title text-darkblue">
                <h5><i className="fa fa-filter"></i> Filtrele</h5>
              </div>
              <div className="ibox-content text-darkblue">
                <strong>Harcama Başlangıç Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterExpenseStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterExpenseStartDate} />
                <strong>Harcama Bitiş Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterExpenseEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterExpenseEndDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MyWorkExpenseAddModal isOpen={this.state.isOpenMyWorkExpenseAddModal} modalDescription="Gerçekleştirdiğiniz masrafları listelemek için aşağıdaki alanları doldurunuz !" modalTitle="Masraf Ekle" onCompleted={this.onCompleted} onRequestClose={() => this.setState({isOpenMyWorkExpenseAddModal: false})} />
      </>
}}

export default function MyWorkExpenses() {
  return (
    <MyWorkExpenseModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}